import {
  findParentNode,
  isNodeSelection,
  mergeAttributes,
  Node,
} from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'
import { BackgroundOptions } from 'modules/tiptap_editor/styles/backgroundStyles'
import { configureJSONAttribute } from 'modules/tiptap_editor/utils'

import { attrsOrDecorationsChanged } from '../../updateFns'
import { CardAttributes, CardLayout } from '../types'
import { isCardNode } from '../utils'
import { CardLayoutItemView } from './CardLayoutItemView'
import { CardLayoutPlugin } from './CardLayoutPlugin'
import {
  ensureCardLayoutItems,
  findLayoutPreset,
  getCardLayoutItems,
  isAccentCardLayoutItem,
} from './cardLayoutUtils'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    cardLayout: {
      setCardLayout: (pos: number, layout: CardLayout) => ReturnType
      selectInsideCardBody: (cardPos: number) => ReturnType
      handleCardAccentDelete: () => ReturnType
    }
  }
}

export type VerticalAlign = 'start' | 'center' | 'end' | null

export type CardLayoutItemIds = 'body' | 'accent'

export type CardLayoutItemAttrs = {
  verticalAlign: VerticalAlign
  background: BackgroundOptions
  itemId: CardLayoutItemIds
}

export const CardLayoutItem = Node.create({
  name: 'cardLayoutItem',
  group: 'cardLayoutItemGroup',
  content: '(block | cardBlock)+',
  selectable: false, // If this is true, clicking between blocks selects the card instead of putting the cursor between the blocks
  draggable: false, // This seems to be needed to rearrange top level (but not nested) cards. Without it, they get duplicated.
  isolating: true,
  containerHandle: true,

  addAttributes() {
    return {
      itemId: {
        default: 'body',
      },
      background: {
        default: {},
        ...configureJSONAttribute('background'),
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(CardLayoutItemView, {
      update: attrsOrDecorationsChanged,
    })
  },
  addCommands() {
    return {
      handleCardAccentDelete:
        () =>
        ({ state, commands }) => {
          if (!isNodeSelection(state.selection)) {
            return false
          }
          const { node } = state.selection
          if (!isAccentCardLayoutItem(node)) {
            return false
          }

          const parentCard = findParentNode(isCardNode)(state.selection)
          if (!parentCard) {
            return false
          }

          return commands.setCardLayout(parentCard.pos, 'blank')
        },
      selectInsideCardBody:
        (cardPos: number) =>
        ({ state, tr, commands }) => {
          const node = state.doc.nodeAt(cardPos)
          if (!node || !isCardNode(node)) {
            return false
          }

          const cardLayoutItems = getCardLayoutItems(tr, cardPos)
          if (Object.entries(cardLayoutItems).length === 0) {
            return commands.selectInsideNodeAtPos(cardPos)
          }

          const body = cardLayoutItems.body
          if (body) {
            return commands.selectInsideNodeAtPos(body.pos)
          }

          // card has cardLayoutItems, but can't find body
          return false
        },
      setCardLayout:
        (cardPos: number, layout: CardLayout) =>
        ({ state, tr, editor }) => {
          const cardNode = state.doc.nodeAt(cardPos)
          if (cardNode?.type.name !== 'card') {
            return false
          }
          const cardAttrs = cardNode.attrs as CardAttributes
          const preset = findLayoutPreset(layout)

          // it's important to start only with the correct layout items for the prev layout
          ensureCardLayoutItems(tr, cardPos, editor.schema, preset)
          if (layout === cardAttrs.layout) {
            // no-op we aren't actually changing
            return true
          }

          // set the layout attribute on cardPos
          tr.setNodeAttribute(cardPos, 'layout', layout)
          return true
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=card-layout-item]',
      },
    ]
  },

  addProseMirrorPlugins() {
    return [CardLayoutPlugin(this.editor)]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'card-layout-item' }),
      0,
    ]
  },
})
