import { SystemStyleObject } from '@chakra-ui/react'
import { CSSObject } from '@emotion/react'

import { CardLayout } from '../types'
import { CardLayoutItemIds } from './CardLayoutItem'
export const MIN_ACCENT_HEIGHT = 'minmax(300px, auto)'

type CardLayoutItemSpec = {
  type: 'body' | 'accent'
  style?: CSSObject
}

export type CardLayoutPreset = {
  grid: SystemStyleObject
  numColumns: number
  items: Partial<
    {
      [id in CardLayoutItemIds]: CardLayoutItemSpec
    }
  >
  stickyAccent?: boolean
}

export const LAYOUT_PRESETS: {
  [key in CardLayout]: CardLayoutPreset
} = {
  top: {
    grid: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'minmax(300px, auto) auto',
      gridTemplateAreas: `"accent" "body"`,
    },
    items: {
      accent: { type: 'accent' },
      body: { type: 'body' },
    },
    numColumns: 1,
  },
  behind: {
    grid: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: MIN_ACCENT_HEIGHT,
    },
    items: {
      accent: {
        type: 'accent',
        style: {
          gridArea: '1 / 1',
        },
      },
      body: {
        type: 'body',
        style: {
          gridArea: '1 / 1',
          zIndex: 1,
        },
      },
    },
    numColumns: 1,
    stickyAccent: true,
  },
  blank: {
    grid: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridTemplateAreas: `"body"`,
    },
    items: {
      body: { type: 'body' },
    },
    numColumns: 1,
  },
  left: {
    grid: {
      gridTemplateColumns: '1fr 1.8fr',
      gridTemplateRows: MIN_ACCENT_HEIGHT,
      gridTemplateAreas: `"accent body"`,
    },
    items: {
      accent: { type: 'accent' },
      body: { type: 'body' },
    },
    numColumns: 2,
    stickyAccent: true,
  },
  right: {
    grid: {
      gridTemplateColumns: '1.8fr 1fr',
      gridTemplateRows: MIN_ACCENT_HEIGHT,
      gridTemplateAreas: `"body accent"`,
    },
    items: {
      accent: { type: 'accent' },
      body: { type: 'body' },
    },
    numColumns: 2,
    stickyAccent: true,
  },
}
