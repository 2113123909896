import { Actions } from '@gammatech/authorization'
import { Editor } from '@tiptap/core'
import { cloneDeep } from 'lodash'
import { GapCursor } from 'prosemirror-gapcursor'
import { NodeSelection } from 'prosemirror-state'
import { useEffect, useState } from 'react'

import { preventDefaultUndo } from 'modules/keyboard'
import { useAppSelector } from 'modules/redux'
import { useAbility } from 'modules/user'
import { findInBetween } from 'utils/dom'

import { CARD_BODY_CLASS } from './extensions/Card/constants'
import { isCardNode } from './extensions/Card/utils'
import { parseHtmlToSlice } from './extensions/Clipboard/utils'
import { useForwardUndo } from './hooks/useForwardUndo'
import { selectDoc } from './reducer'
import { editorHasFocus } from './utils'
import { pruneCardIds } from './utils/transform'

/**
 * This hook monitors selection updates and content updates.
 * It should return a new value (to trigger a re-render) when:
 *   - The selection changes from empty to not empty or empty inside a link
 *   OR
 *   - The content changes while the selection is non-empty
 *
 * This ensures that we get a new value when the content changes,
 * but only if there is something selected (useful for formatting menus)
 *
 * E.g.
 *
 *   Click into doc for initial (empty) selection -> returns 0
 *   Select text to make a text selection         -> returns 1
 *   Bold selection                               -> returns 2
 *   Underline the selection                      -> returns 3
 *   Click somewhere outside the selection        -> returns 0
 *   Click somewhere else in the doc              -> returns 0
 *   Click into a link                            -> returns 1
 */
export const useEditorUpdateDuringSelection = (editor: Editor) => {
  const [val, setVal] = useState<number>(0)

  useEffect(() => {
    const handleUpdate = () => {
      setVal((prev) => {
        // If the selection is empty, set back to 0
        if (
          editor.state.selection.empty &&
          !editor.isActive('link') &&
          !editor.isActive('button')
        )
          return 0

        // The selection is not empty, so increment
        return prev + 1
      })
    }
    editor.on('update', handleUpdate)
    editor.on('selectionUpdate', handleUpdate)

    return () => {
      editor.off('update', handleUpdate)
      editor.off('selectionUpdate', handleUpdate)
    }
  }, [editor])

  return val
}

// Simple hook to keep track of the editor's focused state
export const useEditorFocused = (editor?: Editor) => {
  const [focused, setFocused] = useState(
    editor ? editorHasFocus(editor) : false
  )

  useEffect(() => {
    if (!editor) return

    const cb = () => setFocused(editorHasFocus(editor))
    editor.on('blur', cb).on('focus', cb)
    return () => {
      editor.off('blur', cb).off('focus', cb)
    }
  }, [editor])

  return focused
}

// Anything relying on this hook will only re-render when the given ability value changes
export const useCanWithSelectDoc = (action: Actions) => {
  const ability = useAbility()

  return useAppSelector((state) => {
    const doc = selectDoc(state)
    return doc ? ability.can(action, doc) : false
  })
}

// Currently unused because useGlobalForwardUndo takes precedence
export const usePreventDefaultUndo = (editable: boolean) => {
  useEffect(() => {
    if (!editable) return
    // Prevent the browser's native undo from modifying our content-editable
    // which was leading to https://linear.app/gamma-app/issue/G-1679/unhandled-runtime-error-when-undoing
    window.addEventListener('keydown', preventDefaultUndo)
    return () => window.removeEventListener('keydown', preventDefaultUndo)
  }, [editable])
}

export const useGlobalForwardUndo = (
  editor: Editor | null | undefined,
  editable: boolean
) => {
  const forwardUndo = useForwardUndo(editor)

  useEffect(() => {
    if (!editor || !editable) return
    const forwardUndoIfNotHandled = (ev: KeyboardEvent) => {
      if (ev.defaultPrevented) return // ProseMirror already handled it
      forwardUndo(ev)
    }
    window.addEventListener('keydown', forwardUndoIfNotHandled)
    return () => window.removeEventListener('keydown', forwardUndoIfNotHandled)
  }, [editor, forwardUndo, editable])
}

// Sometimes when you copy a node selection, the target will be the body
// and so it won't bubble up for ProseMirror to handle. This catches those
// cases and dispatches them back to the view.
// https://discuss.prosemirror.net/t/copy-nodeselected-nodes/1513
export const useHandleCopyPasteNodeSelection = (editor: Editor | null) => {
  useEffect(() => {
    if (!editor) return
    const handleEvent = (ev: ClipboardEvent) => {
      if (
        ev.defaultPrevented || // Already handled by ProseMirror
        !editor.isEditable ||
        !editor.isFocused ||
        !(editor.state.selection instanceof NodeSelection) ||
        !ev.target ||
        editor.view.dom.contains(ev.target as Node) // Was already in ProseMirror's tree to handle
      ) {
        return
      }
      editor.view.dispatchEvent(ev)
    }

    document.addEventListener('copy', handleEvent)
    document.addEventListener('cut', handleEvent)
    document.addEventListener('paste', handleEvent)
    return () => {
      document.removeEventListener('copy', handleEvent)
      document.removeEventListener('cut', handleEvent)
      document.removeEventListener('paste', handleEvent)
    }
  }, [editor])
}

// When the editor is blurred, it doesn't get clipboard events
// but we want to special case it to run when you paste an entire
// card
export const useHandlePasteCard = (editor: Editor | null) => {
  useEffect(() => {
    if (!editor) return
    const handleEvent = (ev: ClipboardEvent) => {
      if (
        ev.defaultPrevented || // Already handled by ProseMirror
        editor.isFocused ||
        !editor.isEditable ||
        !ev.target ||
        editor.view.dom.contains(ev.target as Node) // Was already in ProseMirror's tree to handle
      ) {
        return
      }

      const html = ev.clipboardData?.getData('text/html')
      if (!html) {
        return
      }
      const slice = parseHtmlToSlice(html, editor.schema)
      if (
        slice.content.childCount === 1 &&
        isCardNode(slice.content.firstChild!)
      ) {
        const endPos = editor.state.doc.content.size - 1
        const newNode = pruneCardIds(
          cloneDeep(slice.content.firstChild?.toJSON())
        )
        editor
          .chain()
          .insertContentAt(endPos, newNode)
          .selectInsertedNode()
          .scrollIntoView()
          .run()
        // Prevent browser paste from running after we've focused in card
        ev.preventDefault()
      }
    }

    document.addEventListener('paste', handleEvent)
    return () => {
      document.removeEventListener('paste', handleEvent)
    }
  }, [editor])
}

export const useFixReactNodeViewGapCursors = (editor: Editor | null) => {
  useEffect(() => {
    if (!editor) {
      return
    }

    const onClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (
        !target.closest(`.${CARD_BODY_CLASS}`) ||
        target.closest('.drag-handle')
      ) {
        // handle clicks only inside card bodies
        // do not handle clicks on drag handle
        return
      }
      const coordPos = editor.view.posAtCoords({
        left: event.clientX,
        top: event.clientY,
      })
      if (!coordPos) {
        return
      }
      const containerNode = editor.state.doc.nodeAt(coordPos.inside)

      if (
        !containerNode ||
        !containerNode.type.spec.isolating ||
        // if allow gapcursor is explicitley false
        containerNode.type.spec.allowGapCursor === false
      ) {
        // could not find container or container is not isolating
        // dont handle
        return
      }

      const { node, offset } = editor.view.domAtPos(coordPos.inside)
      const containerDom = node.childNodes[offset] as HTMLElement
      if (!containerDom) {
        return
      }
      // this hook only handle ReactNodeViews
      if (!containerDom.classList.contains('react-renderer')) {
        return
      }

      // if there is a `data-node-view-content-inner` in between
      // event.target (where the click happened) and containerDom.node
      // then we know the click was made on the content of the node view
      const contentDOMInBetween = findInBetween(
        event.target as HTMLElement,
        containerDom as HTMLElement,
        (el) => el.hasAttribute('data-node-view-content-inner')
      )
      if (contentDOMInBetween) {
        // clicked inside container content, don't handle
        return
      }

      try {
        const $coordPos = editor.state.doc.resolve(coordPos.pos)
        // @ts-ignore
        if (!GapCursor.valid($coordPos)) {
          return false
        }
        const gapCursor = new GapCursor($coordPos)
        editor.view.dispatch(editor.state.tr.setSelection(gapCursor))
        // dont want other events to handle this or cause other selections
        event.stopPropagation()
        event.preventDefault()
      } catch (err) {
        console.error(
          '[useFixReactNodeViewGapCursors] Error creating GapCursor',
          err
        )
      }
      return
    }

    editor.view.dom.addEventListener('click', onClick)
    return () => editor.view.dom.removeEventListener('click', onClick)
  }, [editor])
}
