import { config } from 'config'
import { ErrorCode } from 'modules/errors'
import { getUserMetadataStore } from 'modules/performance/UserMetadataStore'
import { analytics, SegmentEvents } from 'modules/segment'
import { localStore } from 'utils/storage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

let windowObjectReference: Window | null = null
let previousUrl: string | null = null

export enum WindowPostMessageTypes {
  LOGIN_COMPLETE = 'loginComplete',
  LOGIN_FAILURE = 'loginFailure',
  SIGNUP_COMPLETE = 'signupComplete',
}

type SignupData = {
  isFirstWorkspace: boolean // A user just signed up, and this is that user's FIRST workspace
  newWorkspace: boolean // A user just signed up, and a new workspace was created
  signupFlow: string
  welcomeDocId?: string
  userId: string
  workspaceId: string
}

export type MessageEventData = {
  type: WindowPostMessageTypes
  payload?: {
    code?: ErrorCode
    data?: SignupData
  }
}

type ReceiveMessageParams = {
  event: MessageEvent<MessageEventData>
  errorCallback?: (code: ErrorCode) => void
  redirectTo?: string
  doneCallback?: () => void
}

const identifyLoggedinUser = (data?: SignupData) => {
  if (!data) return
  const { userId, workspaceId } = data
  analytics.identify(userId, {
    user_org_id: workspaceId,
    anonymous_user: false,
    gamma_user_id: userId,
  })
}
export const receiveMessage = ({
  event,
  errorCallback,
  redirectTo,
  doneCallback,
}: ReceiveMessageParams): void => {
  const userMetadataStore = getUserMetadataStore()
  const context = userMetadataStore.get()
  // Do we trust the sender of this message? (might be
  // different from what we originally opened, for example).
  if (
    event.origin !== window.location.origin &&
    event.origin !== config.FRONTEND_URL // Support for custom versions
  ) {
    return
  }
  const { data } = event
  if (data.type === WindowPostMessageTypes.LOGIN_COMPLETE) {
    identifyLoggedinUser(data?.payload?.data)
    analytics.track(SegmentEvents.LOGIN, {}, { context })
    windowObjectReference?.close()
    if (!redirectTo && doneCallback) {
      doneCallback()
      return
    }
    window.location.href = redirectTo ? redirectTo : '/'
  }
  if (data.type === WindowPostMessageTypes.SIGNUP_COMPLETE) {
    identifyLoggedinUser(data?.payload?.data)
    analytics.track(
      SegmentEvents.SIGNUP,
      {
        flow: data?.payload?.data?.signupFlow,
      },
      { context }
    )
    const isFirstWorkspaceForUser = data.payload?.data?.isFirstWorkspace
    windowObjectReference?.close()

    let defaultRedirect = redirectTo ? redirectTo : '/'

    // If a user already has a workspace, and is joining another one, or,
    // if for some reason we explicitly set `isFirstWorkspace` to false in
    // signup-flow.ts, as in the case of `DocInvitationSignupFlow` and
    // `DocAccessLinkSignupFlow`, then we never want to direct them to /tour
    if (!redirectTo && doneCallback && !isFirstWorkspaceForUser) {
      doneCallback()
      return
    }

    if (isFirstWorkspaceForUser && data?.payload?.data) {
      // By default, send all users with only one workspace as of signup to the tour
      // The workspace can be a net new one, or an existing one that they just joined
      // via a workspace invitation
      defaultRedirect = '/tour'
      const { welcomeDocId, newWorkspace } = data.payload.data

      // This is a new workspace, and we should give them the chance to set the workspace name
      if (newWorkspace) {
        defaultRedirect = '/welcome?newWorkspace=true'
      }

      if (welcomeDocId) {
        // The welcomeDocId is not persisted on the BE, so store it in local
        // storage here for possible use on initial sign up
        try {
          localStore.setItem(
            USER_SETTINGS_CONSTANTS.welcomeDocId,
            JSON.stringify(welcomeDocId)
          )
        } catch (err) {
          // Owell, we tried
        }
      }
    }
    window.location.href = defaultRedirect
  }
  if (data.type === WindowPostMessageTypes.LOGIN_FAILURE) {
    const code = event?.data?.payload?.code
    if (!errorCallback) {
      console.error('No error callback defined')
      return
    }
    if (!code) {
      console.error('No code defined')
      return
    }
    windowObjectReference?.close()
    errorCallback(code)
  }
  // if we trust the sender and the source is our popup
}

export enum LoginScenarios {
  ACCEPT_DOC_INVITATION = 'accept-doc-invitation',
  ACCEPT_WORKSPACE_INVITATION = 'accept-workspace-invitation',
  DOC_ACCESS_LINK = 'doc-access-link',
  JOIN_WITH_CODE = 'join-with-code',
  DEFAULT_LOGIN = 'login',
  SIGNUP = 'signup',
}

export type SignupParams = {
  email?: string
  docId?: string
  slug?: string
  inviteCode?: string
  workspaceId?: string
  shareToken?: string
}

const getLoginUrl = (type: LoginScenarios, params?: SignupParams) => {
  switch (type) {
    case LoginScenarios.JOIN_WITH_CODE: {
      const { slug, inviteCode } = params as SignupParams
      return `${config.API_HOST}/organizations/${slug}/join/${inviteCode}`
    }
    case LoginScenarios.ACCEPT_DOC_INVITATION: {
      const { docId, email, inviteCode } = params as SignupParams
      return `${config.API_HOST}/invitations/docs/${docId}/?inviteCode=${inviteCode}&email=${email}`
    }
    case LoginScenarios.ACCEPT_WORKSPACE_INVITATION: {
      const { workspaceId, email, inviteCode } = params as SignupParams
      return `${config.API_HOST}/invitations/workspaces/${workspaceId}?inviteCode=${inviteCode}&email=${email}`
    }
    case LoginScenarios.DOC_ACCESS_LINK: {
      const { docId, shareToken } = params as SignupParams
      return `${config.API_HOST}/access-links/docs/${docId}/?token=${shareToken}`
    }
    case LoginScenarios.SIGNUP: {
      const { inviteCode } = params as SignupParams
      return `${config.API_HOST}/signup?inviteCode=${inviteCode}`
    }
    default:
      return `${config.API_HOST}/login`
  }
}

type OpenLoginWindowParams = {
  type: LoginScenarios
  params?: SignupParams
  errorCallback?: (code: ErrorCode) => void
  redirectTo?: string
  doneCallback?: () => void
}

export const openLoginWindow = ({
  type,
  params,
  errorCallback,
  redirectTo,
  doneCallback,
}: OpenLoginWindowParams) => {
  const url = getLoginUrl(type, params)
  // remove any existing event listeners

  const handleMessage = (event: MessageEvent) => {
    receiveMessage({ event, errorCallback, redirectTo, doneCallback })
  }

  window.removeEventListener('message', handleMessage)

  // window features
  const strWindowFeatures =
    'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
      or if such pointer exists but the window was closed */
    windowObjectReference = window.open(url, type, strWindowFeatures)
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
      then we load it in the already opened secondary window and then
      we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(url, type, strWindowFeatures)
    if (windowObjectReference !== null) {
      windowObjectReference.focus()
    }
  } else {
    /* else the window reference must exist and the window
      is not closed; therefore, we can bring it back on top of any other
      window with the focus() method. There would be no need to re-create
      the window or to reload the referenced resource. */
    windowObjectReference.focus()
  }

  // add the listener for receiving a message from the popup
  window.addEventListener('message', handleMessage, false)
  // assign the previous URL
  previousUrl = url
}
