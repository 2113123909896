import { gql } from '@apollo/client'

export const TRANSFORM_TEXT_GQL = gql`
  mutation TransformText(
    $text: String!
    $operation: TransformationOperation!
    $params: JSON
  ) {
    transformText(text: $text, operation: $operation, params: $params) {
      text
    }
  }
`
