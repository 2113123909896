import { Editor, Extension, findParentNode } from '@tiptap/core'
import isNil from 'lodash/isNil'
import { Node } from 'prosemirror-model'
import { EditorState, Plugin, NodeSelection } from 'prosemirror-state'

import { getStore, AppDispatch } from 'modules/redux'

import {
  selectCardCollapsed,
  selectMode,
  setCardsCollapsed,
} from '../../reducer'
import { EditorModeEnum } from '../../types'
import { isCardNode } from '../../utils/nodeHelpers'
import { CARD_DEPTH } from './constants'

export const CardCollapse = Extension.create({
  name: 'cardCollapse',

  addKeyboardShortcuts() {
    return {
      // Close expanded cards when you hit Esc in doc mode
      Escape: ({ editor }) => {
        // Are we in doc mode
        const store = getStore()
        const mode = selectMode(store.getState())
        if (mode !== EditorModeEnum.DOC_VIEW) return false

        // Is selection inside an expanded card
        const { selection } = editor.state
        if (!selection.empty) return false
        const parentCard = findParentNode(isCardNode)(selection)

        if (
          parentCard &&
          parentCard.depth > CARD_DEPTH && // Top level cards have a depth of 2 (document is 1)
          !isCardCollapsed(parentCard.node)
        ) {
          setCardCollapsed(parentCard.node.attrs.id, true)
          return true
        }
        return false
      },
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        // Prevent the cursor from getting stuck inside a collased card, eg when you arrow up from below one
        // Note that just opeing/closing card doesn't create a transaction right now
        appendTransaction: (_, oldState, newState) => {
          if (
            newState.selection.eq(oldState.selection) ||
            !newState.selection.empty
          )
            return

          // Selection changed, let's make sure it's not somewhere it shouldn't be
          const parentCard = findParentNode(isCardNode)(newState.selection)
          if (!parentCard) return

          const { node, pos } = parentCard
          const isCollapsed = isCardCollapsed(node)
          if (isCollapsed) {
            // Move the selection up to the card node itself
            try {
              const { tr } = newState
              const newSelection = NodeSelection.create(tr.doc, pos)
              if (!newSelection) return
              return tr.setSelection(newSelection)
            } catch (e) {
              console.error(
                'Error moving selection out of collapsed card',
                { node, pos },
                e
              )
            }
          }
          return
        },
      }),
    ]
  },
})

export const setDefaultCardCollapse = (
  rootNode: Node,
  dispatch: AppDispatch
) => {
  const cardIdsToExpand: string[] = []
  const cardIdsToCollapse: string[] = []

  rootNode.descendants((node, pos, parent) => {
    if (!isCardNode(node)) return
    const { id } = node.attrs

    // Always expand top-level cards (for now)
    const topLevelCard = parent && parent.type.name === 'document'
    if (topLevelCard) {
      cardIdsToExpand.push(id)
    } else {
      cardIdsToCollapse.push(id)
    }
  })

  dispatch(setCardsCollapsed({ cardIds: cardIdsToExpand, isCollapsed: false }))
  dispatch(setCardsCollapsed({ cardIds: cardIdsToCollapse, isCollapsed: true }))
}

export const isCardCollapsed = (nodeOrCardId: Node | string) => {
  const id =
    typeof nodeOrCardId === 'string' ? nodeOrCardId : nodeOrCardId.attrs.id
  if (!id) return
  const state = getStore().getState()
  return selectCardCollapsed(id)(state)
}

export const setCardCollapsed = (
  input: string | string[],
  isCollapsed: boolean
) => {
  if (isNil(input)) {
    // Attempt to mitigate this issue: https://sentry.io/organizations/gamma-tech/issues/3463686772/?project=5776661&query=is%3Aunresolved+null+is+not+an+object&statsPeriod=14d
    // Its unclear how input would ever be falsey here
    console.error(
      '[setCardCollapsed] Input is unexpectedly not string | string[]: ',
      input
    )
    return
  }
  const cardIds = Array.isArray(input) ? input : [input]
  const store = getStore()
  // Short circuit for performance reasons if the change is a no-op
  // This will fire twice when youre following someone in present mode
  //   * Once when the memoState.expandedCards is synced
  //   * Once when usePresentVariant changes
  if (
    cardIds.length === 1 &&
    selectCardCollapsed(cardIds[0])(store.getState()) === isCollapsed
  ) {
    return
  }

  store.dispatch(setCardsCollapsed({ cardIds, isCollapsed }))
}

export const expandAllCards = (editor: Editor) => {
  const ids: string[] = []
  editor.state.doc.descendants((node) => {
    if (!isCardNode(node)) return
    const { id } = node.attrs
    ids.push(id)
  })
  setCardCollapsed(ids, false)
}

// Sets the state of a card to expanded if its state is not already set.
export const initializeCardExpanded = (cardId: string) => {
  const store = getStore()
  const state = store.getState()
  if (state.TipTap.memoState.expandedCards[cardId] === undefined) {
    store.dispatch(setCardsCollapsed({ cardIds: [cardId], isCollapsed: false }))
  }
}
