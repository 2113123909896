import {
  findParentNode,
  isNodeEmpty,
  JSONContent,
  mergeAttributes,
  Node,
} from '@tiptap/core'
import { round } from 'lodash'
import { Node as ProsemirrorNode } from 'prosemirror-model'
import { Selection } from 'prosemirror-state'

import { ignoreDataMutation } from 'modules/tiptap_editor/utils/ignoreMutation'
import { findSelectionNearOrGapCursor } from 'modules/tiptap_editor/utils/selection/findSelectionNearOrGapCursor'
import { dispatchContainerResizeEvent } from 'utils/hooks/useContainerResizing'

import { ReactNodeViewRenderer } from '../../react'
import { configureJSONAttribute, isTreeEmpty } from '../../utils'
import { computeDeleteLayoutAnnotationMoves } from '../Annotatable/utils'
import { ExtensionPriorityMap } from '../constants'
import {
  addColWidth,
  createColumnWidths,
  removeColWidth,
} from '../tables/prosemirror-table/columnUtils'
import { numChildrenOrAttrsOrDecorationsChanged } from '../updateFns'
import { deleteLayoutCell } from './commands'
import { MAX_COLUMNS } from './constants'
import { LayoutCell } from './LayoutCell'
import { LayoutPlugin } from './LayoutPlugin'
import { COL_MIN_PERCENT, getLayoutElement } from './LayoutResizing/input'
import { createLayoutResizingPlugin } from './LayoutResizing/LayoutResizingPlugin'
import { LayoutView } from './LayoutView'
import {
  getColIndex,
  getLayoutCellResolvedPos,
  getLayoutChildren,
  getParentLayout,
} from './utils'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    layout: {
      handleLayoutDelete: (
        forward: boolean,
        requireEmpty?: boolean
      ) => ReturnType
      goToNextLayoutCell: (forward: boolean) => ReturnType
      insertLayout: (columns: number) => ReturnType
      addLayoutCell: (pos: number, focus?: boolean) => ReturnType
      useLayoutPreset: (pos: number, colWidths: number[]) => ReturnType
    }
  }
}

export type FindParentNodeResult =
  | {
      pos: number
      start: number
      depth: number
      node: ProsemirrorNode
    }
  | undefined

export type LayoutAttrs = {
  colWidths: string[]
  fullWidthBlock: boolean
}

export const Layout = Node.create({
  name: 'gridLayout',
  content: `gridCell{1, ${MAX_COLUMNS}}`, // https://prosemirror.net/docs/guide/#schema.content_expressions
  group: 'cardBlock',
  defining: false,
  isolating: true,
  selectable: false,
  priority: ExtensionPriorityMap.Layout,
  containerHandle: true,

  parseHTML() {
    return [
      {
        tag: 'div[class=grid-layout]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'grid-layout' }), 0]
  },

  addAttributes() {
    return {
      colWidths: {
        ...configureJSONAttribute('colWidths'),
        default: [],
      },
      fullWidthBlock: {
        default: false,
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(LayoutView, {
      update: numChildrenOrAttrsOrDecorationsChanged,
      ignoreMutation: ignoreDataMutation,
    })
  },

  addProseMirrorPlugins() {
    return [LayoutPlugin(this.editor), createLayoutResizingPlugin()]
  },

  addCommands() {
    return {
      useLayoutPreset:
        (pos, colWidths) =>
        ({ dispatch, tr, commands, view }) => {
          if (!dispatch) return true

          const $layout = getParentLayout(tr.doc.resolve(pos))
          if (!$layout) {
            return true
          }

          const layoutChildren = getLayoutChildren($layout)
          let diff = colWidths.length - layoutChildren.length

          // collect content to append to last cell if removing cells
          let content: JSONContent[] = []
          while (diff !== 0) {
            // need to remove
            if (diff < 0) {
              const $cell = getLayoutCellResolvedPos(tr.doc.resolve(pos), -1)!
              const cell = $cell.nodeAfter!

              tr.deleteRange($cell.pos, $cell.pos + cell.nodeSize)
              const removedContent =
                cell.childCount === 1 && isNodeEmpty(cell.firstChild!)
                  ? []
                  : cell.content.toJSON()

              content = [...removedContent, ...content]
              diff++
              continue
            }
            if (diff > 0) {
              commands.addLayoutCell($layout.pos, false)
              diff--
              continue
            }
          }
          tr.setNodeAttribute(pos, 'colWidths', colWidths)

          // insert any deleted content if removing cells
          const $lastCell = getLayoutCellResolvedPos(
            tr.doc.resolve($layout.pos),
            -1
          )!

          if (!$lastCell || !$lastCell.nodeAfter) {
            // this
            return true
          }

          const lastCellEndPos =
            $lastCell.pos + $lastCell.nodeAfter.nodeSize - 1

          commands.insertContentAt(lastCellEndPos, content, {
            updateSelection: false,
          })

          if (
            tr.selection.from > lastCellEndPos ||
            tr.selection.to > lastCellEndPos
          ) {
            // put selection at end of the last cell
            const lastCellEndPosAfterInsert = tr.doc
              .resolve($lastCell.pos + 1)
              .end()
            const sel = findSelectionNearOrGapCursor(
              tr.doc.resolve(lastCellEndPosAfterInsert),
              -1
            )
            if (sel) {
              tr.setSelection(sel)
            }
          }

          // dispatch container resize to image handles
          const el = getLayoutElement(view, $layout)
          if (el) {
            dispatchContainerResizeEvent(el)
          }

          return true
        },

      insertLayout:
        (columns) =>
        ({ dispatch, commands }) => {
          if (!dispatch) return true
          const cells = Array.from(Array(columns), (_) => EMPTY_CELL)

          return commands.insertContentAndSelect({
            type: 'gridLayout',
            attrs: {
              colWidths: createColumnWidths(columns),
            },
            content: cells,
          })
        },

      addLayoutCell:
        (pos, focus = true) =>
        ({ chain, state }) => {
          const node = state.doc.nodeAt(pos)
          if (!node || node.type.name !== Layout.name) return false
          const insertPos = pos + node.nodeSize - 1 // Just inside the layout, at the end
          const { colWidths } = node.attrs

          // always adding col to end
          const col = colWidths.length
          const newColSize = round(100 / (col + 1), 2)

          const newColWidths = addColWidth(
            colWidths,
            col,
            newColSize,
            COL_MIN_PERCENT
          )

          const chained = chain().insertContentAt(insertPos, EMPTY_CELL, {
            updateSelection: false,
          })
          if (focus) {
            chained.selectInsertedNode()
          }
          chained
            .command(({ tr }) => {
              tr.setNodeAttribute(pos, 'colWidths', newColWidths)
              return true
            })
            .run()

          return true
        },

      handleLayoutDelete:
        (forward, requireEmpty = true) =>
        ({ tr, dispatch, state }) => {
          if (!dispatch) return true

          const parentCell = findParentNode(
            (n) => n.type.name === LayoutCell.name
          )(state.selection) as FindParentNodeResult

          const parentLayout = findParentNode(
            (n) => n.type.name === Layout.name
          )(state.selection) as FindParentNodeResult

          if (!parentCell || !parentLayout) return false

          if (!requireEmpty || isTreeEmpty(parentCell.node)) {
            // If deleting this cell would take us down to 1, unwrap the whole layout
            if (parentLayout.node.childCount == 2) {
              // deleting the left most column
              const deletingLeft =
                parentCell.node === parentLayout.node.child(0)

              // otehr child is a gridCell node
              const otherChild = deletingLeft
                ? parentLayout.node.child(1)
                : parentLayout.node.child(0)

              // parentLayout.start represents the pos of the gridCell
              const contentPos = deletingLeft
                ? parentLayout.start + parentLayout.node.child(0).nodeSize + 1
                : parentLayout.start + 1

              // use otherChild content size, not nodeSize because we dont want to count
              // the start and end of the gridCell
              const contentEnd = contentPos + otherChild.content.size

              tr.replaceWith(
                parentLayout.pos,
                parentLayout.pos + parentLayout.node.nodeSize,
                otherChild.content
              )

              // after deleting find selection either at beginning (parentLayout.pos) or
              // at end parentLayout.pos + otherChild.content.size
              const sel = findSelectionNearOrGapCursor(
                tr.doc.resolve(
                  parentLayout.pos +
                    (!deletingLeft ? otherChild.content.size : 0)
                ),
                deletingLeft ? 1 : -1
              )
              if (sel) {
                tr.setSelection(sel)
              }

              // compute all of the moved annotations
              const instructions = computeDeleteLayoutAnnotationMoves({
                insertPos: parentLayout.pos,
                contentPos,
                contentEnd,
                editor: this.editor,
              })
              requestAnimationFrame(() => {
                this.editor.commands.moveAnnotations?.(instructions)
              })
            } else {
              const $cell = tr.doc.resolve(parentCell.pos)
              deleteLayoutCell(tr, $cell, forward)
              tr.setNodeAttribute(
                parentLayout.pos,
                'colWidths',
                removeColWidth(parentLayout.node.attrs.colWidths, [
                  getColIndex($cell),
                ])
              )
            }

            return true
          }

          // If you backspace an empty paragraph at the start of a column, delete it
          // https://linear.app/gamma-app/issue/G-1677/no-way-to-delete-text-node-at-the-top-of-a-column
          const { parent } = state.selection.$from
          if (
            state.selection.empty &&
            parent.isTextblock &&
            isNodeEmpty(parent) &&
            parent == parentCell.node.firstChild &&
            !forward
          ) {
            tr.deleteRange(
              state.selection.$from.before(),
              state.selection.$from.after()
            )
            return true
          }

          return false
        },
      goToNextLayoutCell:
        (forward) =>
        ({ tr, state }) => {
          const parentCell = findParentNode(
            (n) => n.type.name === LayoutCell.name
          )(state.selection)
          const parentLayout = findParentNode(
            (n) => n.type.name === Layout.name
          )(state.selection)
          if (!parentCell || !parentLayout) return false

          const $cell = state.doc.resolve(parentCell.pos)
          const nextIndex = forward ? $cell.index() + 1 : $cell.index() - 1
          if (nextIndex < 0 || nextIndex >= $cell.parent.childCount)
            return false
          const nextPos = $cell.posAtIndex(nextIndex)

          tr.setSelection(Selection.near(state.doc.resolve(nextPos)))
          return true
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        return editor.commands.goToNextLayoutCell(true)
      },
      'Shift-Tab': ({ editor }) => {
        return editor.commands.goToNextLayoutCell(false)
      },
    }
  },
})

const EMPTY_CELL = {
  type: 'gridCell',
  content: [
    {
      type: 'paragraph',
    },
  ],
}
