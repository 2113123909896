import { cx } from '@chakra-ui/utils'
import { NodeViewProps } from '@tiptap/react'
import { useCallback } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { useSendSlideToSlideEvent } from 'modules/performance/slideToSlidePerf'
import { useAppDispatch, useAppStore } from 'modules/redux'
import { stopPropagation } from 'utils/handlers'

import { NodeViewContent } from '../../../react'
import {
  selectIsAllowedToEdit,
  setFollowingAttached,
  setIsEditingInSlideView,
} from '../../../reducer'
import { AnnotatableNodeViewWrapper } from '../../Annotatable'
import { hasBlockHoverDeco } from '../../block/BlockHoverPlugin'
import { useDocFlag } from '../../DocFlags/hooks'
import { ContainerDragHandle } from '../../DragDrop/ContainerDragHandle/ContainerDragHandle'
import { isSelectingNode } from '../../selection/FocusedNodes'
import { CardDivider } from '../CardDivider'
import { getDisplayLayout } from '../CardLayout/cardLayoutUtils'
import { findCardPluginDecoration } from '../CardPlugin'
import { useCardStyles } from '../CardStyles'
import { CollapsedCardPreview } from '../CollapsedCardPreview'
import { CARD_CONTENT_CLASS } from '../constants'
import { useCardState } from '../hooks/useCardState'
import { ManageCardControls } from '../ManageCardControls'
import { CardLayout } from '../types'
import { Card2Body } from './Card2Body'
import { Card2Layout } from './Card2Layout'
import { Card2Wrapper } from './Card2Wrapper'
import { CardDebugPanel } from './CardDebugPanel'
import { CardLayoutDropdownMenu } from './CardLayoutDropdownMenu'

/**
 * The React Component TipTap uses to render a card node view.
 * Read about it's API here: https://www.tiptap.dev/guide/node-views/react#render-a-react-component
 */
export const CardView2 = (nodeViewProps: NodeViewProps) => {
  /**
   *  *********************** WARNING! *******************************
   * Excess re-renders of this component can cause performance issues.
   * This is especially noticeable when switching between DOC<>SLIDE views.
   * If you're adding new hooks or selectors that observe state changes, make
   * sure to QA entering & exiting present mode. Each card should not render more
   * than once.
   *  *********************** WARNING! *******************************
   */
  const dispatch = useAppDispatch()
  const store = useAppStore()
  const { getPos, node, editor, decorations } = nodeViewProps
  const cardPluginSpec = findCardPluginDecoration(decorations)
  const docId = editor.gammaDocId
  const cardLayoutsEnabled = useDocFlag(editor, 'cardLayoutsEnabled')

  const cardState = useCardState(nodeViewProps)
  const {
    isPresentMode,
    isNested,
    isFocused,
    isEditable,
    isFirstCard,
    attrs,
    cardId,
    isFullBleed,
  } = cardState
  const { layout } = attrs

  // Slide to Slide RUM
  const realUserMetricsEnabled = useFeatureFlag('realUserMetrics')
  useSendSlideToSlideEvent(
    realUserMetricsEnabled,
    cardId,
    cardState.presentVariant,
    cardState.prevPresentVariant
  )

  // Card styles
  const cardStyles = useCardStyles({
    node,
    ...cardState,
  })

  console.debug(
    `%c[CardComponent] Card id ${cardId} is rerendering`,
    'background-color: aqua',
    {
      cardState,
      cardStyles,
    }
  )

  const editCardInPresentMode = useCallback(() => {
    const isAllowedToEdit = selectIsAllowedToEdit(store.getState())
    if (!isAllowedToEdit) return
    dispatch(setIsEditingInSlideView({ isEditingInSlideView: true }))
    // Detach so they can edit without being dragged around while following
    dispatch(setFollowingAttached({ attached: false }))
    editor.commands.turnOffSpotlight()
  }, [editor, dispatch, store])

  const setLayout = useCallback(
    (l: CardLayout) => {
      editor
        .chain()
        .setCardLayout(getPos(), l)
        .selectInsideCardBody(getPos())
        .focus()
        .run()
    },
    [editor, getPos]
  )
  const isHovering = hasBlockHoverDeco(decorations)
  const isSelected = isSelectingNode(decorations)

  const shouldShowLayoutMenu =
    isEditable && !isNested && (isHovering || isFocused || isSelected)
  const displayLayout = getDisplayLayout({ layout, isNested })

  return (
    // CARD WRAPPER: this node is *almost* the top level of the Card, but the actual root is the .react-renderer just outside this which has decoration classes
    // Diagram of these components: https://miro.com/app/board/o9J_l8hO-qI=/
    <AnnotatableNodeViewWrapper
      as="div"
      style={{
        minHeight: isPresentMode && !isNested ? '100vh' : 0,
      }} // Prevents all the cards from going behind this when you enter present mode
      hideComments={true}
      {...nodeViewProps}
    >
      <Card2Wrapper {...cardState}>
        <Card2Body {...cardState}>
          <Card2Layout
            layout={displayLayout}
            {...cardState}
            hasCardLayoutItems={cardPluginSpec.hasLayoutItem}
            onDoubleClick={
              isPresentMode && !isEditable ? editCardInPresentMode : undefined
            }
          >
            <CardDebugPanel {...nodeViewProps} />
            <ContainerDragHandle
              {...nodeViewProps}
              data-testid="card-drag-handle"
              zIndex={3}
              left={2}
              top={isFirstCard && isFullBleed ? 13 : 3}
              width="18px"
              height="25px"
              borderRadius="4px"
            />
            {shouldShowLayoutMenu && (
              <CardLayoutDropdownMenu
                layout={layout}
                setLayout={setLayout}
                position="absolute"
                mt={3}
                left={8}
                // set above ContainerDragHandle
                zIndex={4}
                // dont trigger selectCardEdges
                onClick={stopPropagation}
              />
            )}
            <CollapsedCardPreview {...cardState} node={node} />

            <NodeViewContent
              className={cx(
                CARD_CONTENT_CLASS,
                isFocused && 'is-focused',
                isNested && 'is-nested',
                (isPresentMode || isFullBleed) && 'is-full-width',
                cardState.isDark && 'is-dark'
              )}
              {...(isPresentMode &&
                !cardLayoutsEnabled && {
                  style: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  },
                })}
            />

            <ManageCardControls
              top={[1, isFirstCard && isFullBleed ? '54px' : 3]}
              docId={docId}
              hasCardBackground={cardStyles.hasCardBackground}
              // dont show in cardView2 becuase we have the CardFormattingMenu
              hideManageCardMenuButton={true}
              {...cardState}
              {...nodeViewProps}
            />
          </Card2Layout>
        </Card2Body>

        <CardDivider
          display={isEditable && !isNested && !isPresentMode ? 'flex' : 'none'}
          editor={editor}
          getPos={getPos}
          isNested={isNested}
        />
      </Card2Wrapper>
    </AnnotatableNodeViewWrapper>
  )
}
