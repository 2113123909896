import { Box } from '@chakra-ui/react'
import { Editor, NodeViewProps } from '@tiptap/core'
import React, { memo, useCallback, useEffect, useState } from 'react'

import { useHealthCheck } from 'modules/api'
import { UnsplashProvider } from 'modules/media'
import { OfflineInfoBox } from 'modules/offline'
import {
  MediaSourcesMap,
  MediaSourceType,
  NO_BACKGROUND_SOURCE_KEY,
} from 'modules/tiptap_editor/extensions/media/MediaSources'

import { BackgroundOptions } from '../../styles/backgroundStyles'
import { MediaDrawerMenu } from '../drawers/MediaDrawer/MediaDrawerMenu'

type BackgroundPanelProps = {
  editor?: Editor
  updateAttributes: NodeViewProps['updateAttributes']
  background: BackgroundOptions
  defaultMessage: React.ReactNode
  isDark?: boolean
}

const isValidBackgroundSource = (source: MediaSourceType) =>
  !!source.backgroundType

export const BackgroundPanel = memo(
  ({
    editor,
    updateAttributes,
    background,
    defaultMessage,
    isDark,
  }: BackgroundPanelProps) => {
    const [currentSourceKey, setCurrentSourceKey] = useState(
      UnsplashProvider.key
    )
    const { isConnected } = useHealthCheck()

    const currentSource = MediaSourcesMap[currentSourceKey]
    const setBackgroundAttributes = useCallback(
      (attributes) => {
        updateAttributes({
          background: {
            ...background, // The current background
            type: currentSource.backgroundType,
            [currentSource.backgroundType as string]: {
              ...attributes,
            },
            source: currentSourceKey,
          },
        })
      },
      [updateAttributes, background, currentSource, currentSourceKey]
    )
    const getBackgroundAttributes = (bg) => {
      return bg[currentSource.backgroundType as string] || {}
    }
    const resetBackground = useCallback(
      (sourceKey) => {
        const source = MediaSourcesMap[sourceKey]
        updateAttributes({
          background: {
            type: source.backgroundType,
            source: sourceKey,
          },
        })
      },
      [updateAttributes]
    )

    const resetToPlaceholder = useCallback(
      () => resetBackground(currentSourceKey),
      [currentSourceKey, resetBackground]
    )

    const onChange = useCallback(
      (value: string) => {
        if (value === NO_BACKGROUND_SOURCE_KEY)
          resetBackground(NO_BACKGROUND_SOURCE_KEY)
        setCurrentSourceKey(value)
      },
      [resetBackground]
    )

    useEffect(() => {
      // Set source picker value on first load, and any time it changes
      if (background.source && MediaSourcesMap[background.source]) {
        setCurrentSourceKey(background.source)
      }
    }, [background.source])

    const isConnectedOrAvailableOffline = Boolean(
      isConnected || currentSource?.availableOffline
    )

    return (
      <>
        <OfflineInfoBox
          isConnected={isConnectedOrAvailableOffline}
          label="Some background options will not be available until you reconnect."
          mb={3}
          my={6}
        />
        <MediaDrawerMenu
          isValidSource={isValidBackgroundSource}
          onChange={onChange}
          currentSource={currentSource}
        />
        {currentSource && (
          <Box
            opacity={isConnectedOrAvailableOffline ? 1 : 0.4}
            pointerEvents={isConnectedOrAvailableOffline ? 'initial' : 'none'}
          >
            <currentSource.Panel
              editor={editor}
              updateAttributes={setBackgroundAttributes}
              currentAttributes={getBackgroundAttributes(background)}
              resetToPlaceholder={resetToPlaceholder}
              editType="background"
              isDark={isDark}
            />
          </Box>
        )}
        {currentSourceKey === NO_BACKGROUND_SOURCE_KEY && defaultMessage}
      </>
    )
  }
)

BackgroundPanel.displayName = 'BackgroundPanel'
