import { Editor, findChildren } from '@tiptap/core'
import { UppyFile } from '@uppy/core'
import merge from 'lodash/merge'
import { Node as ProsemirrorNode } from 'prosemirror-model'

import { ImageUploadResult, UploadStatus } from 'modules/media'
import { ImageAttrs } from 'modules/media/types/Image'
import { formatBytes } from 'utils/file'

import { WebEmbedAttrs } from '../types'
import { MediaUploadStorage } from './UploadExtension'
const REVOKE_TEMP_URL_TIMEOUT = 30000

type UploadResult =
  | {
      result?: undefined
      isError: true
    }
  | {
      isError: false
      result: ImageUploadResult
    }

export const getUploadedImageAttrs = ({
  result,
  isError,
}: UploadResult): Partial<ImageAttrs> => {
  if (isError) {
    return {
      uploadStatus: UploadStatus.Error,
      showPlaceholder: true,
      tempUrl: null,
      src: null,
    }
  }

  return {
    uploadStatus: UploadStatus.Done,
    showPlaceholder: false,
    ...result,
  }
}

/**
 * Merges the provided image attributes into the node's existing attributes
 * at the keypath defined by spec.imageKeyPath, otherwise at the root.
 *
 * TODO - Handle nested imageKeyPaths like `config.foo.image`
 */
export const mergeImageAttrs = (
  node: ProsemirrorNode,
  attrs: Partial<ImageAttrs>
) => {
  const key = node.type.spec.imageKeyPath
  const mergeObj = key
    ? {
        [key]: attrs,
      }
    : attrs
  return merge({}, node.attrs, mergeObj)
}

const findImagesWithUrl = (editor: Editor, existingUrl: string) =>
  // Find the image with placeholder we inserted via attrs.tempUrl
  findChildren(editor.state.doc, (node) => {
    const attrsObj = node.type.spec.imageKeyPath
      ? node.attrs[node.type.spec.imageKeyPath]
      : node.attrs

    return [attrsObj?.tempUrl, attrsObj?.src].includes(existingUrl)
  })

const findEmbedsWithUrl = (editor: Editor, existingUrl: string) =>
  // Find the embed with placeholder we inserted via attrs.url
  findChildren(editor.state.doc, (node) => {
    return node.type.name === 'embed' && node.attrs.url === existingUrl
  })

export const handleImageUploadSuccess = (
  editor: Editor,
  existingUrl: string,
  result: ImageUploadResult
) => {
  if (existingUrl) {
    updateMediaUploadStorage(editor, result, existingUrl)
  }

  const matches = findImagesWithUrl(editor, existingUrl)
  if (matches.length == 0) {
    // This can happen if the user hits undo or deletes before the upload is done
    console.warn("[MediaUpload] Couldn't find placeholder node")
    return
  }
  matches.forEach(({ pos }) => {
    editor.commands.updateImageOnUploadSuccess(pos, result)
  })

  setTimeout(() => {
    editor.commands.revokeImageTempURLs(existingUrl)
  }, REVOKE_TEMP_URL_TIMEOUT)
}

export const handleImageUploadFailed = (
  editor: Editor,
  existingUrl: string,
  errorMessage?: string
) => {
  const matches = findImagesWithUrl(editor, existingUrl)
  if (matches.length == 0) {
    // This can happen if the user hits undo or deletes before the upload is done
    console.warn("[MediaUpload] Couldn't find placeholder node")
    return
  }
  matches.forEach((match) =>
    editor.commands.updateImageOnUploadFailure(match.pos)
  )
  // TODO handle upload errors here
  console.error(errorMessage)
}

// Track the upload result so if the user copies the placeholder and pastes it
// during upload, we can put it back in place in transformPasted
const updateMediaUploadStorage = (
  editor: Editor,
  result: ImageUploadResult,
  tempUrl: string
) => {
  const uploadStorage: MediaUploadStorage = editor.storage.mediaUpload
  uploadStorage.completedUploads[tempUrl] = result
}

export const isGammaCDNUrl = (url: string) => {
  return (
    url.startsWith('https://cdn.gamma.app/') ||
    url.startsWith('https://cdn-staging.gamma.app/')
  )
}

export const handlePDFUploadSuccess = (
  editor: Editor,
  existingUrl: string,
  result: ImageUploadResult
) => {
  const matches = findEmbedsWithUrl(editor, existingUrl)
  if (matches.length == 0) {
    // This can happen if the user hits undo or deletes before the upload is done
    console.warn("[MediaUpload] Couldn't find placeholder node")
    return
  }
  matches.forEach((match) => {
    editor.commands.updateAttributesAtPos(match.pos, {
      url: result.src,
      sourceUrl: result.src,
      embed: {
        url: result.src,
      },
      thumbnail: {
        src: result.thumbnail,
        uploadStatus: UploadStatus.Done,
        showPlaceholder: false,
      },
    })
  })
}

export const getTempPDFAttrs = (
  file: File | UppyFile
): Partial<WebEmbedAttrs> => {
  return {
    meta: {
      title: file.name,
      description: formatBytes(file.size, 1),
    },
    thumbnail: {
      uploadStatus: UploadStatus.Uploading,
      showPlaceholder: true,
    },
    source: 'embed.pdf',
  }
}
