import { findParentNode } from '@tiptap/core'
import { Fragment, Node, Slice } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { fragmentToArray, isNodeEmpty } from 'modules/tiptap_editor/utils'

import { createSelectionNearLastTo } from '../../utils/selection/findSelectionNearOrGapCursor'
import { fontSizeFromNode } from '../Font/utils'
import { isToggleSummaryNode } from './utils'

type ToggleDecorationSpec = {
  isToggleDecoration: true
  toggleId: string
  isEmpty: boolean
}

type ToggleDecoration = Decoration & {
  spec: ToggleDecorationSpec
}

export const TogglePlugin = () =>
  new Plugin({
    props: {
      handlePaste(view, event, slice) {
        // Check if we're inside a summary
        const { selection, tr } = view.state
        const summary = findParentNode(isToggleSummaryNode)(selection)
        if (!summary) return false

        // The first textblock you paste's content should go into the summary and update fontSize if needed
        let summaryContent: Fragment | null = null
        let fontSize: string | null = null
        const bodyContent: Node[] = []
        // Subsequent blocks should paste into the content, keeping their original types
        slice.content.descendants((node) => {
          if (!node.isTextblock) return
          if (summaryContent) {
            bodyContent.push(node)
          } else {
            summaryContent = node.content
            fontSize = fontSizeFromNode(node) || null
          }
        })

        if (!summaryContent) return

        if (fontSize) {
          tr.setNodeAttribute(summary.pos, 'fontSize', fontSize)
        }
        tr.replaceSelection(Slice.maxOpen(summaryContent))
        const afterSummaryPos = tr.mapping.map(
          summary.pos + summary.node.nodeSize
        )
        tr.replaceRange(
          afterSummaryPos,
          afterSummaryPos,
          Slice.maxOpen(Fragment.fromArray(bodyContent))
        )
        const sel = createSelectionNearLastTo(tr, -1)
        if (sel) {
          tr.setSelection(sel)
        }

        view.dispatch(tr)
        return true
      },

      decorations({ doc }) {
        const decorations: Decoration[] = []

        doc.descendants((node, pos, parent) => {
          if (!parent || !isToggleSummaryNode(node)) {
            return
          }
          const peers = fragmentToArray(parent.content)
          const isEmpty = peers.slice(1).every((n) => isNodeEmpty(n))
          const decoration = Decoration.node(
            pos,
            pos + node.nodeSize,
            {},
            {
              isToggleDecoration: true,
              toggleId: parent!.attrs.id,
              isEmpty,
            }
          )
          decorations.push(decoration)
        })
        return DecorationSet.create(doc, decorations)
      },
    },
  })

export const findToggleDecoration = (
  decorations: Decoration[]
): Partial<ToggleDecorationSpec> => {
  return (
    decorations.find((d): d is ToggleDecoration => d.spec.isToggleDecoration)
      ?.spec || {}
  )
}
