import { Box } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { gammaTheme } from '@gamma-app/ui'
import { EditorOptions } from '@tiptap/core'
import { NodeViewProps } from '@tiptap/react'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

// import { Reaction, TargetType } from 'modules/api'
import { useHealthCheck } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { useAppDispatch, useAppSelector } from 'modules/redux'
import { useCanWithSelectDoc } from 'modules/tiptap_editor/hooks'
import { NodeViewWrapper } from 'modules/tiptap_editor/react'
import {
  selectCommentsEnabled,
  selectIsBlockCommentOpen,
  setCommentReactionOpen,
  setFollowingAttached,
} from 'modules/tiptap_editor/reducer'
import { colorWithOpacity } from 'utils/color'
import { isMobileDevice } from 'utils/deviceDetection'
import { useHover } from 'utils/hooks'

import { hasAnnotatableHoverDeco } from '../../block/BlockHoverPlugin'
import { DraftComment } from '../DraftCommentsExtension/types'
import { useMobileAddCommentPos } from '../hooks'
import { BlockCommentsStack } from './BlockCommentsStack/BlockCommentsStack'
import { createEmptyDraftComment, updateDraftComment } from './helpers'
import {
  SelectionData,
  useAnnotationComments,
  useAnnotationReactions,
  useDraftCommentsFromDecorations,
  useOnCommentSave,
} from './hooks'
import { BlockReaction } from './types'

/**
 * MAIN Component
 */
type AnnotatableNodeViewWrapperProps = {
  children: React.ReactNode
  as?: React.ElementType
  className?: string
  style?: React.CSSProperties
  // this prop is used to explicitly hide comments for certain blocks, like uncollapsed nested cards
  hideComments?: boolean
  readOnly?: boolean
} & NodeViewProps

export const AnnotatableNodeViewWrapper: React.FC<AnnotatableNodeViewWrapperProps> =
  ({
    children,
    as,
    className,
    style,
    hideComments = false,
    readOnly = false,
    ...nodeViewProps
  }) => {
    const { decorations } = nodeViewProps
    const isAnnotatable = decorations.some((d) => d.spec.isAnnotatable)
    if (!isAnnotatable) {
      return (
        <NodeViewWrapper as={as} className={className} style={style}>
          {children}
        </NodeViewWrapper>
      )
    }

    return (
      <AnnotatableComponent
        as={as}
        className={className}
        style={style}
        hideComments={hideComments}
        readOnly={readOnly}
        {...nodeViewProps}
      >
        {children}
      </AnnotatableComponent>
    )
  }

export const AnnotatableComponent: React.FC<AnnotatableNodeViewWrapperProps> =
  ({
    children,
    decorations,
    editor,
    getPos,
    as,
    className,
    style,
    hideComments,
    readOnly,
    node,
  }) => {
    const { isConnected } = useHealthCheck()
    const showActivator = hasAnnotatableHoverDeco(decorations)
    const addCommentPos = useMobileAddCommentPos({
      editor,
      decorations,
    })

    const enableReactions = useFeatureFlag('blockReactions')
    const userCanComment = useCanWithSelectDoc('comment')
    const doesEditorInstanceSupportComments = useAppSelector(
      selectCommentsEnabled
    )
    const blockCommentId = useMemo(() => nanoid(5), [])
    const blockAllowsCommenting = !hideComments && !readOnly
    const isBlockCommentOpen = useAppSelector(
      selectIsBlockCommentOpen(blockCommentId)
    )
    /**
     * This is the state of the component
     *   draftComments - come from decorations where the relative position maps inside this component
     *   savedDraftComment - is the first item in the list and presumed only one. This only one contraint can't be enforced with decorations, so we assume it's the first, but always cleanup every draftComment when closing the component
     *   localDraft - once opened, we create a new draft or load the savedDraft.  All editing is saved in this via the onCommentDraftUpdate function
     */
    const debugComments = useFeatureFlag('debugComments')
    const draftComments = useDraftCommentsFromDecorations(decorations)
    const savedDraftComment: DraftComment | null = draftComments[0] || null
    const [localDraft, setLocalDraft] = useState<DraftComment | null>(null)

    const dispatch = useAppDispatch()

    // This controls the state of the draft comment and saving loading the localDraft
    // in and other of the prosemirror draft comment plugin
    const createDraftComment = useCallback(
      (selectionData?: SelectionData) => {
        // selectionData is the optional data about the selection being commented on.
        // If present, we show and use it as the targetHtml and store its
        // specific position (the from) in the annotation state
        const getPosFn = selectionData?.getPos || getPos
        const targetHtml = selectionData?.targetHtml
        let draft = savedDraftComment
        if (!draft) {
          draft = createEmptyDraftComment(getPosFn, editor, targetHtml)
          editor.commands.createDraftComment?.(draft)
        } else if (selectionData) {
          // If we have an existing draft comment with new selection data,
          // update the draft with the new position and targetHtml.
          // This supports changing the selection without losing work
          draft = updateDraftComment(draft, getPosFn, targetHtml, editor)
        }
        setLocalDraft(draft)
        dispatch(setFollowingAttached({ attached: false }))
      },
      [dispatch, editor, getPos, savedDraftComment]
    )
    const cleanupDraftComment = useCallback(() => {
      localDraft && localDraft?.text?.trim()?.length > 0
        ? // update the draft comment in plugin state
          editor.commands.createDraftComment?.(localDraft)
        : // remove all draft comments
          editor.commands.removeDraftComments?.(draftComments)

      // always clear out the local draft state when closing the comment panel
      setLocalDraft(null)
    }, [draftComments, editor.commands, localDraft])

    // Keep track of the open state so we can use it in a component unmount hook cleanup
    const isCommentsPopupOpenRef = useRef(isBlockCommentOpen)
    isCommentsPopupOpenRef.current = isBlockCommentOpen
    useEffect(() => {
      return () => {
        // If the component is destroyed (e.g. via a rearrange action) while it is open,
        // be sure to set isAnyCommentOpen to false.
        if (isCommentsPopupOpenRef.current) {
          dispatch(setCommentReactionOpen({ isOpen: false, blockCommentId }))
        }
      }
    }, [blockCommentId, dispatch])

    // This will need to be updated to know whether it's a top-level comment, or
    // a reply to an ID
    const onCommentDraftUpdate: EditorOptions['onUpdate'] = ({
      editor: commentEditor,
    }) => {
      setLocalDraft((data) => {
        // uninitialized null case
        if (!data) return data

        return {
          ...data,
          json: commentEditor.view.state.doc.toJSON(),
          text: commentEditor.view.state.doc.textContent,
        }
      })
    }

    // this hook takes care of the heavy lifting of preparing comment data and persisting it
    const onCommentSave = useOnCommentSave({
      draftComment: localDraft,
      clearDraftComment() {
        if (localDraft) {
          editor.commands.removeDraftComments?.([localDraft, ...draftComments])
        }
        // reset to a new empty draft comment
        const draft = createEmptyDraftComment(getPos, editor)
        editor.commands.createDraftComment?.(draft)
        setLocalDraft(draft)
      },
      editor,
    })

    // UI Derived states
    const comments = useAnnotationComments(decorations)
    const hasComments = comments.length > 0 && !hideComments

    // When the component is not open the localDraft is null.  To show the proper
    // icon for draft comments we rely on the savedDraftComment which is provided
    // via the decorator
    const draftToPass = localDraft || savedDraftComment
    const hasDraftContent = draftToPass?.text?.length > 0
    const [ref, isHovering] = useHover<HTMLDivElement>()

    let reactions: BlockReaction[] = useAnnotationReactions(decorations)
    if (!enableReactions) {
      reactions = []
    }
    const hasReactions = reactions.length > 0

    // we want to render comments stack anytime the editor supports it
    // because this component includes the add comment buttons
    const shouldRenderCommentStack =
      doesEditorInstanceSupportComments && !hideComments && userCanComment
    // only show the comments stack if
    const isCommentStackVisible =
      (isMobileDevice &&
        (isBlockCommentOpen ||
          hasComments ||
          hasReactions ||
          !!addCommentPos)) ||
      // TODO can we check if selected here
      (!isMobileDevice &&
        (isHovering ||
          showActivator ||
          isBlockCommentOpen ||
          hasComments ||
          hasReactions ||
          hasDraftContent))

    const userCanCommentAndConnected = userCanComment && isConnected

    const bgHighlight =
      isBlockCommentOpen || addCommentPos
        ? colorWithOpacity(gammaTheme.colors.yellow[200], 0.25)
        : undefined

    return (
      <NodeViewWrapper
        as={as}
        style={{
          ...style,
          position: 'relative',
          backgroundColor: !isMobileDevice ? bgHighlight : undefined,
        }}
        className={cx(
          className,
          'annotatable-node-view-wrapper',
          isMobileDevice && 'is-mobile',
          debugComments ? 'debug-comments' : ''
        )}
        ref={ref}
      >
        {shouldRenderCommentStack && (
          <BlockCommentsStack
            nodeName={node.type.name}
            isVisible={isCommentStackVisible}
            reactions={reactions}
            blockCommentId={blockCommentId}
            userCanComment={userCanCommentAndConnected}
            blockAllowsCommenting={blockAllowsCommenting}
            onCommentDraftUpdate={onCommentDraftUpdate}
            cleanupDraftComment={cleanupDraftComment}
            createDraftComment={createDraftComment}
            editor={editor}
            getPos={getPos}
            comments={comments}
            draftComment={draftToPass}
            onCommentSave={onCommentSave}
            enableReactions={enableReactions}
            mobileAddCommentPos={addCommentPos}
          />
        )}
        {/**
         * if the comment stack is visible on mobile wrap in a box, so that we can highlight
         * the background without putting the highlight behind the comment stack, since its
         * rendered inline in mobile
         */}
        {isMobileDevice && bgHighlight ? (
          <Box bgColor={bgHighlight}>{children}</Box>
        ) : (
          children
        )}
      </NodeViewWrapper>
    )
  }
