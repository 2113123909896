import { mergeAttributes, Node } from '@tiptap/core'

import { ReactNodeViewRenderer } from '../../../react'
import { configureJSONAttribute } from '../../../utils'
import { attrsOrDecorationsChanged } from '../../updateFns'
import { getMediaSourceUrl, getMediaTitle } from '../utils'
import { VideoView } from './VideoView'

export const Video = Node.create({
  name: 'video',
  group: 'block media',
  atom: true,
  draggable: true,
  selectable: true,

  expandable: true,

  addNodeView() {
    return ReactNodeViewRenderer(VideoView, {
      update: attrsOrDecorationsChanged,
    })
  },

  addAttributes() {
    return {
      embedUrl: {},
      source: {},
      thumbnail: {
        ...configureJSONAttribute('thumbnail'),
      },
      embed: {
        ...configureJSONAttribute('embed'),
      },
      meta: {
        ...configureJSONAttribute('meta'),
      },
      sourceUrl: {},
      displayStyle: {
        default: 'inline',
      },
      fullWidthBlock: { default: false },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=video]',
      },
    ]
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'video' }),
      // This won't be used in our own internal copy paste, but is what shows up
      // when you copy from Gamma into another tool, or generate a comment preview
      [
        'a',
        { href: getMediaSourceUrl(node), target: '_blank' },
        getMediaTitle(node),
      ],
    ]
  },
})
