import { Flex } from '@chakra-ui/react'
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react'
import { memo } from 'react'

import { NodeViewContent } from 'modules/tiptap_editor/react'

import { ContainerDragHandle } from '../../DragDrop/ContainerDragHandle/ContainerDragHandle'

export const TableRowView = (nodeViewProps: NodeViewProps) => {
  return (
    <NodeViewWrapper
      className="table-row-wrapper"
      style={{ display: 'contents' }} // Skip rendering the wrapper so the TR is the immediate child of the table
    >
      <th style={{ position: 'relative', padding: 0 }}>
        <RowControl {...nodeViewProps}></RowControl>
      </th>
      <NodeViewContent
        as="div"
        className="table-row-content"
        style={{ display: 'contents' }}
      ></NodeViewContent>
    </NodeViewWrapper>
  )
}

type RowControlProps = NodeViewProps

const RowControl = memo(({ ...nodeViewProps }: RowControlProps) => {
  const { getPos, editor } = nodeViewProps
  const selectRow = () => {
    const rowPos = getPos() + 1
    editor.chain().focus().selectRow(rowPos).run()
  }

  return (
    <Flex
      height="100%"
      align="center"
      contentEditable="false"
      suppressContentEditableWarning
    >
      <ContainerDragHandle
        {...nodeViewProps}
        label="Select row"
        onClick={selectRow}
        left="-0.5em"
        top="1em"
        zIndex="3"
        data-testid="table-row-control"
      />
    </Flex>
  )
})

RowControl.displayName = 'RowControl'
