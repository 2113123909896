// Based on https://github.com/ProseMirror/prosemirror-dropcursor

import { Extension } from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'
import { dropPoint } from 'prosemirror-transform'
import { EditorView } from 'prosemirror-view'

import { checkBetweenCardsDropTarget } from '../Card'
import { checkColumnDropTarget, isLayoutCellNode } from '../Layout/utils'
import { checkGalleryDropTarget } from '../media/Gallery/utils'
import {
  checkSmartLayoutDropTarget,
  isSmartLayoutCellNode,
} from '../SmartLayout/utils'

// :: (options: ?Object) → Plugin
// Create a plugin that, when added to a ProseMirror instance,
// causes a decoration to show up at the drop position when something
// is dragged over the editor.
//
// Nodes may add a `disableDropCursor` property to their spec to
// control the showing of a drop cursor inside them. This may be a
// boolean or a function, which will be called with a view and a
// position, and should return a boolean.
//
//   options::- These options are supported:
//
//     color:: ?string
//     The color of the cursor. Defaults to `black`.
//
//     width:: ?number
//     The precise width of the cursor in pixels. Defaults to 1.
//
//     class:: ?string
//     A CSS class name to add to the cursor element.
export function dropCursor(options = {}) {
  return new Plugin({
    view(editorView) {
      return new DropCursorView(editorView, options)
    },
  })
}

type DropCursorTypes = 'default' | 'column' | 'gallery' | 'smartLayout'
type CursorType = {
  pos: number
  type: DropCursorTypes
  side?: 'left' | 'right' | 'bottom' | 'top'
  rect?: DOMRect
  node?: Node | null
}

class DropCursorView {
  editorView: EditorView
  width: number
  color: string
  class: string
  cursor: CursorType | null = null
  element: HTMLElement | null = null
  timeout: any = null
  handlers: { name: string; handler: (ev: any) => any }[]

  constructor(editorView, options) {
    this.editorView = editorView
    this.width = options.width || 1
    this.color = options.color || 'black'
    this.class = options.class

    // Set up event handlers
    this.handlers = ['dragover', 'dragend', 'drop', 'dragleave'].map((name) => {
      const handler = (e) => this[name](e)
      editorView.dom.addEventListener(name, handler)
      return { name, handler }
    })
  }

  // Clear out event handlers
  destroy() {
    this.handlers.forEach(({ name, handler }) =>
      this.editorView.dom.removeEventListener(name, handler)
    )
  }

  // If the state updates while dragging, redraw the cursor or clear if out of bounds
  update(editorView, prevState) {
    if (this.cursor != null && prevState.doc != editorView.state.doc) {
      if (this.cursor.pos > editorView.state.doc.content.size)
        this.setCursor(null)
      else this.updateOverlay()
    }
  }

  private setCursor(cursor: CursorType | null) {
    const pos = cursor?.pos || null
    if (
      pos == this.cursor?.pos &&
      cursor?.type == this.cursor?.type &&
      cursor?.side == this.cursor?.side
    )
      return
    this.cursor = cursor
    if (cursor == null) {
      this.element?.parentNode?.removeChild(this.element)
      this.element = null
    } else {
      this.updateOverlay()
    }
  }

  updateOverlay() {
    if (this.cursor == null || !this.editorView) return
    const $pos = this.editorView.state.doc.resolve(this.cursor.pos)
    let rect,
      message = ''
    if (
      this.cursor.type == 'gallery' &&
      this.cursor.node?.type.name !== 'gallery'
    ) {
      // Dropping over an image
      rect = this.cursor.rect
      message = 'Drop to make a gallery'
    } else if (
      this.cursor.type == 'column' ||
      this.cursor.type == 'gallery' ||
      this.cursor.type == 'smartLayout'
    ) {
      const nodeRect = this.cursor.rect
      if (!nodeRect) return
      const isAfter =
        this.cursor.side == 'right' || this.cursor.side == 'bottom'
      const node = isAfter ? $pos.nodeAfter : $pos.nodeBefore
      const offset =
        node && (isLayoutCellNode(node) || isSmartLayoutCellNode(node))
          ? 0
          : this.width
      if (this.cursor.side == 'right' || this.cursor.side == 'left') {
        const x = isAfter ? nodeRect.right + offset : nodeRect.left - offset
        rect = {
          top: nodeRect.top,
          bottom: nodeRect.bottom,
          left: x - this.width / 2,
          right: x + this.width / 2,
        }
      } else {
        const y = isAfter ? nodeRect.bottom + offset : nodeRect.top - offset
        rect = {
          left: nodeRect.left,
          right: nodeRect.right,
          top: y - this.width / 2,
          bottom: y + this.width / 2,
        }
      }
    } else if (!$pos.parent.inlineContent) {
      const before = $pos.nodeBefore,
        after = $pos.nodeAfter
      if (before || after) {
        const nodeRect = (
          this.editorView.nodeDOM(
            this.cursor.pos - (before ? before.nodeSize : 0)
          ) as HTMLElement
        ).getBoundingClientRect()

        let top = before ? nodeRect.bottom : nodeRect.top
        if (before && after)
          top =
            (top +
              (
                this.editorView.nodeDOM(this.cursor.pos) as HTMLElement
              ).getBoundingClientRect().top) /
            2
        rect = {
          left: nodeRect.left,
          right: nodeRect.right,
          top: top - this.width / 2,
          bottom: top + this.width / 2,
        }
      }
    }
    if (!rect) {
      const coords = this.editorView.coordsAtPos(this.cursor.pos)
      rect = {
        left: coords.left - this.width / 2,
        right: coords.left + this.width / 2,
        top: coords.top,
        bottom: coords.bottom,
      }
    }

    const parent = (this.editorView.dom as HTMLElement)
      .offsetParent as HTMLElement
    if (!this.element) {
      this.element = parent.appendChild(document.createElement('div'))
      if (this.class) this.element.className = this.class
      this.element.style.cssText =
        'position: absolute; z-index: 50; pointer-events: none; background-color: ' +
        this.color
    }
    let parentLeft, parentTop
    if (
      !parent ||
      (parent == document.body && getComputedStyle(parent).position == 'static')
    ) {
      parentLeft = -pageXOffset
      parentTop = -pageYOffset
    } else {
      const parentRect = parent.getBoundingClientRect()
      parentLeft = parentRect.left - parent.scrollLeft
      parentTop = parentRect.top - parent.scrollTop
    }
    this.element.style.left = rect.left - parentLeft + 'px'
    this.element.style.top = rect.top - parentTop + 'px'
    this.element.style.width = rect.right - rect.left + 'px'
    this.element.style.height = rect.bottom - rect.top + 'px'
    this.element.dataset.type = this.cursor.type
    this.element.innerHTML = message
  }

  scheduleRemoval(timeout) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.setCursor(null), timeout)
  }

  dragover(event) {
    if (!this.editorView.editable) return

    // Check for "special" drop types. Order matters - we want gallery to run first so an image-on-image
    // drop will create a gallery rather than columns
    const galleryDropTarget = checkGalleryDropTarget(
      this.editorView,
      event,
      this.editorView.dragging?.slice,
      !this.editorView.dragging // Needs upload if not dragging internally
    )
    if (galleryDropTarget) {
      this.setCursor({ ...galleryDropTarget, type: 'gallery' })
      this.scheduleRemoval(5000)
      return
    }

    const columnDropTarget = checkColumnDropTarget(
      this.editorView,
      event,
      this.editorView.dragging?.slice,
      !this.editorView.dragging // Needs upload if not dragging internally
    )
    // Column drops only work for internal drags, not dragging in from your desktop
    // because the upload plugin takes precedence.
    if (columnDropTarget) {
      this.setCursor({ ...columnDropTarget, type: 'column' })
      this.scheduleRemoval(5000)
      return
    }

    const smartLayoutDropTarget = checkSmartLayoutDropTarget(
      this.editorView,
      event,
      this.editorView.dragging?.slice
    )
    if (smartLayoutDropTarget) {
      this.setCursor({ ...smartLayoutDropTarget, type: 'smartLayout' })
      this.scheduleRemoval(5000)
      return
    }

    const betweenCardsDropTarget = checkBetweenCardsDropTarget(
      this.editorView,
      event,
      this.editorView.dragging?.slice
    )
    if (betweenCardsDropTarget) {
      this.setCursor({ pos: betweenCardsDropTarget.pos, type: 'default' })
      this.scheduleRemoval(5000)
      return
    }

    const pos = this.editorView.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    })

    const node =
      pos && pos.inside >= 0 && this.editorView.state.doc.nodeAt(pos.inside)
    const disableDropCursor = node && node.type.spec.disableDropCursor
    const disabled =
      typeof disableDropCursor == 'function'
        ? disableDropCursor(this.editorView, pos)
        : disableDropCursor

    if (pos && !disabled) {
      let target: number | null | undefined = pos.pos
      if (this.editorView.dragging && this.editorView.dragging.slice) {
        target = dropPoint(
          this.editorView.state.doc,
          target,
          this.editorView.dragging.slice
        )
        if (target == null) return this.setCursor(null)
      }
      this.setCursor({ pos: target, type: 'default' })

      this.scheduleRemoval(5000)
    }
  }

  dragend() {
    this.scheduleRemoval(20)
  }

  drop() {
    this.scheduleRemoval(20)
  }

  dragleave(event) {
    if (
      event.target == this.editorView.dom ||
      !this.editorView.dom.contains(event.relatedTarget)
    )
      this.setCursor(null)
  }
}

export interface DropcursorOptions {
  color: string | null
  width: number | null
  class: string | null
}

export const DropCursor = Extension.create<DropcursorOptions>({
  name: 'dropCursor',

  addOptions() {
    return {
      color: 'currentColor',
      width: 1,
      class: null,
    }
  },

  addProseMirrorPlugins() {
    return [dropCursor(this.options)]
  },
}).configure({
  color: 'var(--chakra-ring-color)',
  width: 3,
  class: 'ProseMirror-dropcursor',
})
