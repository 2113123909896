import { Divider, Flex } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import React from 'react'

import { useAllFeatureFlags } from 'modules/featureFlags'

import { InsertWidgetButtons } from './InsertWidgetButtons'
import { INSERTABLE_CATEGORIES_LIST } from './items'

export const InsertWidgetInner = ({ editor }: { editor: Editor }) => {
  const allFlags = useAllFeatureFlags()
  return (
    <Flex
      direction="column"
      bg="#F9FAFBDD"
      p={1}
      borderWidth="1px"
      borderColor="whiteAlpha.600"
      borderRadius="xl"
      shadow="xl"
    >
      {INSERTABLE_CATEGORIES_LIST.map((outer, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && <Divider mb={2} borderColor="blackAlpha.200" />}
            {outer.map(
              ({ name, itemGroups, icon, featureFlag, isHighlighted }) => {
                if (featureFlag && !allFlags[featureFlag]) return null

                return (
                  <InsertWidgetButtons
                    key={name}
                    itemGroups={itemGroups}
                    icon={icon}
                    name={name}
                    editor={editor}
                    isHighlighted={isHighlighted}
                  />
                )
              }
            )}
          </React.Fragment>
        )
      })}
    </Flex>
  )
}
