import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME, GammaTooltip } from '@gamma-app/ui'
import { Editor, NodeViewProps } from '@tiptap/core'
import { memo, useCallback, useState } from 'react'

import { useDrawerSize } from 'modules/tiptap_editor/components/drawers/hooks'
import { BackgroundPanel } from 'modules/tiptap_editor/components/panels/BackgroundPanel'
import { useForwardUndo } from 'modules/tiptap_editor/hooks/useForwardUndo'
import {
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/backgroundStyles'

import { backgroundSupportsMask } from '../BackgroundMask'
import { BackgroundMaskPanel } from './BackgroundMaskPanel'

type EditBackgroundDrawerProps = {
  editor: Editor
  // disclosure props
  isOpen: boolean
  onClose: () => void
  tabIndex: number
  setTabIndex: (tabIndex: number) => void
  background: BackgroundOptions
  updateAttributes: NodeViewProps['updateAttributes']
  enableMask?: boolean
  hasFullWidthChildrenOverride?: boolean
}

export const useEditBackgroundDrawerDisclosure = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [tabIndex, setTabIndex] = useState<number>(0)

  const openStyleDrawer = useCallback(
    (index: number) => {
      onOpen()
      setTabIndex(index)
    },
    [onOpen]
  )

  const openBackgroundTab = useCallback(() => {
    openStyleDrawer(0)
  }, [openStyleDrawer])
  const openStylesTab = useCallback(() => {
    openStyleDrawer(1)
  }, [openStyleDrawer])

  return {
    openBackgroundTab,
    openStylesTab,
    isOpen,
    onClose,
    tabIndex,
    setTabIndex,
  }
}

export const EditBackgroundDrawer = memo(function CardStyleDrawer({
  editor,
  isOpen,
  onClose,
  background,
  updateAttributes,
  tabIndex,
  setTabIndex,
  enableMask = false,
}: EditBackgroundDrawerProps) {
  const forwardUndo = useForwardUndo(editor)

  const handleReset = useCallback(() => {
    updateAttributes({
      background: { type: BackgroundType.NONE },
    })
  }, [updateAttributes])

  const drawerSize = useDrawerSize()
  const showMaskTab = enableMask && backgroundSupportsMask(background)

  return (
    <Drawer
      trapFocus={false}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size={drawerSize}
    >
      <DrawerOverlay background="none" />
      <DrawerContent
        onKeyDown={forwardUndo}
        data-in-editor-focus
        data-testid="card-style-drawer"
      >
        <DrawerCloseButton />
        <DrawerHeader>Background</DrawerHeader>
        <DrawerBody overflowX="hidden">
          <Tabs
            variant="soft-rounded"
            size="sm"
            isFitted
            isLazy
            index={tabIndex}
            onChange={setTabIndex}
          >
            {showMaskTab && (
              <TabList>
                <Tab>
                  <Box mr={2}>
                    <FontAwesomeIcon icon={regular('fill-drip')} />
                  </Box>
                  Background
                </Tab>
                <Tab>
                  <Box mr={2}>
                    <FontAwesomeIcon icon={regular('eye')} />
                  </Box>
                  Mask
                </Tab>
              </TabList>
            )}
            <TabPanels>
              <TabPanel p={0} pb={1} mt={showMaskTab ? 4 : 0}>
                <BackgroundPanel
                  editor={editor}
                  updateAttributes={updateAttributes}
                  background={background}
                  defaultMessage={
                    <Alert>
                      <AlertIcon />
                      The {DOC_DISPLAY_NAME}'s background will show up behind
                      this card. You can change the {DOC_DISPLAY_NAME}'s
                      background using the theme button in the toolbar, or add a
                      background specific to this card.
                    </Alert>
                  }
                  isDark={false}
                />
              </TabPanel>
              {showMaskTab && (
                <TabPanel p={0} pb={1} mt={4}>
                  <BackgroundMaskPanel
                    updateAttributes={updateAttributes}
                    background={background}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </DrawerBody>

        <DrawerFooter>
          <ButtonGroup w="100%">
            <GammaTooltip
              placement="top"
              label="Reset this card's style to the theme default"
            >
              <Button
                variant="ghost"
                colorScheme="gray"
                onClick={handleReset}
                data-testid="reset-card-style"
              >
                Reset
              </Button>
            </GammaTooltip>
            <Button flex="1" variant="solid" onClick={onClose}>
              Done
            </Button>
          </ButtonGroup>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
})
