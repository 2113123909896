import { CSSObject } from '@emotion/react'
import { User } from '@gammatech/schema'

import { Font, ThemeFont } from 'modules/api'
import { BackgroundOptions } from 'modules/tiptap_editor/styles/backgroundStyles'
import { ContainerOptions } from 'modules/tiptap_editor/styles/containerStyles'

import { ColorAttrs } from '../tiptap_editor/components/panels/ColorPanel'
import { MaybeHex } from './components/ColorPalettePicker'
import { LinearGradient } from './components/LinearGradientPicker'

export type ThemeType = 'custom' | 'standard'

export type Theme = {
  id: string
  workspaceId?: string
  name: string
  archived: boolean
  createdBy?: Partial<
    Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
  >
  createdTime?: string
  updatedTime?: string
  priority: number
  // Styles
  headingFont?: string
  headingFontWeight?: number
  bodyFont?: string
  bodyFontWeight?: number
  accentColor?: string
  logoUrl?: string
  // Advanced
  config: {
    // Todo: move to top level
    themeBase?: string
    // Deck backgrounds
    background?: BackgroundOptions // [existing] The deck background. Only visible in doc mode.

    // Colors, in addition to accent color
    headingColor?: string // [existing]
    bodyColor?: string // [existing]
    secondaryAccentColors?: MaybeHex[] // [new] Optional secondary colors in addition to the accent color. An ordered list.
    headingGradient?: LinearGradient // [new]
    accentGradient?: LinearGradient // [new]

    // Fonts
    fontSize?: number // [existing] A multiplier on the font size, defaults to 1

    // Card styles
    containerStyles?: CSSObject // [existing] CSS applied to the card container. Extends boxStyles, interactiveBoxStyles
    container?: ContainerOptions // [existing] isDark and width are still needed in new world. Maybe move these out to top level props?

    cardBackground?: BackgroundOptions // [new] The default background color for the card body.
    // accentBackgrounds?: BackgroundOptions[] // [new] A set of splashy backgrounds for card layouts. New cards will cycle through these, if provided
    contentStyles?: Record<string, any> // [existing] Was mainly used for heading gradients. Should not be needed anymore.

    // Deprecated
    colors?: ColorAttrs[] // [existing] Optional secondary colors in addition to the accent color. An ordered list.
  }
  fonts?: ThemeFont[]
  docCount?: number
}

export type ThemeBase = {
  key: string
  cardSx: ThemeCSSObject
  boxSx?: ThemeCSSObject //
  imageSx?: ThemeCSSObject // Images, media placeholder
  clickableSx?: ThemeCSSObject // Nested cards, embeds/videos, buttons
  smartLayoutContentSx?: CSSObject
  smartLayoutBoxSx?: CSSObject // Timeline/bullet markers, text boxes
  smartLayoutImageSx?: CSSObject // Styles applied to smart layout images
  smartLayoutLineSx?: CSSObject // Timeline lines
  smartLayoutCellSx?: CSSObject // The content of the cell, e.g. to restyle headings
  tableSx?: CSSObject // Styles applied to tables
  ornaments?: ThemeOrnament[]
  imageMasks?: any[]
  getCSSVars: (
    cardColor: string,
    accentColor: string
  ) => CSSObject & { '--line-thickness': string }
}

type ThemeCSSObject = Partial<
  Pick<
    CSSObject,
    | 'borderRadius'
    | 'border'
    | 'boxShadow'
    | 'backgroundColor'
    | '@media print'
    | 'backdropFilter' // should this be done via card background instead?
    | '_hover'
  >
>

export type ThemeOrnament = {
  key: string
  image: StaticImageData
}

export const emptyTheme: Theme = {
  id: 'new',
  name: '',
  archived: false,
  priority: 0,
  config: {},
  fonts: [],
}

export type FontMap = {
  [fontId: string]: Font
}

export type FontPickerFonts = {
  globalFonts: Font[]
  workspaceFonts: Font[]
}
