import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NodeViewProps } from '@tiptap/core'
import { memo, useCallback } from 'react'

import { useGetCard } from 'modules/cards'
import { useAppDispatch } from 'modules/redux'
import { useAnalytics } from 'modules/segment/SegmentContextProvider'
import { SegmentEvents } from 'modules/segment/segmentEvents'
import {
  ClickableButtonSx,
  clickableHoverSx,
  CLICKABLE_BOX_CLASS,
} from 'modules/theming/styles/box'
import { HEADING_CLASS } from 'modules/theming/styles/heading'
import { setFollowingAttached } from 'modules/tiptap_editor/reducer'

import { AnnotatableNodeViewWrapper } from '../../Annotatable'
import { ContainerDragHandle } from '../../DragDrop/ContainerDragHandle/ContainerDragHandle'
import { isExpandableSelectedNode } from '../../ExpandableNodes/ExpandableNodes'
import { getFontSizeStyles } from '../../Font/fontStyles'
import { setCardCollapsed } from '../CardCollapse'
import { CARD_WRAPPER_CLASS } from '../constants'
import { useCardState } from '../hooks/useCardState'
import { CardAttributes } from '../types'

const CollapsedCardTitleStyles = getFontSizeStyles('h4')

export const CollapsedCard = memo((nodeViewProps: NodeViewProps) => {
  const { decorations, editor, getPos } = nodeViewProps
  const analytics = useAnalytics()
  const dispatch = useAppDispatch()

  const cardState = useCardState(nodeViewProps)
  const { cardId, isPresentMode, isEditable } = cardState

  const cardData = useGetCard(cardId)
  const loading = cardData === undefined
  const title = cardData?.title
  const text = cardData?.text
  const { previewContent } = nodeViewProps.node.attrs as CardAttributes
  const hidePreview = previewContent === ''

  // Expandable selection
  const isExpandableSelected = isExpandableSelectedNode(decorations)

  const expandCard = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (isPresentMode && !isEditable) {
        dispatch(setFollowingAttached({ attached: false }))
        editor.commands.descendIntoCurrentCard(getPos(), 'push')
        analytics?.track(SegmentEvents.CARD_EXPANDED, {
          is_present_mode: true,
          method: 'click',
        })
      } else {
        setCardCollapsed(cardId, false)
        setTimeout(() => {
          if (!isEditable || editor.isDestroyed) return
          // Select just inside the opened card
          const pos = getPos()
          editor.commands.selectInsideNodeAtPos(pos)
        }, 50) // Wait until it's opened so contentEditable is true
        analytics?.track(SegmentEvents.CARD_EXPANDED, {
          is_present_mode: false,
          method: 'click',
        })
      }
    },
    [
      cardId,
      isPresentMode,
      isEditable,
      dispatch,
      editor.commands,
      editor.isDestroyed,
      getPos,
      analytics,
    ]
  )

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <ContainerDragHandle
        {...nodeViewProps}
        data-testid="card-drag-handle"
        handlePlacement="outside"
      />
      <Box
        mb="0.25em"
        position="relative"
        contentEditable={false}
        userSelect="none"
        role="group"
        data-card-id={cardId}
      >
        <ButtonGroup
          position="absolute"
          right={4}
          top={3}
          data-print-hidden
          variant="unstyled"
          colorScheme="gray"
        >
          {isEditable && (
            <CollapsedCardPreviewMenu
              hidePreview={hidePreview}
              {...nodeViewProps}
            />
          )}
          <CollapsedCardExpandButton onClick={expandCard} />
        </ButtonGroup>

        <Box
          cursor="pointer"
          className={cx(
            CLICKABLE_BOX_CLASS,
            CARD_WRAPPER_CLASS,
            'card-collapsed',
            isExpandableSelected && 'expandable-selected'
          )}
          _groupHover={clickableHoverSx} // Always use the hover transition, even when editable
          paddingX={['1em', '2em']}
          paddingY={['1em', '1.25em']}
          onClick={expandCard}
          data-collapsed-card
          data-selection-ring
          data-selection-background
          data-content-reference // Used for drag previews
        >
          <Text
            sx={CollapsedCardTitleStyles}
            noOfLines={1}
            mr={8} // Make room for card controls
            mb={2}
            className={cx(HEADING_CLASS)}
          >
            {title ? (
              title
            ) : loading ? (
              <>
                <Spinner size="sm" mr={2} /> Loading...
              </>
            ) : (
              <Text as="span" color="gray.400">
                Untitled card
              </Text>
            )}
          </Text>
          <HStack color="var(--body-color-muted)">
            <Text noOfLines={1} fontSize="0.9em">
              {hidePreview || !text?.trim() ? 'View more' : text}
            </Text>
            {hidePreview && (
              <FontAwesomeIcon icon={regular('chevron-down')} size="sm" />
            )}
          </HStack>
        </Box>
      </Box>
    </AnnotatableNodeViewWrapper>
  )
})

CollapsedCard.displayName = 'CollapsedCard'

const CollapsedCardPreviewMenu = ({ hidePreview, updateAttributes }) => {
  const togglePreview = useCallback(
    (ev) => {
      if (!updateAttributes) return
      // Set previewContent to empty string to hide it and show "view more" instead
      // Reset to null to show default
      updateAttributes({ previewContent: hidePreview ? null : '' })
      ev.stopPropagation()
    },
    [updateAttributes, hidePreview]
  )
  return (
    <Button
      opacity={0}
      transitionProperty="opacity"
      transitionDuration="normal"
      _groupHover={{
        opacity: 1,
      }}
      sx={ClickableButtonSx}
      fontSize="0.7em"
      onClick={togglePreview}
      className={CLICKABLE_BOX_CLASS}
      zIndex={1}
    >
      {hidePreview ? 'Show preview' : 'Hide preview'}
    </Button>
  )
}

const CollapsedCardExpandButton = ({ onClick }) => {
  return (
    <Button
      aria-label="Expand card"
      className={CLICKABLE_BOX_CLASS}
      onClick={onClick}
      zIndex={1}
      sx={ClickableButtonSx}
      fontSize="0.7em"
    >
      <FontAwesomeIcon icon={regular('expand-alt')} fontSize="0.9em" />
      <Text
        as="span"
        _groupHover={{ opacity: 1, display: 'inline' }}
        opacity="0"
        display="none"
        ml={2}
      >
        Expand
      </Text>
    </Button>
  )
}
