import { useAppSelector } from 'modules/redux'
import { Theme } from 'modules/theming'
import { selectZoomLevel } from 'modules/tiptap_editor/reducer'
import { isMobileDevice } from 'utils/deviceDetection'

import { NESTED_CARD_OUTDENT } from '../constants'

// Font size across modes
const CHAR_WIDTH = 0.5 // The average width of a character, relative to its height. Future: update based on font
const CARD_INNER_PADDING_X = 3.75 // In ems. Will scale with the font size
const CARD_MOBILE_INNER_PADDING_X = 1.5 // In ems. Will scale with the font size
const CARD_INNER_PADDING_Y = 2.75 // In ems. Will scale with the font size
const CARD_MOBILE_INNER_PADDING_Y = 1.5 // In ems. Will scale with the font size

type useCardSizeCSSVarsProps = {
  isPresentMode: boolean
  isNested: boolean
  nestedDepth: number
  isEditable: boolean
  isFullBleed: boolean
  theme: Theme
}

export const useCardSizeCSSVars = ({
  isPresentMode,
  isNested,
  nestedDepth,
  isEditable,
  isFullBleed,
  theme,
}: useCardSizeCSSVarsProps) => {
  const userZoomLevel = useAppSelector(selectZoomLevel)

  const cardInnerPaddingX = !isMobileDevice
    ? CARD_INNER_PADDING_X
    : CARD_MOBILE_INNER_PADDING_X
  const cardInnerPaddingY = !isMobileDevice
    ? CARD_INNER_PADDING_Y
    : CARD_MOBILE_INNER_PADDING_Y

  const maxCharsPerLine = isPresentMode ? 95 : 85
  const contentWidthEms = maxCharsPerLine * CHAR_WIDTH
  const nestedBulgeEms = isNested ? nestedDepth * NESTED_CARD_OUTDENT : 0
  const cardWidthEms =
    contentWidthEms + cardInnerPaddingX * 2 + nestedBulgeEms * 2

  // In present mode, font size should scale with the user's zoom level
  // and the viewport, keeping consistent characters per line. We clamp
  // to make sure it never gets too small to read, or too tall that it scrolls a lot.
  const themeFontSize = theme.config.fontSize ?? 1 // Todo: auto calculate this for custom themes somehow
  const deviceScaleFactor = isMobileDevice ? 1 : 1.125
  const fontSize = isPresentMode
    ? `calc(${
        userZoomLevel * themeFontSize
      } * clamp(1rem, 100vw / ${cardWidthEms}, 3vh))`
    : `${themeFontSize * deviceScaleFactor}rem`

  const maxContentWidth = `calc(${fontSize} * ${contentWidthEms})`

  const innerPaddingX = `calc(${fontSize} * ${cardInnerPaddingX})`
  const innerPaddingY = `calc(${fontSize} * ${cardInnerPaddingY})`

  // Full width blocks should always be at least 100% of the content width, and up to 2x it. In between, they should
  // have enough padding for the insert widget, TOC, and comment bubbles to be reachable
  const fullWidthPadding = `max(${
    isEditable ? 7 : 5 // Leave room for the insert widget and TOC if editing
  }em, ${cardInnerPaddingX}em)`

  return {
    '--card-width': `${cardWidthEms}em`,
    '--card-inner-padding': `${innerPaddingY} ${innerPaddingX}`,
    '--full-width': `clamp(100%, var(--editor-width) - ${fullWidthPadding} * 2, 200%)`,
    '--comment-padding': isFullBleed ? '3rem' : `${cardInnerPaddingX}em`,
    '--font-size': fontSize,
    '--max-content-width': maxContentWidth,
    '--nested-card-margin': `calc(-1 * var(--comment-padding) - ${nestedBulgeEms}em)`,
  }
}
