export interface ImageUploadResult {
  src: string
  meta: {
    width: number
    height: number
    average_color?: string
    has_transparency?: boolean
    aspect_ratio?: number
    frame_count?: number
  }
  name: string
  thumbnail?: string
  croppedFace?: string
}

export interface ImageUploadPlaceholder {
  // In the future, we could also return other metadata
  tempUrl: string
}

export enum UploadStatus {
  Done,
  Uploading,
  Error,
}
