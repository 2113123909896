import { Box, BoxProps } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { ReactNode } from 'react'

import { CARD_CONTENT_CLASS, EXPAND_CARD_TRANSITION_TIME } from '..'
import {
  findLayoutPreset,
  getCardLayoutChildrenSx,
} from '../CardLayout/cardLayoutUtils'
import { CardLayout } from '../types'
import { CARD2_LAYOUT_CLASS } from './constants'

type CardLayoutComponentProps = {
  layout: CardLayout
  isExpandableSelected: boolean
  isEditable: boolean
  isFirstCard: boolean
  isFocused: boolean
  isFullBleed: boolean
  isPresentMode: boolean
  hasCardLayoutItems: boolean
  children: ReactNode
} & BoxProps

export const Card2Layout = ({
  layout,
  children,
  isExpandableSelected,
  isFirstCard,
  isFocused,
  isFullBleed,
  isPresentMode,
  isEditable,
  hasCardLayoutItems,
}: CardLayoutComponentProps) => {
  // always show a blank layout in nested cards
  const layoutPreset = findLayoutPreset(layout)
  const layoutWidth = isPresentMode
    ? 'var(--editor-width)'
    : `var(--card-width)`
  const childItemsSx = getCardLayoutChildrenSx(layout)

  return (
    <Box
      className={cx(
        CARD2_LAYOUT_CLASS,
        isExpandableSelected && 'expandable-selected'
      )}
      w={layoutWidth}
      position="relative"
      spellCheck={isFocused}
      cursor={isEditable ? 'text' : undefined}
      color="var(--body-color)"
      {...(isPresentMode && {
        minH: '100%',
      })}
      role={layout == 'behind' ? 'group' : undefined} // Always show the accent image selector when hovering in behind layout
      sx={{
        // the direct descendent specificity is necessary so that grids and nested card sub grids
        // dont interact with each other
        [`> .${CARD_CONTENT_CLASS}`]: {
          '> [data-node-view-content-inner="card"]': {
            // if you are using CardView2 but the content was created without a card layout item
            ...(hasCardLayoutItems
              ? {
                  // base grid props for all layout presets
                  display: 'grid',
                  alignItems: 'stretch',
                  justifyContent: 'stretch',
                  minH: '100%',
                  transition: `min-height ${EXPAND_CARD_TRANSITION_TIME}ms`,
                  ...layoutPreset.grid,
                  ...childItemsSx,
                }
              : {
                  padding: 'var(--card-inner-padding)',
                  display: 'block',
                }),
            '> .node-cardAccentLayoutItem': {
              ...(isPresentMode &&
                layoutPreset.stickyAccent && {
                  '> [data-node-view-wrapper]': {
                    '> .card-layout-cell-bg': {
                      position: 'sticky',
                      height: '100vh',
                      top: 0,
                    },
                  },
                }),
            },
            '> .node-cardLayoutItem, > .node-cardAccentLayoutItem': {
              '> [data-node-view-wrapper]': {
                '> .card-layout-cell-bg': {
                  ...(isFullBleed &&
                    isFirstCard && {
                      paddingTop: `4.5rem`,
                    }),
                  '> [data-node-view-content]': {
                    '> [data-node-view-content-inner]': {
                      maxWidth: 'var(--max-content-width)',
                      margin: 'auto', // Center horizontally
                    },
                    padding: 'var(--card-inner-padding)',
                  },
                },
              },
            },
          },
        },
      }}
    >
      {children}
    </Box>
  )
}
