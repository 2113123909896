import { Editor } from '@tiptap/core'
import { Slice } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import {
  uploadFile,
  uploadFileFromUrl,
  UploadStatus,
  UppyUploadHandlers,
} from 'modules/media'
import { getFileExtension, isDocUpload } from 'utils/file'
import { dataURLtoFile, isImageMimeType, svgToFile } from 'utils/image'

import { insertAndUploadFiles } from './insertFiles'
import { looksLikeSvg } from './svg'
import { MediaUploadStorage } from './UploadExtension'
import {
  getUploadedImageAttrs,
  handleImageUploadFailed,
  handleImageUploadSuccess,
  isGammaCDNUrl,
  mergeImageAttrs,
} from './utils'

// Based on https://github.com/outline/rich-markdown-editor/blob/main/src/nodes/Image.tsx

export const uploadImageFileOrUrl = (
  editor: Editor,
  tempUrl: string,
  file?: File
) => {
  const onUploadComplete = (result, previousUrl?) => {
    handleImageUploadSuccess(editor, previousUrl || tempUrl, result)
  }
  const onUploadFailed = (error) => {
    handleImageUploadFailed(editor, tempUrl, error)
  }
  const uppyUploadHandlers: UppyUploadHandlers = {
    onOriginalFileUpload: onUploadComplete,
    onUploadComplete,
    onUploadFailed,
  }
  if (file) {
    uploadFile(file, editor.storage.mediaUpload.orgId, uppyUploadHandlers)
  } else {
    uploadFileFromUrl(
      tempUrl,
      editor.storage.mediaUpload.orgId,
      uppyUploadHandlers
    )
  }
}

const isUploadable = (file: File) =>
  isImageMimeType(file.type) || isDocUpload(file.type, getFileExtension(file))

export const generateUploadPlugin = (editor: Editor) => {
  return new Plugin({
    props: {
      transformPasted: (slice) => {
        slice.content.descendants((node) => {
          // Handle pasting an image that was in the process of uploading
          if (node.type.name === 'image' && node.attrs.tempUrl) {
            const uploadStorage: MediaUploadStorage = editor.storage.mediaUpload
            const result = uploadStorage.completedUploads[node.attrs.tempUrl]
            if (!result) return
            Object.assign(
              node.attrs,
              mergeImageAttrs(
                node,
                getUploadedImageAttrs({
                  isError: false,
                  result,
                })
              )
            )
          } else if (
            node.type.name === 'image' &&
            node.attrs.src &&
            !isGammaCDNUrl(node.attrs.src)
          ) {
            // If it's a remote URL, upload it
            let tempUrl: string = node.attrs.src
            let file: File | undefined
            const isDataUrl = tempUrl.startsWith('data:')
            if (isDataUrl) {
              file = dataURLtoFile(node.attrs.src)
              tempUrl = URL.createObjectURL(file)
            }

            Object.assign(node.attrs, {
              uploadStatus: UploadStatus.Uploading,
              tempUrl,
              src: undefined,
              source: 'image.custom',
            })
            uploadImageFileOrUrl(editor, tempUrl, file)
          }
        })
        return slice
      },

      handlePaste(view, event, slice) {
        return handleFilePaste(editor, view, event, slice)
      },

      handleDOMEvents: {
        drop(view, event: DragEvent): boolean {
          return handleFileDrop(editor, view, event)
        },
      },
    },
  })
}

export const getUploadsFromClipboardEvent = (
  event: ClipboardEvent,
  slice: Slice
): File[] | null => {
  if (!event.clipboardData) {
    return null
  }

  const html = event.clipboardData.getData('text/html')
  const text = event.clipboardData.getData('text/plain')

  if (text && !html && looksLikeSvg(text)) {
    return [svgToFile(text)]
  }

  const clipboardItems: DataTransferItem[] = Array.prototype.slice.call(
    event.clipboardData.items
  )

  // If the event has _both_ files and HTML content, prefer the HTML
  // This is needed e.g. when pasting from Excel - it has an HTML table of the data,
  // but also an image of the data. However, we don't want to do this if the HTML is
  // just an image tag, since the file upload is more likely to succeed than remotely
  // fetching the image URL (e.g. a private Slack link)
  if (
    clipboardItems.some((item) => item.type === 'text/html') &&
    (slice.content.childCount > 1 ||
      slice.content.firstChild?.type.name !== 'image')
  ) {
    return null
  }

  // Check if we actually pasted any files
  return clipboardItems
    .map((dt) => dt.getAsFile())
    .filter((file): file is File => !!file)
    .filter(isUploadable)
}

const handleFileDrop = (editor: Editor, view: EditorView, event: DragEvent) => {
  if (view.props.editable && !view.props.editable(view.state)) {
    return false
  }

  // filter to only include image files
  const uploads = getDataTransferFiles(event).filter(isUploadable)

  if (uploads.length === 0) {
    return false
  }

  // grab the position in the document for the cursor
  const result = view.posAtCoords({
    left: event.clientX,
    top: event.clientY,
  })

  if (!result) {
    return false
  }

  event.preventDefault()
  insertAndUploadFiles(editor, uploads, result.pos)
  return true
}

const handleFilePaste = (
  editor: Editor,
  view: EditorView,
  event: ClipboardEvent,
  slice: Slice
): boolean => {
  if (view.props.editable && !view.props.editable(view.state)) {
    return false
  }

  const files = getUploadsFromClipboardEvent(event, slice)
  if (!files || files.length === 0) {
    return false
  }

  const { selection } = view.state

  const insertPos = selection.from
  if (!selection.empty) {
    editor.commands.deleteSelection()
  }

  insertAndUploadFiles(editor, files, insertPos)

  return true
}

function getDataTransferFiles(event: DragEvent): File[] {
  // @ts-ignore
  let dataTransferItemsList: FileList | DataTransferItemList = []
  const eventTarget = event.target as HTMLInputElement

  if (event.dataTransfer) {
    const dt = event.dataTransfer
    if (dt.files && dt.files.length) {
      dataTransferItemsList = dt.files
    } else if (dt.items && dt.items.length) {
      // During the drag even the dataTransfer.files is null
      // but Chrome implements some drag store, which is accesible via dataTransfer.items
      dataTransferItemsList = dt.items
    }
  } else if (eventTarget && eventTarget.files) {
    dataTransferItemsList = eventTarget.files
  }
  // Convert from DataTransferItemsList to the native Array
  return Array.prototype.slice.call(dataTransferItemsList)
}
