import { Box, Button, Flex, HStack, Link } from '@chakra-ui/react'
import { useCallback } from 'react'

import { Doc, DocChannel } from 'modules/api'
import { CHANNELS_ICON } from 'modules/sharing/constants'
import { useUpdatePublicChannels } from 'modules/sharing/useUpdatePublicChannels'
import { generateChannelUrl } from 'utils/url'
import { SharePanelRowIcon } from './SharePanelRowIcon'

export const ChannelRow = ({
  channel,
  doc,
  isDisabled,
  showDisabledIcon = false,
}: {
  channel: DocChannel
  doc: Doc
  isDisabled: boolean
  showDisabledIcon: boolean
}) => {
  const { removeDocChannel } = useUpdatePublicChannels()
  const onRemoveClick = useCallback(() => {
    if (!doc) return
    removeDocChannel(doc, channel.id)
  }, [channel.id, doc, removeDocChannel])
  const channelUrl = generateChannelUrl({ slug: channel.slug, id: channel.id })

  return (
    <HStack
      py={2}
      key={channel.id}
      w="100%"
      justify="space-between"
      data-testid={`workspace-row-${channel.id}`}
    >
      <Box>
        <Flex alignItems="center">
          <SharePanelRowIcon
            icon={CHANNELS_ICON}
            showDisabled={showDisabledIcon}
          />
          <Box ml={3} opacity={showDisabledIcon ? 0.5 : 1}>
            <Link href={channelUrl} target="_blank">
              {channel.name}
            </Link>
            {/* TODO: Add channel membership info */}
            {/* https://linear.app/gamma-app/issue/G-1992/add-channel-membership-info */}
            {/* <Flex align="center">
              <Text fontSize="sm" color="gray.400">
                {memberCount} {memberCount === 1 ? 'member' : 'members'}
                {channel.isMember && ', including you.'}
              </Text>
            </Flex> */}
          </Box>
        </Flex>
      </Box>
      <Button
        size="sm"
        variant="ghost"
        colorScheme="red"
        onClick={onRemoveClick}
        disabled={isDisabled}
      >
        Remove
      </Button>
    </HStack>
  )
}
