import { Box, Image as ChakraImage, ImageProps } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/react'
import { motion, MotionProps } from 'framer-motion'
import { forwardRef } from 'react'

import { useAppStore } from 'modules/redux'
import { selectAnimationsEnabled } from 'modules/tiptap_editor/reducer'

import { mediaPlaceholderStyleProps } from '../Placeholder/MediaPlaceholderImage'
import { ImageNodeAttrs } from '../types'
import { isSVG } from '../utils'
import { getCustomClipData } from './Clippable'

const MotionBox = motion(Box)

type CroppedImageProps = ImageProps & {
  node: NodeViewProps['node']
  motionProps?: MotionProps
  isCroppingThisImage?: boolean
  isZoomed?: boolean
  objectFit?: 'contain' | 'cover'
  containerWidth?: number
  intrinsicAspectRatio?: number
}

export const CroppedImage = forwardRef<HTMLImageElement, CroppedImageProps>(
  (
    {
      node,
      isCroppingThisImage = false,
      motionProps = {},
      isZoomed = false,
      objectFit = 'contain',
      containerWidth,
      intrinsicAspectRatio,
      ...imageProps
    },
    ref
  ) => {
    const animationsEnabled = selectAnimationsEnabled(useAppStore().getState())

    const { src, tempUrl, resize, meta } = node.attrs as ImageNodeAttrs
    const {
      scaleCrop,
      scaleY,
      clipPathCSSString,
      translateX,
      translateY,
      aspectRatio,
      referenceXOffset,
      referenceYOffset,
    } =
      getCustomClipData(
        resize,
        // Calculate the width, height and aspect ratio if they aren't passed in as props, falling back to square if unknown
        containerWidth || meta?.width || 1,
        containerWidth && intrinsicAspectRatio
          ? containerWidth / intrinsicAspectRatio
          : meta?.height || 1,
        intrinsicAspectRatio
          ? intrinsicAspectRatio
          : meta
          ? meta.width / meta.height
          : 1
      ) || {}

    const applyAspectRatio = !isCroppingThisImage && Boolean(resize?.clipPath)
    const imgAspectRatio = `${applyAspectRatio ? aspectRatio : ''}`

    const imgClipPath = isCroppingThisImage ? '' : clipPathCSSString
    // This is the transform needed "zoom in" to the original image to achieve the cropping effect
    const imgClipTransform = isCroppingThisImage
      ? ''
      : `scale(${scaleCrop}) translate(-${translateX}%, -${translateY}%)`

    const isSvg = isSVG(src || tempUrl)

    return (
      <MotionBox
        // Sizes the cropped image, preventing it from going over neighboring nodes
        css={{
          aspectRatio: imgAspectRatio,
        }}
        className="image"
        data-content-reference={`${referenceXOffset},${referenceYOffset}`} // The value of this attribute (e.g. `data-content-reference="233,147"`) is the offset pair that the drag preview image should use to correctly show the drag preview image due to a chrome bug.
        // Fit inside a container, e.g. gallery or zoomable overlay
        maxH={'var(--media-maxH)'}
        maxW={'var(--media-maxW)'}
        overflow="hidden" // Necessary for vertical cropping to show properly
        w={isZoomed ? undefined : '100%'}
        h={objectFit == 'cover' ? '100%' : undefined}
        sx={{
          '@media print': {
            // Unset height for print stylesheets, otherwise cropped images disappear :()
            height: 'unset',
          },
        }}
        // Use a layout transition to zoom in and out
        {...(animationsEnabled ? motionProps : {})}
      >
        <ChakraImage
          alt=""
          // Fit the container, scaling up or down as needed.
          // contain = normal, cover = crop to fit in a gallery or carousel thumbnail
          objectFit={objectFit}
          maxH={`calc(var(--media-maxH) * ${scaleY || 1})`}
          minH={
            // Fill the vertical height of a gallery
            objectFit == 'cover'
              ? `calc(var(--media-maxH) / ${scaleCrop || 1})`
              : undefined
          }
          // Apply the crop and scale
          transformOrigin="left top"
          clipPath={imgClipPath}
          // Chakra's fallback logic works well for changing image URLs, but seems to mess up Framer's layout animation, so we only use it when you're not zoomed
          ignoreFallback={isZoomed}
          transform={imgClipTransform}
          src={src || tempUrl || ''}
          fallbackSrc={tempUrl || ''}
          // Pass through ref and props
          ref={ref}
          w={isSvg ? '100%' : undefined}
          {...(src || tempUrl ? {} : mediaPlaceholderStyleProps)}
          {...imageProps}
        />
      </MotionBox>
    )
  }
)
CroppedImage.displayName = 'CroppedImage'
