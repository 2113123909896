// Returns an array of elements matching the selector

import { Editor } from '@tiptap/core'
import {
  DOMParser as ProseMirrorDOMParser,
  Fragment,
  Schema,
  Slice,
} from 'prosemirror-model'

import {
  serializeFragment,
  transformOutsidePastedContent,
  transformPastedHTML,
} from '../Clipboard'

// https://stackoverflow.com/a/28211790
export const querySelectorArray = (
  dom: Document | DocumentFragment,
  selector: string,
  reverse: boolean = false
) => {
  const elts = [].slice.call(dom.querySelectorAll(selector), 0) as HTMLElement[]
  if (reverse) {
    elts.reverse()
  }
  return elts
}

const NonTextTags = ['img']
export const isElementEmpty = (node: Node) => {
  if (!node.hasChildNodes()) return true
  if (node.textContent?.trim() != '' || NonTextTags.includes(node.nodeName))
    return false
  return [...node.childNodes].every((child) => {
    return isElementEmpty(child)
  })
}

export const getSerializedNodeAtPos = (editor: Editor, pos: number) => {
  const node = editor.state.doc.nodeAt(pos)
  if (!node) return
  const fragment = Fragment.from(node)
  const serialized = serializeFragment(fragment, editor.schema)
  // Turn the fragment into HTML for clipboard
  const div = document.createElement('div')
  div.appendChild(serialized)
  const html = div.innerHTML
  return html
}

// Takes HTML from outside the editor, or copied from the editor
// and parses into a Slice for inserting back into the editor
export const parseHtmlToSlice = (
  html: string,
  schema: Schema,
  shouldSplitCards = false,
  preserveWhitespace = false
): Slice => {
  const transformedHtml = transformPastedHTML(html)
  const dom = new DOMParser().parseFromString(transformedHtml, 'text/html')
  const slice = ProseMirrorDOMParser.fromSchema(schema).parseSlice(dom, {
    preserveWhitespace,
  })
  const transformedSlice = transformOutsidePastedContent(
    slice,
    schema,
    shouldSplitCards
  )
  return transformedSlice
}
