import { JSONContent } from '@tiptap/core'
import { Schema, Slice } from 'prosemirror-model'

import { fetchTextCompletion } from 'modules/ai/utils'

import { parseMarkdownToSlice } from '../../Clipboard/markdown'
import { generateToggleId } from '../../Toggle/UniqueToggleId'

export const summarizeToToggles = async (
  text: string,
  schema: Schema
): Promise<JSONContent[]> => {
  const toggles: JSONContent[] = []
  const slice = await summarizeToCards(text, schema)
  slice.content.forEach((node) => {
    if (node.type.name !== 'card') throw new Error('Not a card')
    if (!node.firstChild) throw new Error('No card content')
    const toggle = {
      type: 'toggle',
      attrs: {
        id: generateToggleId(),
      },
      content: [
        {
          type: 'toggleSummary',
          attrs: {
            fontSize: 'h3',
          },
          content: node.firstChild.content.toJSON(),
        },
        ...node.cut(node.firstChild.nodeSize).content.toJSON(),
      ],
    }
    toggles.push(toggle)
  })
  return toggles
}

export const summarizeToCards = async (
  text: string,
  schema: Schema
): Promise<Slice> => {
  const prompt = addHeadingsPrompt(text)
  const result = await fetchTextCompletion(prompt, 'raw', {
    maxTokens: 2048,
    temperature: 0.9,
    model: 'text-davinci-002',
  })
  // Reuse our Clipboard extension logic for parsing markdown,
  // and splitting headings into cards
  const slice = parseMarkdownToSlice(result, schema, true)
  return slice
}

const addHeadingsPrompt = (
  input: string
) => `You are summarizing documents and writing brief headings for paragraphs. Take the input text and add at least one heading using markdown syntax.

Input:
The web is an incredible platform. Its mix of ubiquity across devices and operating systems, its user-centered security model, and the fact that neither its specification nor its implementation are controlled by a single company makes the web a unique platform to develop software on. Combined with its inherent linkability, it's possible to search it and share what you've found with anyone, anywhere. Whenever you go to a website, it's up-to-date, and your experience with that site can be as ephemeral or as permanent as you'd like. Web applications can reach anyone, anywhere, on any device with a single codebase.

Platform-specific applications are known for being incredibly rich and reliable. They're ever-present, on home screens, docks, and taskbars. They work regardless of network connection. They launch in their own standalone experience. They can read and write files from the local file system, access hardware connected via USB, serial port, or bluetooth, and even interact with data stored on your device, such as contacts and calendar events. In these applications, you can do things such as take pictures, see playing songs listed on the home screen, or control song playback while in another app. Platform-specific applications feel like part of the device they run on.
 

Output:
# Ubiquity and linkability make the web unique
The web is an incredible platform. Its mix of ubiquity across devices and operating systems, its user-centered security model, and the fact that neither its specification nor its implementation are controlled by a single company makes the web a unique platform to develop software on. Combined with its inherent linkability, it's possible to search it and share what you've found with anyone, anywhere. Whenever you go to a website, it's up-to-date, and your experience with that site can be as ephemeral or as permanent as you'd like. Web applications can reach anyone, anywhere, on any device with a single codebase.

# Platform-specific apps feel like native
Platform-specific applications are known for being incredibly rich and reliable. They're ever-present, on home screens, docks, and taskbars. They work regardless of network connection. They launch in their own standalone experience. They can read and write files from the local file system, access hardware connected via USB, serial port, or bluetooth, and even interact with data stored on your device, such as contacts and calendar events. In these applications, you can do things such as take pictures, see playing songs listed on the home screen, or control song playback while in another app. Platform-specific applications feel like part of the device they run on.

Input:
When shoppers in places like America take a woven reusable bag to the store, they aren’t just saving the planet. They are reviving a storied industry thousands of miles away in India.

Jute, a coarse fiber used to make fabrics like burlap, has been cultivated for centuries in the warm and humid climate of the Ganges Delta. Some of India’s jute factories have been in operation for more than a century, and today the country is the world’s largest producer.

But in recent decades, the industry has struggled as less expensive synthetic substitutes have flooded the market. Farmers turned to other crops, cheap labor moved elsewhere and mills deteriorated from lack of investment.

Now, though, what had been jute’s weakness is its potential strength. As much of the world seeks biodegradable alternatives to synthetic materials like plastics, Indian jute is making its way around the planet, from supermarkets in the United States to fashion houses in France to wine producers in Italy.
 
Output:
# Reusable bags are made of Jute
When shoppers in places like America take a woven reusable bag to the store, they aren’t just saving the planet. They are reviving a storied industry thousands of miles away in India.

Jute, a coarse fiber used to make fabrics like burlap, has been cultivated for centuries in the warm and humid climate of the Ganges Delta. Some of India’s jute factories have been in operation for more than a century, and today the country is the world’s largest producer.

# It's making a comeback across the world
In recent decades, the industry has struggled as less expensive synthetic substitutes have flooded the market. Farmers turned to other crops, cheap labor moved elsewhere and mills deteriorated from lack of investment.

Now, though, what had been jute’s weakness is its potential strength. As much of the world seeks biodegradable alternatives to synthetic materials like plastics, Indian jute is making its way around the planet, from supermarkets in the United States to fashion houses in France to wine producers in Italy.

Input:
${input}

Output:`

export const summarizeBulletsPrompt = (
  input: string
) => `Summarize this using a few short bullets for a powerpoint presentation. Each bullet should be less than 6 words long.
    Input:
    ${input}
    Output:`
