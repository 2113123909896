export const getViewportHeight = () =>
  window.innerHeight || document.documentElement.clientHeight

/**
 * Recursively sum the offsetTop via the element's offsetParent,
 * so long as we're contained inside the parentSelector provided.
 * This is necessary to compute the total offsetTop between the
 * element and the doc's root scroll container, where every position:relative
 * parent node is considered an offsetParent.
 */
export const getOffsetFromParent = (
  element: HTMLElement,
  parentSelector: string
) => {
  if (!document.body.contains(element)) {
    console.warn('[getOffsetFromParent] Element is not in the DOM!', element)
  }
  if (element.closest(parentSelector) && element.offsetParent) {
    return (
      getOffsetFromParent(element.offsetParent as HTMLElement, parentSelector) +
      element.offsetTop
    )
  }
  return element.offsetTop
}

const EditableElements = ['input', 'textarea']
export const isEditableElement = (el: HTMLElement) => {
  return (
    EditableElements.includes(el.tagName.toLowerCase()) || el.isContentEditable
  )
}

/**
 * Find the first element (starting with the one passed)
 * that has a non-zero height, traversing up each parent
 */
export const getFirstParentWithHeight = (
  el?: HTMLElement
): HTMLElement | undefined => {
  let currentEl = el
  while (
    currentEl &&
    currentEl.getBoundingClientRect().height === 0 &&
    currentEl.parentElement
  ) {
    currentEl = currentEl.parentElement
  }
  return currentEl
}

/**
 * Calculate the scrollTop and scrollLeft of a given element relative to the document, not viewport.
 * Useful for `getBoundingClientRect()` which returns the position the user sees the element
 * and therefore can return negative values for top/left.
 * From: https://thecompetentdev.com/weeklyjstips/tips/32_boundingclientrect/
 */
export const calculateScroll = (element: Element | null): [number, number] => {
  if (element) {
    const [scrollTop, scrollLeft] = calculateScroll(
      element.parentNode as Element | null
    )
    return [
      (element.scrollTop || 0) + scrollTop,
      (element.scrollLeft || 0) + scrollLeft,
    ]
  } else {
    return [0, 0]
  }
}

// Based on
// https://gist.github.com/jwilson8767/db379026efcbd932f64382db4b02853e#file-es6-element-ready-js
/**
 * Waits for an element satisfying selector to exist, then resolves promise with the element.
 * Useful for resolving race conditions.
 *
 * @param selector
 * @returns {Promise}
 */
export function elementReady(
  selector: string,
  timeout = 5000
): Promise<HTMLElement | null> {
  let observer: MutationObserver | undefined
  return Promise.race<Promise<HTMLElement | null>>([
    new Promise((_resolve, reject) => {
      setTimeout(() => {
        observer?.disconnect()
        reject()
      }, timeout)
    }),
    new Promise((resolve) => {
      // Handles if the element was already in the DOM
      const el = document.querySelector<HTMLElement>(selector)
      if (el) {
        resolve(el)
        return
      }
      // Handles if element gets added later
      observer = new MutationObserver(() => {
        // Query for element matching the specified selector
        const foundEl = document.querySelector<HTMLElement>(selector)
        if (foundEl) {
          // Once we have resolved we don't need the observer anymore.
          resolve(foundEl)
          observer?.disconnect()
        }
      })

      observer.observe(document.documentElement, {
        childList: true,
        subtree: true,
      })
    }),
  ])
}

export const __DEBUGGING_addDebuggingTripline = ({
  topOffset = 100,
  color = 'aqua',
  requiredCookie,
}: {
  topOffset?: number
  color?: string
  requiredCookie?: string
}) => {
  if (requiredCookie && !document.cookie.includes(requiredCookie)) {
    return () => {}
  }
  const div = document.createElement('div')
  div.classList.add('debuggingTripline')
  div.style.width = '100vw'
  div.style.position = 'absolute'
  div.style.top = `${topOffset}px`
  div.style.zIndex = '9999999'
  div.style.height = '1px !important'
  div.style.border = `1px solid ${color}`
  div.style.pointerEvents = 'none'
  document.body.prepend(div)
  return () => {
    document.body.removeChild(div)
  }
}

export const __DEBUGGING_addDebuggingOutline = ({
  element,
  color = '#ff49c9',
  duration = 1500,
  requiredCookie,
}: {
  element: HTMLElement
  color?: string
  duration?: number
  requiredCookie?: string
}) => {
  if (requiredCookie && !document.cookie.includes(requiredCookie)) {
    return
  }

  element.style.outline = `dotted 4px ${color}`
  setTimeout(() => {
    element.style.outline = ''
  }, duration)
}

export const isHTMLElement = (n: Node): n is HTMLElement =>
  n.nodeType !== Node.TEXT_NODE

// Detects cases where an element is display: none or display: contents
// https://stackoverflow.com/a/36267487
export const isElementDisplayed = (el: HTMLElement) => {
  return Boolean(el.offsetParent || el.offsetWidth || el.offsetHeight)
}

/**
 * Traverses upward from child to root node testing if there are any nodes
 * matching predicate in the traversal path
 * @param child T
 * @param root
 * @param predicate
 * @returns
 */
export const findInBetween = (
  child: HTMLElement,
  root: HTMLElement,
  predicate: (el: HTMLElement) => boolean
): HTMLElement | null => {
  let currentEl = child.parentElement
  while (currentEl && currentEl !== root && currentEl.parentElement) {
    if (predicate(currentEl)) {
      return currentEl
    }
    currentEl = currentEl.parentElement
  }
  return null
}
