import {
  Button,
  ButtonGroup,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  PopoverContent,
  StackDivider,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo, MutableRefObject, useEffect, useState } from 'react'

import { isMobileOrTabletDevice } from 'utils/deviceDetection'

import { featureFlags } from '../FeatureFlagProvider'
import { FeatureFlagListItem } from './FeatureFlagListItem'

const useFeatureFlagExplanation = () => {
  const [explain, setExplain] = useState(featureFlags.explain())

  useEffect(() => {
    if (!featureFlags.hasInitialized) {
      // Ensure to update upon initialization
      featureFlags.initializePromise.then(() => {
        setExplain(featureFlags.explain())
      })
    }
    return featureFlags.onChange(() => {
      setExplain(featureFlags.explain())
    })
  }, [])

  return explain
}

type FeatureFlagPanelProps = {
  initialFocusRef: MutableRefObject<HTMLInputElement | null>
}

export const FeatureFlagPanel: React.FC<FeatureFlagPanelProps> = memo(
  ({ initialFocusRef }) => {
    const explain = useFeatureFlagExplanation()
    const allFlags = featureFlags.all()
    const [search, setSearch] = useState('')

    // Used to debug our error boundary: when true, throws an error in the render path
    const [testRenderError, setTestRenderError] = useState(false)
    if (testRenderError) {
      throw new Error('[FeatureFlagPanel]: Render Error')
    }

    const filtered = explain.filter((a) => {
      if (search === '') {
        return true
      }
      return a.key.toLocaleLowerCase().indexOf(search.trim()) > -1
    })

    return (
      <PopoverContent
        bg="white"
        p={3}
        width="max(30vw, 300px)"
        flexDirection="column"
      >
        <VStack align="start" p={2}>
          <Heading size="xs">Feature Flags</Heading>
          <InputGroup size="sm">
            <Input
              ref={initialFocusRef}
              pr="1.5rem"
              type="text"
              placeholder="Filter"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <InputRightElement width="2rem">
              <IconButton
                variant="ghost"
                size="xs"
                onClick={() => setSearch('')}
                icon={<FontAwesomeIcon icon={regular('x')} />}
                aria-label="clear"
              />
            </InputRightElement>
          </InputGroup>
        </VStack>
        <VStack
          align="stretch"
          overflowY="auto"
          h={isMobileOrTabletDevice ? '55vh' : '75vh'}
          justify="space-between"
          spacing={4}
        >
          <VStack
            p={2}
            align="stretch"
            divider={<StackDivider borderColor="gray.200" />}
          >
            {filtered
              .filter((item) => item.type === 'boolean')
              .map((item) => {
                const currentValue = allFlags[item.key]
                return (
                  <FeatureFlagListItem
                    currentValue={currentValue}
                    source={item.source}
                    key={item.key}
                    flagKey={item.key}
                    overrideValue={item.overrideValue}
                  />
                )
              })}
          </VStack>
          <VStack align="start" mt={8}>
            <Heading size="xs">Debugging</Heading>
            <HStack mb={3}>
              <ButtonGroup variant="plain" size="xs" flexWrap="wrap">
                <Button
                  onClick={() => {
                    throw new Error('[FeatureFlagPanel] SyncError')
                  }}
                >
                  Throw global err
                </Button>
                <Button
                  onClick={() => {
                    setTestRenderError(true)
                  }}
                >
                  Throw render err
                </Button>
                <Button
                  onClick={() => {
                    new Promise((r) => setTimeout(r, 100)).then(() => {
                      throw new Error(
                        '[FeatureFlagPanel] UnhandledPromiseException'
                      )
                    })
                  }}
                >
                  Throw promise err
                </Button>
              </ButtonGroup>
            </HStack>
          </VStack>
        </VStack>
      </PopoverContent>
    )
  }
)
FeatureFlagPanel.displayName = 'FeatureFlagPanel'
