import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'

export const MANAGE_PERMISSION_LABEL = 'Full Access'

export const CHANNELS_ICON = regular('hashtag')
export const CHANNELS_ICON_ACTIVE = solid('hashtag')

export const PUBLIC_ACCESS_TOOLTIP_LABEL = `They don't need to have a Gamma account to view this ${DOC_DISPLAY_NAME} 👍`

export const ACCESS_LINK_DISPLAY_NAME = 'invite link'
