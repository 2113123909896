import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  ButtonGroup,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  StackProps,
  Tooltip,
} from '@chakra-ui/react'
import {
  RiCheckboxBlankFill,
  RiCheckboxBlankLine,
  RiLayoutLeftFill,
  RiLayoutRightFill,
  RiLayoutTopFill,
} from 'react-icons/ri'

import LayoutBehindIcon from '../CardLayout/icons/layout-behind.svg'
import LayoutBlankIcon from '../CardLayout/icons/layout-blank.svg'
import LayoutLeftIcon from '../CardLayout/icons/layout-left.svg'
import LayoutRightIcon from '../CardLayout/icons/layout-right.svg'
import LayoutTopIcon from '../CardLayout/icons/layout-top.svg'
import { CardLayout } from '../types'

type CardLayoutMenuDropdownProps = {
  setLayout: (layout: CardLayout) => void
  layout: CardLayout
} & StackProps

export const CardLayoutDropdownMenu: React.FC<CardLayoutMenuDropdownProps> = ({
  layout,
  setLayout,
  ...rest
}) => {
  let previewIcon
  if (layout === 'left') previewIcon = <RiLayoutLeftFill />
  if (layout === 'right') previewIcon = <RiLayoutRightFill />
  if (layout === 'top') previewIcon = <RiLayoutTopFill />
  if (layout === 'blank') previewIcon = <RiCheckboxBlankLine />
  if (layout === 'behind') previewIcon = <RiCheckboxBlankFill />

  return (
    <HStack spacing={1} {...rest} contentEditable={false}>
      <>
        <ButtonGroup spacing={1} size="md" alignItems="center">
          <Menu>
            <Tooltip label="Change layout">
              <MenuButton
                as={Flex}
                alignItems="center"
                height="25px"
                // Styles should match GlobalDragHandle styles in globals.scss
                fontWeight="900"
                backgroundColor="whiteAlpha.900"
                fontFamily="Inter, sans-serif"
                backdropFilter="blur(8px)"
                border="1px solid"
                borderColor="blackAlpha.100"
                color={'gray.500'}
                boxShadow="sm"
                fontSize="16px"
                cursor="pointer"
                px={0.5}
                _hover={{
                  color: 'gray.600',
                  backgroundColor: 'gray.100',
                }}
                // match container drag handle
                borderRadius="4px"
              >
                <HStack spacing={1}>
                  {previewIcon}
                  <ChevronDownIcon />
                </HStack>
              </MenuButton>
            </Tooltip>
            <MenuList minW="auto" fontSize="sm">
              <MenuOptionGroup value={layout} type="radio" fontSize="sm">
                <MenuItemOption
                  onClick={() => setLayout('blank')}
                  value={'blank'}
                >
                  <Image w="20" src={LayoutBlankIcon.src} />
                </MenuItemOption>
                <MenuItemOption onClick={() => setLayout('top')} value={'top'}>
                  <Image w="20" src={LayoutTopIcon.src} />
                </MenuItemOption>
                <MenuItemOption
                  onClick={() => setLayout('left')}
                  value={'left'}
                >
                  <Image w="20" src={LayoutLeftIcon.src} />
                </MenuItemOption>
                <MenuItemOption
                  onClick={() => setLayout('right')}
                  value={'right'}
                >
                  <Image w="20" src={LayoutRightIcon.src} />
                </MenuItemOption>
                <MenuItemOption
                  onClick={() => setLayout('behind')}
                  value={'behind'}
                >
                  <Image w="20" src={LayoutBehindIcon.src} />
                </MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </ButtonGroup>
      </>
    </HStack>
  )
}
