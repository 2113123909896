import {
  combineTransactionSteps,
  Editor,
  findChildrenInRange,
  isNodeSelection,
} from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { Plugin, PluginKey } from 'prosemirror-state'
import { AttrStep, dropPoint } from 'prosemirror-transform'
import { Decoration, DecorationSet } from 'prosemirror-view'

import getChangedRanges from 'modules/tiptap_editor/plugins/uniqueAttribute/helpers/getChangedRanges'
import {
  findNodeAndParents,
  findParentNodes,
} from 'modules/tiptap_editor/utils'

import { getDocFlags } from '../../DocFlags/docFlags'
import { CardLayout, CardSize } from '../types'
import { isCardLayoutItemNode, isCardNode } from '../utils'
import {
  ensureCardLayoutItems,
  findLayoutPreset,
  getDisplayLayout,
  isAccentCardLayoutItem,
} from './cardLayoutUtils'

const CardLayoutPluginKey = new PluginKey('cardLayoutPlugin')

type CardLayoutDecorationSpec = {
  isCardLayoutDecoration: true
  cardId: string
  layout: CardLayout
  cardSize: CardSize
  parentCardPos: number
}

type CardLayoutDecoration = Decoration & {
  spec: CardLayoutDecorationSpec
}

// Which node types need to be aware of the card depth and style

export const CardLayoutPlugin = (editor: Editor) =>
  new Plugin({
    key: CardLayoutPluginKey,
    /**
     * Only allow attribute changes
     */
    filterTransaction: (tr, state) => {
      if (!tr.docChanged) {
        return true
      }
      if (
        !(
          isNodeSelection(state.selection) &&
          isCardLayoutItemNode(state.selection.node)
        )
      ) {
        // we only care about cardLayoutItem changes
        return true
      }

      if (tr.steps.every((step) => step instanceof AttrStep)) {
        // attr changes are fine, and are common for cardLayoutItems
        return true
      }

      // if the changed ranges aren't actually the cardLayoutItem node then we dont care
      const changes = getChangedRanges(tr)
      const allNotOnCardLayoutItem = changes.every((val) => {
        const node = tr.before.nodeAt(val.oldStart)
        return !node || !isCardLayoutItemNode(node)
      })

      if (!allNotOnCardLayoutItem) {
        console.warn(
          '[CardLayoutPlugin] blocking transaction with CardLayoutItem selected'
        )
      }

      return allNotOnCardLayoutItem
    },

    appendTransaction: (transactions, oldState, newState) => {
      const docChanges =
        transactions.some((transaction) => transaction.docChanged) &&
        !oldState.doc.eq(newState.doc)

      if (!docChanges) {
        return
      }

      const docFlags = getDocFlags(editor.state.doc)
      if (!docFlags.cardLayoutsEnabled) {
        return
      }

      const tr = newState.tr
      // const { types, attributeName, initialValue, callback } = this.options
      const transform = combineTransactionSteps(
        oldState.doc,
        transactions as any
      )

      // get changed ranges based on the old state
      const changes = getChangedRanges(transform)
      changes.forEach((change) => {
        const newRange = {
          from: change.newStart,
          to: change.newEnd,
        }
        // find cards in changed range
        const newCards = findChildrenInRange(newState.doc, newRange, (node) => {
          return node.type.name === 'card'
        })

        // for each card ensure the layout items are present
        newCards.forEach(({ pos }) => {
          // findChildrenInRange doesn't gaurantee it finds a node where pos >= newRange.from
          // manually filter those out
          if (pos < newRange.from) {
            return
          }
          const newPos = tr.mapping.map(pos)
          const cardNode = tr.doc.nodeAt(newPos)
          if (!cardNode) {
            return
          }

          const preset = findLayoutPreset(cardNode.attrs.layout)
          ensureCardLayoutItems(tr, newPos, editor.schema, preset)
        })
      })
      return tr
    },

    props: {
      handleDrop(view, e, slice) {
        // we only care about preventing drops on cardLayoutItems with type of accent

        const eventPos = view.posAtCoords({
          left: e.clientX,
          top: e.clientY,
        })

        if (!eventPos) {
          return false
        }
        const $mouse = view.state.doc.resolve(eventPos.pos)
        if (!$mouse || !slice) {
          return false
        }

        // this logic is duplicated from the GlobalDragHandle to figure out where the drop would
        // be inserted
        let insertPos = slice
          ? dropPoint(view.state.doc, $mouse.pos, slice)
          : $mouse.pos
        if (insertPos == null) insertPos = $mouse.pos

        const $pos = view.state.doc.resolve(insertPos)
        const parentCardLayoutItem = findNodeAndParents(
          $pos,
          isAccentCardLayoutItem
        )
        if (parentCardLayoutItem.length > 0) {
          return true
        }

        return false
      },
      decorations: ({ doc }) => {
        const decorations: Decoration[] = []
        const decorate = (node: Node, pos: number) => {
          if (node.type.name === 'card') {
            const $card = doc.resolve(pos)
            const parentCards = findParentNodes($card, isCardNode).map(
              (c) => c.node
            )
            const layout = getDisplayLayout({
              isNested: parentCards.length > 0,
              layout: node.attrs.layout,
            })

            node.forEach((child, offset) => {
              const childPos = $card.pos + offset + 1
              if (!isCardLayoutItemNode(child)) {
                return
              }
              const spec: CardLayoutDecorationSpec = {
                cardId: node.attrs.id,
                isCardLayoutDecoration: true,
                layout,
                cardSize: node.attrs.cardSize,
                parentCardPos: $card.pos,
              }
              decorations.push(
                Decoration.node(
                  childPos,
                  childPos + child.nodeSize,
                  {
                    ['data-layout-item-id']: child.attrs.itemId,
                  },
                  spec
                )
              )
            })
          }
        }
        doc.descendants(decorate)
        return DecorationSet.create(doc, decorations)
      },
    },
  })

export const findCardLayoutDecorationSpec = (
  decorations: Decoration[]
): CardLayoutDecorationSpec | undefined => {
  const found = decorations.find(
    (d): d is CardLayoutDecoration => d.spec.isCardLayoutDecoration
  )
  return found?.spec
}
