import { Extension } from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { isNodeEmpty, isTreeEmpty } from '../utils'

// Based on https://discuss.prosemirror.net/t/how-to-input-like-placeholder-behavior/705/16
// and https://github.com/ueberdosis/tiptap/blob/main/packages/extension-placeholder/src/placeholder.ts

const shouldDecorateEmptyNode = (node: Node) =>
  node.isTextblock || // All text blocks should get this so they can show placeholders
  ['gallery', 'cardLayoutItem'].includes(node.type.name)

type EmptyNodesOptions = {
  emptyNodeClass: string
  showOnlyWhenEditable: boolean
  showOnlyCurrent: boolean
}

export const EmptyNodes = Extension.create<EmptyNodesOptions>({
  name: 'emptyNodes',

  addOptions() {
    return {
      emptyNodeClass: 'is-empty',
      showOnlyWhenEditable: true,
      showOnlyCurrent: false,
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations: ({ doc, selection }) => {
            const decorations: Decoration[] = []
            const active =
              this.editor.isEditable || !this.options.showOnlyWhenEditable
            const { anchor } = selection
            if (!active) return

            const decorate = (node: Node, pos: number) => {
              if (!shouldDecorateEmptyNode(node)) return
              const hasAnchor = anchor >= pos && anchor <= pos + node.nodeSize
              const isEmpty = isTreeEmpty(node)
              if ((hasAnchor || !this.options.showOnlyCurrent) && isEmpty) {
                decorations.push(
                  Decoration.node(
                    pos,
                    pos + node.nodeSize,
                    {
                      class: this.options.emptyNodeClass,
                    },
                    {
                      isEmpty: true,
                    }
                  )
                )
              }
            }

            doc.descendants(decorate)

            return DecorationSet.create(doc, decorations)
          },
        },
      }),
    ]
  },
})

export const isNodeViewEmpty = (decorations: Decoration[]) => {
  return decorations.some((decoration) => decoration.spec.isEmpty)
}
