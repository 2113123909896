import { Box, BoxProps, HStack } from '@chakra-ui/react'
import { useGammaTooltipHider } from '@gamma-app/ui'
import { Editor, isNodeSelection } from '@tiptap/core'
import { NodeSelection, Selection, TextSelection } from 'prosemirror-state'
import { Decoration } from 'prosemirror-view'

import { getStore } from 'modules/redux'
import { isAccentCardLayoutItem } from 'modules/tiptap_editor/extensions/Card/CardLayout/cardLayoutUtils'
import { getDocFlags } from 'modules/tiptap_editor/extensions/DocFlags/docFlags'
import {
  isLayoutCellNode,
  isLayoutNode,
} from 'modules/tiptap_editor/extensions/Layout/utils'
import { isGalleryNode } from 'modules/tiptap_editor/extensions/media/Gallery'
import {
  isSmartLayoutCellNode,
  isSmartLayoutNode,
} from 'modules/tiptap_editor/extensions/SmartLayout/utils'
import { CellSelection } from 'modules/tiptap_editor/extensions/tables/prosemirror-table'
import { selectCardCollapsed } from 'modules/tiptap_editor/reducer'
import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'

import { BubbleMenu } from '../../../extensions/BubbleMenu/BubbleMenu'
import { isMediaEmbedNode } from '../../../extensions/media/utils'
import { useEditorUpdateDuringSelection } from '../../../hooks'
import { ButtonFormattingMenu } from './ButtonFormattingMenu'
import { CalloutBoxFormattingMenu } from './CalloutBoxFormattingMenu'
import { CardFormattingMenu } from './CardFormattingMenu'
import { CardLayoutItemFormattingMenu } from './CardLayoutItemFormattingMenu'
import { CardTOCFormattingMenu } from './CardTOCFormattingMenu'
import { ContributorsFormattingMenu } from './ContributorsFormattingMenu'
import { DrawingFormattingMenu } from './DrawingFormattingMenu'
import { EmbedVideoFormattingMenu } from './EmbedVideoFormattingMenu'
import { GalleryFormattingMenu } from './GalleryFormattingMenu'
import { ImageFormattingMenu } from './ImageFormattingMenu'
import { LayoutCellFormattingMenu } from './LayoutCellFormattingMenu'
import { LayoutFormattingMenu } from './LayoutFormattingMenu'
import { LinkFormattingMenu } from './LinkFormattingMenu'
import { SmartLayoutCellFormattingMenu } from './SmartLayoutCellFormattingMenu'
import { SmartLayoutFormattingMenu } from './SmartLayoutFormattingMenu'
import { TableFormattingMenu } from './TableFormattingMenu'
import { TextFormattingMenu } from './TextFormattingMenu'
import { NodeFormattingMenuProps } from './types'

type FormattingMenuProps = {
  editor: Editor
  scrollingParentSelector?: string
}

type FormattingMenuType = {
  component: React.FC<
    | NodeFormattingMenuProps
    | {
        editor: Editor
        selection: Selection
        decorations?: Decoration[]
      }
  >
  predicate: (selection: Selection, editor: Editor) => boolean
  offsetPx?: number
}

const FORMATTING_MENUS: FormattingMenuType[] = [
  {
    component: TableFormattingMenu,
    predicate: (selection): selection is CellSelection =>
      selection instanceof CellSelection && selection.isColSelection(),
    offsetPx: 16,
  },
  {
    component: TableFormattingMenu,
    predicate: (selection): selection is CellSelection =>
      selection instanceof CellSelection && selection.isRowSelection(),
    offsetPx: 12,
  },
  {
    component: LinkFormattingMenu,
    predicate: (selection, editor): selection is TextSelection =>
      selection instanceof TextSelection && editor.isActive('link'),
  },
  {
    component: ButtonFormattingMenu,
    predicate: (_selection, editor) => editor.isActive('button'),
  },
  {
    component: LayoutFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection && isLayoutNode(selection.node),
  },
  {
    component: CardFormattingMenu,
    predicate: (selection): selection is NodeSelection => {
      // TODO(jordan) enable this later
      const docFlags = getDocFlags(selection.$from.doc) || {}
      const isExpandedCardNodeSel =
        selection instanceof NodeSelection &&
        isCardNode(selection.node) &&
        selectCardCollapsed(selection.node.attrs.id)(getStore().getState()) ===
          false

      return docFlags && docFlags.cardLayoutsEnabled && isExpandedCardNodeSel
    },
    offsetPx: 0,
  },
  {
    component: CardLayoutItemFormattingMenu,
    predicate: (selection) => {
      const res =
        selection instanceof NodeSelection &&
        isAccentCardLayoutItem(selection.node)
      return res
    },
  },
  {
    component: LayoutCellFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection && isLayoutCellNode(selection.node),
  },
  {
    component: SmartLayoutFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection && isSmartLayoutNode(selection.node),
  },
  {
    component: SmartLayoutCellFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection &&
      isSmartLayoutCellNode(selection.node),
  },
  {
    component: GalleryFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection && isGalleryNode(selection.node),
  },
  {
    component: CalloutBoxFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection &&
      selection.node.type.name === 'calloutBox',
  },
  {
    component: DrawingFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection &&
      selection.node.type.name === 'drawing',
  },
  {
    component: ImageFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection &&
      (selection.node.type.name === 'image' ||
        selection.node.type.name === 'mediaPlaceholder'),
  },
  {
    component: EmbedVideoFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection && isMediaEmbedNode(selection.node),
  },
  {
    component: CardTOCFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection &&
      selection.node.type.name === 'tableOfContents',
  },
  {
    component: ContributorsFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection &&
      selection.node.type.name === 'contributors',
  },
  {
    component: TextFormattingMenu,
    predicate: (selection): selection is TextSelection =>
      selection instanceof TextSelection,
  },
]

const menuStyles: BoxProps = {
  border: '1px solid',
  borderColor: 'gray.200',
  backdropFilter: 'blur(20px)',
  backgroundColor: '#F9FAFBEE',
  shadow: 'lg',
}

export const FormattingMenu = ({
  editor,
  scrollingParentSelector,
}: FormattingMenuProps) => {
  // Trigger a re-render whenever the selection changes or content is
  // updated while the selection is not empty, as our menus need to re-calc
  // This prevents the menus from re-rendering on every keystroke
  useEditorUpdateDuringSelection(editor)
  const { hideTooltips, GammaTooltipHiderContext } = useGammaTooltipHider()

  const selection = editor.state.selection
  const activeMenu = FORMATTING_MENUS.find((menu) =>
    menu.predicate(selection, editor)
  )
  let decorations: Decoration[] = []
  try {
    if (isNodeSelection(editor.state.selection)) {
      // this is a bit of a hack since docView doesn't expose the `descAt` method in the TS type, but it exists
      decorations =
        (editor as any).view.docView.descAt(editor.state.selection.from)
          ?.outerDeco || [] // outerDeco are the decorations AT that node
    }
  } catch (e) {
    console.error(
      `[FormattingMenu] unable to find decorations at ${editor.state.selection.from}`,
      e.message
    )
  }

  return (
    <GammaTooltipHiderContext>
      <BubbleMenu
        editor={editor}
        onHide={hideTooltips}
        tippyOptions={{
          maxWidth: 'none',
          // @ts-ignore - tippy.js only wants a number here, but the string works correctly
          zIndex: 'var(--chakra-zIndices-popover)',
          popperOptions: {
            modifiers: [
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['top', 'bottom'],
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  boundary: scrollingParentSelector
                    ? document.querySelector(scrollingParentSelector)
                    : undefined,
                },
              },
            ],
          },
        }}
      >
        {activeMenu && (
          <Box
            borderRadius="xl"
            {...menuStyles}
            w="auto"
            minW="0px"
            position="relative"
            bottom={activeMenu.offsetPx ? `${activeMenu.offsetPx}px` : '0'}
            data-in-editor-focus
            data-testid="formatting-menu"
          >
            <HStack spacing={3} px={2} height={10}>
              <activeMenu.component
                editor={editor}
                selection={selection}
                decorations={decorations}
              />
            </HStack>
          </Box>
        )}
      </BubbleMenu>
    </GammaTooltipHiderContext>
  )
}
