import { Avatar, Flex, Popover, PopoverTrigger } from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'
import React, { useEffect, useRef } from 'react'

import { config } from 'config'
import { useUserContext } from 'modules/user/context/UserContext'
import { useSSRMounted } from 'utils/hooks'

import { FeatureFlagPanel } from './FeatureFlagPanel'

export const FeatureFlagDevtoolBall: React.FC = () => {
  const initialFocusRef = useRef<HTMLInputElement | null>(null)
  const { isGammaOrgUser } = useUserContext()

  useEffect(() => {
    // Only allow gamma org users to use the gammaDebug console function
    if (isGammaOrgUser !== true) return

    window['gammaDebug'] = (val: boolean = true) => {
      // delete all other cookies
      document.cookie =
        'g' +
        'a' +
        'm' +
        'm' +
        'adebug=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/'
      document.cookie =
        'g' +
        'a' +
        'm' +
        'm' +
        'adebug=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/docs'
      const days = 90
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      // Security through obscurity. The enabled cookie value here is gammadebug=gtpwtw
      document.cookie = `gammadebug=${
        val ? 'gtp' + 'wtw' : 'false'
      }; expires=${date.toUTCString()}; path=/`

      if (val !== config.DEBUG_ENABLED) {
        window.location.reload()
      }
    }
  }, [isGammaOrgUser])

  if (!useSSRMounted()) return null // SSR Hydration
  if (!config.DEBUG_ENABLED) return null

  return (
    <Flex zIndex="overlay" position="fixed" bottom="16px" left="16px">
      <Popover placement="top" initialFocusRef={initialFocusRef}>
        {({ isOpen }) => (
          <>
            <GammaTooltip label={'Feature Flags'}>
              <PopoverTrigger>
                <Avatar
                  tabIndex={0}
                  _focus={{
                    shadow: 'outline',
                  }}
                  transitionProperty="common"
                  transitionDuration="normal"
                  width="40px"
                  height="40px"
                  src="https://res.cloudinary.com/bizzaboprod/image/upload/q_auto:best,c_crop,g_custom/v1560896151/yecilv8jlrtehq3zmea2.png"
                  shadow="base"
                  outline="none"
                  alignItems="center"
                  flexDirection="column"
                  cursor="pointer"
                  opacity={0}
                  _hover={{
                    shadow: '0 0 0 3px #CBD5E0',
                    opacity: 1,
                  }}
                  {...(isOpen && {
                    shadow: '0 0 0 3px #CBD5E0',
                    opacity: 1,
                  })}
                />
              </PopoverTrigger>
            </GammaTooltip>
            <FeatureFlagPanel initialFocusRef={initialFocusRef} />
          </>
        )}
      </Popover>
    </Flex>
  )
}
