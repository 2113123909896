import { Box, HStack, Flex, Text, VStack } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'

import { Doc } from 'modules/api'
import { useHandlePDFExport } from 'modules/sharing/pdfHooks'

export const Export = ({ doc, editor }: { doc: Doc; editor: Editor }) => {
  const { handlePDFExport, exportInProgress } = useHandlePDFExport(
    doc,
    'share_panel',
    editor
  )

  return (
    <>
      <Text fontSize="sm" color="gray.600" pt={2}>
        Download a static copy of your {DOC_DISPLAY_NAME} to share with others.
      </Text>
      <Box
        alignContent="left"
        width="100%"
        onClick={handlePDFExport}
        borderRadius="md"
        mt={4}
        border="1px"
        borderColor="gray.200"
        bgColor={exportInProgress ? 'gray.50' : 'white'}
        cursor={exportInProgress ? 'progress' : undefined}
        disabled={exportInProgress}
        as="button"
        transitionProperty="common"
        transitionDuration="normal"
        _hover={
          exportInProgress
            ? {}
            : {
                boxShadow: 'var(--chakra-shadows-md)',
                borderColor: 'var(--chakra-colors-trueblue-300)',
                color: 'var(--chakra-colors-trueblue-400)',
              }
        }
      >
        <HStack p={4} paddingRight={6} justifyContent="space-between">
          <HStack spacing={3}>
            <Flex
              bgColor={exportInProgress ? 'gray.100' : 'gray.50'}
              color="red.500"
              height={12}
              width={12}
              alignItems="center"
              justifyContent="center"
              borderRadius="md"
              fontSize="2xl"
            >
              <FontAwesomeIcon icon={regular('file-pdf')} fixedWidth />
            </Flex>
            <VStack alignItems="flex-start" spacing={0}>
              <Text fontWeight={'medium'}>Export to PDF</Text>
              {exportInProgress && (
                <Text color="gray.600" fontSize="xs">
                  Your PDF is being generated. It's safe to close the share
                  panel.
                </Text>
              )}
            </VStack>
          </HStack>
          {!exportInProgress && (
            <FontAwesomeIcon
              icon={regular('arrow-down-to-bracket')}
              size="1x"
            />
          )}
        </HStack>
      </Box>
    </>
  )
}
