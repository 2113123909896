import { Theme, ThemeBase } from '../types'
import { DefaultThemeBase } from './default'
import { GlassThemeBase } from './glass'
import { LowKeyThemeBase } from './lowKey'
import { MidnightThemeBase } from './midnight'
import { NeomorphicThemeBase } from './neomorphic'
import { WavyThemeBase } from './wavy'

export const THEME_BASES: ThemeBase[] = [
  DefaultThemeBase,
  GlassThemeBase,
  NeomorphicThemeBase,
  MidnightThemeBase,
  WavyThemeBase,
  LowKeyThemeBase,
]

const ThemeBasesMap: Record<string, ThemeBase> = THEME_BASES.reduce(
  (acc, themeBase) => {
    acc[themeBase.key] = themeBase
    return acc
  },
  {}
)

export const getThemeBase = (theme: Theme) => {
  const themeBaseKey = theme.config.themeBase
  if (themeBaseKey && themeBaseKey in ThemeBasesMap) {
    return ThemeBasesMap[themeBaseKey]
  } else {
    return DefaultThemeBase
  }
}
