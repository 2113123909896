import { NodeViewProps } from '@tiptap/react'

import { SlashMenuPrompt } from 'modules/tiptap_editor/components/menus/SlashMenu'

import { NodeViewContent } from '../../react'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { isNodeViewEmpty } from '../EmptyNodes'
import { isFocusedInside } from '../selection/utils'
export const ParagraphView = (nodeViewProps: NodeViewProps) => {
  const { node, editor, decorations } = nodeViewProps
  const isEmpty = isNodeViewEmpty(decorations)
  const isFocused = isFocusedInside(decorations) // This also ensures it's editable
  const { horizontalAlign, fontSize } = node.attrs
  return (
    <AnnotatableNodeViewWrapper
      {...nodeViewProps}
      style={{ position: 'relative' }}
    >
      <NodeViewContent
        as="p"
        data-font-size={fontSize}
        style={{ textAlign: horizontalAlign }}
      ></NodeViewContent>
      {isEmpty && isFocused && <SlashMenuPrompt {...nodeViewProps} />}
    </AnnotatableNodeViewWrapper>
  )
}
