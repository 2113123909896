import { Box, Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { getThemeBase } from 'modules/theming/themeBases'

import { BulletMarker } from '../components/BulletMarker'
import { CELL_PADDING, EmptyCellContent } from '../constants'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

export const BulletsWrapper: SmartLayoutWrapperComponent = ({ children }) => {
  return (
    <Flex gap="1em" direction="row" wrap="wrap" data-selection-ring>
      {children}
    </Flex>
  )
}

export const BulletsCell: SmartLayoutCellComponent = (props) => {
  const { children, theme } = props
  const base = getThemeBase(theme)

  return (
    <Flex
      direction="row"
      align="flex-start"
      gap={CELL_PADDING}
      flex="1 1 auto"
      // Target 2 columns. The flex will make this always fill the width
      w={'45%'}
      data-selection-ring
      data-content-reference
    >
      <BulletMarker {...props} alignText />
      <Box flex="1" sx={base.smartLayoutContentSx}>
        {children}
      </Box>
    </Flex>
  )
}

export const Bullets: SmartLayoutVariant = {
  key: 'bullets',
  name: 'Bullets',
  icon: regular('square-list'),
  options: [],
  Wrapper: BulletsWrapper,
  Cell: BulletsCell,
  defaultContent: EmptyCellContent,
}
