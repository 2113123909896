import { Box, Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { getThemeBase } from 'modules/theming/themeBases'

import { CELL_PADDING, EmptyCellContent } from '../constants'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

export const TextBoxesWrapper: SmartLayoutWrapperComponent = ({ children }) => {
  return (
    <Flex gap="1em" wrap="wrap" data-selection-ring>
      {children}
    </Flex>
  )
}

export const TextBoxCell: SmartLayoutCellComponent = ({
  theme,
  children,
  numCells,
}) => {
  const base = getThemeBase(theme)
  return (
    <Box
      flex="1 1 auto"
      // Target 2 columns with >3 cells, but one row if less. The flex will make this always fill the width
      w={numCells > 3 ? '45%' : '30%'}
      px={CELL_PADDING}
      data-selection-ring
      data-content-reference
      sx={base.smartLayoutBoxSx || base.boxSx}
    >
      {children}
    </Box>
  )
}

export const TextBoxes: SmartLayoutVariant = {
  key: 'textBoxes',
  name: 'Text boxes',
  icon: regular('input-text'),
  options: [],
  Wrapper: TextBoxesWrapper,
  Cell: TextBoxCell,
  defaultContent: EmptyCellContent,
  addDirection() {
    return 'right'
  },
}
