import { Image, Kbd, Stack, Text } from '@chakra-ui/react'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { StepType } from '@reactour/tour'

import { baseStyles } from 'modules/guiders/components/baseStyles'
import SlashDemo from 'publicImages/guiders/slashmenudemo.gif'
import { elementReady } from 'utils/dom'

import { StepContent } from '../../components/StepContent'

const PRESENT_BUTTON_GROUP_SELECTOR =
  '[data-guider-highlight="present-button-group"]'
const MODAL_CLOSE_BUTTON_SELECTOR = '.chakra-modal__close-btn'

const exitPresentMode = () => {
  const presentButtonGroup = document.querySelector(
    PRESENT_BUTTON_GROUP_SELECTOR
  ) as HTMLButtonElement
  if (presentButtonGroup?.dataset?.editorMode === 'slide') {
    const presentButton = presentButtonGroup.querySelector(
      '[data-present-button]'
    ) as HTMLButtonElement
    presentButton.click()
  }
}

const disablePresentButton = () => {
  const presentButtonGroup = document.querySelector(
    PRESENT_BUTTON_GROUP_SELECTOR
  ) as HTMLButtonElement
  if (presentButtonGroup) {
    presentButtonGroup.style.pointerEvents = 'none'
  }
}

export const enablePresentButton = () => {
  const presentButtonGroup = document.querySelector(
    PRESENT_BUTTON_GROUP_SELECTOR
  ) as HTMLButtonElement
  if (presentButtonGroup) {
    presentButtonGroup.style.pointerEvents = 'initial'
  }
}

const closeAnyModals = () => {
  let closeButtons = document.querySelectorAll(MODAL_CLOSE_BUTTON_SELECTOR)
  if (closeButtons.length) {
    closeButtons.forEach((n) => (n as HTMLButtonElement).click())
  }
  // Super edge case-y, but if someone is in the doc theme drawer, and then they open the
  // create theme modal, then we need to actually call this twice D:
  setTimeout(() => {
    closeButtons = document.querySelectorAll(MODAL_CLOSE_BUTTON_SELECTOR)
    if (closeButtons.length) {
      closeButtons.forEach((n) => (n as HTMLButtonElement).click())
    }
  }, 1000)
}

export const DOC_EDITOR_TOUR_STEPS: StepType[] = (
  [
    // Welcome
    {
      position: 'center',
      selector: '#editor-core-root',
      content: function StepContentWrapper() {
        return (
          <StepContent
            title={`👋 Welcome to the editor!`}
            body="This is a quick tour to show you how to use Gamma."
          />
        )
      },
      styles: {
        ...baseStyles,
        ...{
          maskArea: (base) => {
            const maskAreaBaseStyles =
              baseStyles && baseStyles.maskArea && baseStyles.maskArea(base)
            return {
              ...maskAreaBaseStyles,
              width: 0,
              height: 0,
            }
          },
        },
      },
      action: () => {
        elementReady(PRESENT_BUTTON_GROUP_SELECTOR).then(
          (presentButtonGroup) => {
            if (presentButtonGroup) {
              presentButtonGroup.style.pointerEvents = 'none'
            }
          }
        )
      },
    },

    // Insert widget
    {
      selector: '[data-guider-highlight="insert-widget"]',
      position: 'bottom',
      content: (
        <StepContent
          title="Insert widget"
          body={`Use this to insert content into your ${DOC_DISPLAY_NAME}. You can add text, images, layouts, videos, and more.`}
        />
      ),
    },

    // Slash command
    {
      selector: '[data-guider-highlight="card-body"]',
      content: (
        <StepContent
          title="Try the Slash command"
          body={
            <Stack spacing={4}>
              <Text size="sm">
                If you're used to using tools like Notion, you can use the{' '}
                <Kbd>/</Kbd> command to quickly insert content."
              </Text>
              <Image src={SlashDemo.src} />
            </Stack>
          }
        />
      ),
      highlightedSelectors: [
        '[data-guider-highlight="card-body"]',
        '[data-slash-menu-dropdown]',
      ],
      mutationObservables: [
        '[data-guider-highlight="card-body"]',
        '[data-slash-menu-dropdown]',
      ],
      resizeObservables: [
        '[data-guider-highlight="card-body"]',
        '[data-slash-menu-dropdown]',
      ],
    },

    // Add card
    {
      selector:
        '[data-is-nested-card="false"] [data-guider-highlight="add-card-button"]',
      mutationObservables: [
        '[data-is-nested-card="false"] [data-guider-highlight="add-card-button"]',
      ],
      resizeObservables: [
        '[data-card-body]',
        '[data-is-nested-card="false"] [data-guider-highlight="add-card-button"]',
      ],
      position: 'top',
      content: (
        <StepContent
          title="Add cards"
          body={`Add cards to your ${DOC_DISPLAY_NAME} with this button. You can also choose from our card templates to mix things up.`}
        />
      ),
      action: (elem) => {
        if (elem?.parentElement) {
          elem.parentElement.style.opacity = '1'
        }
      },
    },

    // Table of Contents
    {
      selector: '[data-guider-highlight="table-of-contents-opener"]',
      content: (
        <StepContent
          title="Table of contents"
          body={`Get a bird's eye view of your ${DOC_DISPLAY_NAME}. Jump to any section with a click.`}
        />
      ),
      highlightedSelectors: [
        '[data-guider-highlight="table-of-contents-opener"]',
        // '[data-table-of-contents]',
      ],
      // mutationObservables: ['[data-table-of-contents]'],
      // resizeObservables: ['[data-table-of-contents]'],
    },

    // Theme
    {
      selector: '[data-guider-highlight="toolbar-theme-button"]',
      content: (
        <StepContent
          title={`Customize your ${DOC_DISPLAY_NAME} with themes`}
          body=" Pick from any of our default themes, or make your own."
        />
      ),
    },

    // Share
    {
      selector: '[data-guider-highlight="share-button"]',
      content: (
        <StepContent
          title="Share your work"
          body={`Open the share panel to share your ${DOC_DISPLAY_NAME} with others.`}
        />
      ),
    },

    // Present
    {
      selector: PRESENT_BUTTON_GROUP_SELECTOR,
      content: (
        <StepContent
          title={`Present your ${DOC_DISPLAY_NAME}`}
          body={
            <Text size="sm">
              Click this button to enter present mode. Use the arrow keys{' '}
              <Kbd size="md">&uarr;</Kbd> <Kbd size="md">&darr;</Kbd>{' '}
              <Kbd size="md">&larr;</Kbd> <Kbd size="md">&rarr;</Kbd> to move.
              Press <Kbd size="md">esc</Kbd> to get out of present mode.
            </Text>
          }
        />
      ),
      action: (elem) => {
        enablePresentButton()
      },
    },

    // End
    {
      selector: '[data-guider-highlight="default-help-widget-activator"]',
      content: function StepContentWrapper() {
        return (
          <StepContent
            body={
              <Text size="sm">
                If you want to go through this tour again, just click{' '}
                <strong>Take the editor tour</strong> in the{' '}
                <strong>Help</strong> menu.
              </Text>
            }
          />
        )
      },
      styles: {
        ...baseStyles,
        ...{
          close: (base) => ({
            ...base,
            display: 'none',
          }),
        },
      },
    },
  ] as StepType[]
).map((step) => ({
  ...step,
  action: (elem) => {
    // By default, disable present mode buttons because the keyboard listeners are AGGRO AF. We'll enable it when we need it.
    disablePresentButton()
    if (step.action) {
      step.action(elem)
    }
  },
  actionAfter: (elem) => {
    // Always exit present mode in case we're in it, before proceeding to the next step.
    exitPresentMode()
    closeAnyModals()
    if (step.actionAfter) {
      step.actionAfter(elem)
    }
  },
}))
