import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react'

import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'

const CellSizeOptionControl = ({
  value,
  updateValue,
}: SmartLayoutOptionControlProps<number>) => {
  return (
    <Slider
      min={5}
      max={20}
      step={0.25}
      value={value}
      onChange={updateValue}
      w="60px"
      focusThumbOnChange={false}
      colorScheme="trueblue"
      size="sm"
    >
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb background="trueblue.500" />
    </Slider>
  )
}

export const CellSizeOption: SmartLayoutOption<number> = {
  key: 'cellSize',
  defaultValue: 8.75,
  Control: CellSizeOptionControl,
}
