import { InMemoryCache } from '@apollo/client'
import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist'
import localforage from 'localforage'

import { isStorageSupported } from 'utils/storage/utils'

export const setupPersistCache = async (cache: InMemoryCache) => {
  if (typeof window === 'undefined') return

  // if local storage not available, then return
  if (!isStorageSupported) {
    return Promise.resolve()
  }
  return persistCache({
    cache,
    storage: new LocalForageWrapper(localforage),
  })
}
