import memoize from 'fast-memoize'

import {
  getContainerBackgroundColor,
  getContainerOptions,
} from 'modules/tiptap_editor/styles/containerStyles'
import {
  colorWithLightness,
  colorWithOpacity,
  isColorDark,
  lightenColor,
  makeColorReadable,
} from 'utils/color'

import {
  generateLinearGradientCSS,
  lightenedGradient,
  readableGradient,
} from '../components/LinearGradientPicker'
import {
  DEFAULT_BODY_COLOR,
  DEFAULT_BODY_COLOR_DARK,
  DEFAULT_FONTS,
  DEFAULT_HEADING_COLOR,
  DEFAULT_HEADING_COLOR_DARK,
} from '../constants'
import { getThemeBase } from '../themeBases'
import { Theme } from '../types'
import { getFontName, getFontWeight } from '../utils/fonts'
import {
  getAccentColor,
  getAccentColorPalette,
  isThemeDark,
} from '../utils/utils'

const DEFAULT_CONTRAST_RATIO = 3 // A lower ratio to try to honor the theme maker's intent
const DEFAULT_CONTRAST_RATIO_INVERTED = 5 // If you change the color, use a stricter constrast ratio to make sure it's readable

// In Card1, you can override a card to be light/dark
// In Card2, you can override it to a custom color
const generateThemeCSSVars = (
  theme: Theme,
  isDarkOverride?: boolean,
  cardColorOverride?: string
) => {
  const themeContainer = getContainerOptions(theme)
  const { bodyFont, headingFont, fonts } = theme
  // Retrieve font names
  const bodyFontName = getFontName(fonts, bodyFont) || DEFAULT_FONTS.bodyFont
  const headingFontName =
    getFontName(fonts, headingFont) || DEFAULT_FONTS.headingFont
  const accentColor = getAccentColor(theme)
  const isDark =
    isDarkOverride ??
    (cardColorOverride ? isColorDark(cardColorOverride) : isThemeDark(theme))

  const {
    bodyColor = isDark ? DEFAULT_BODY_COLOR_DARK : DEFAULT_BODY_COLOR,
    headingColor = isDark ? DEFAULT_HEADING_COLOR_DARK : DEFAULT_HEADING_COLOR,
    fontSize = DEFAULT_FONTS.fontSize,
    headingGradient,
    accentGradient,
  } = theme.config

  const cardColor =
    cardColorOverride ??
    getContainerBackgroundColor(theme, {
      ...themeContainer,
      isDark,
    })

  const themeBase = getThemeBase(theme)
  const baseCSSVars = themeBase.getCSSVars?.(cardColor, accentColor) || {}

  const isColorOverride =
    isDarkOverride !== undefined || cardColorOverride !== undefined

  const contrastColor = cardColor
  const linkColor = makeColorReadable(
    accentColor,
    contrastColor,
    isColorOverride ? DEFAULT_CONTRAST_RATIO_INVERTED : DEFAULT_CONTRAST_RATIO
  )
  const bodyColorReadable = makeColorReadable(
    bodyColor,
    contrastColor,
    isColorOverride ? DEFAULT_CONTRAST_RATIO_INVERTED : DEFAULT_CONTRAST_RATIO
  )
  const headingColorReadable = makeColorReadable(
    headingColor,
    contrastColor,
    isColorOverride ? DEFAULT_CONTRAST_RATIO_INVERTED : DEFAULT_CONTRAST_RATIO
  )
  const accentPalette = getAccentColorPalette(theme)

  return {
    // Fonts
    '--body-font': `"${bodyFontName}"`,
    '--heading-font': `"${headingFontName}"`,
    '--font-size': `${fontSize}em`, // Only used in Card1. Card2 overwrites this in useCardSizeCSSVars
    '--heading-font-weight': getFontWeight(theme, 'heading'),
    '--body-font-weight': getFontWeight(theme, 'body'),

    // Colors
    '--accent-color': accentColor,
    '--accent-color-2': accentPalette[1 % accentPalette.length],
    '--accent-color-3': accentPalette[2 % accentPalette.length],
    '--accent-color-4': accentPalette[3 % accentPalette.length],
    '--accent-color-5': accentPalette[4 % accentPalette.length],

    '--accent-gradient-background': generateLinearGradientCSS(
      lightenedGradient(accentGradient, isDark ? 0.25 : 0.9)
    ),
    '--accent-color-background': isDark
      ? colorWithLightness(accentColor, 0.25)
      : colorWithLightness(accentColor, 0.9),
    '--accent-color-border': isDark
      ? colorWithLightness(accentColor, 0.3)
      : colorWithLightness(accentColor, 0.8),

    '--accent-color-background-muted': isDark
      ? colorWithLightness(accentColor, 0.15)
      : colorWithLightness(accentColor, 0.95),
    '--accent-color-100': colorWithLightness(accentColor, 0.9),
    '--accent-color-500': colorWithLightness(accentColor, 0.5),
    '--accent-gradient': generateLinearGradientCSS(
      readableGradient(accentGradient, contrastColor, 0.5)
    ),
    '--expandable-hover-background': colorWithOpacity(linkColor, 0.1),

    '--link-color': linkColor,
    '--link-color-hover': lightenColor(linkColor, -10),
    '--body-color': bodyColorReadable,
    '--body-color-muted': colorWithOpacity(bodyColorReadable, 0.5),
    '--body-color-inverted': isDark ? '#000' : '#fff',
    '--heading-color': headingColorReadable,
    '--heading-gradient': generateLinearGradientCSS(
      readableGradient(
        headingGradient,
        contrastColor,
        isColorOverride ? DEFAULT_CONTRAST_RATIO_INVERTED : 2
      )
    ),
    // The background color of the nearest containing block. Blocks within the card like columns might override this.
    '--card-color': cardColor,
    '--card-color-mask': colorWithOpacity(cardColor, 0.75),
    '--link-box-shadow': `0 0 0px 3px ${colorWithOpacity(linkColor, 1)}`,
    ...baseCSSVars,
  }
}

export const getThemeCSSVars = memoize(generateThemeCSSVars)
