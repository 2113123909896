import { Editor, findParentNodeClosestToPos, NodeWithPos } from '@tiptap/core'
import { Node, ResolvedPos } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'

import { findNodeAndParents } from 'modules/tiptap_editor/utils'
import { isMobileDevice } from 'utils/deviceDetection'

import { isAnnotatableBlock } from '../Annotatable/utils'
import { CARD_WRAPPER_CLASS } from '../Card'
import { isCardLayoutItemNode, isCardNode } from '../Card/utils'
import { hasContainerDragHandle } from '../DragDrop/utils'
import { TableMap } from '../tables/prosemirror-table'
import {
  BlockHoverPluginKey,
  BlockHoverState,
  SetBlockHoverPosEvent,
} from './BlockHoverState'

type BlockHoverDecorationSpec = {
  isBlockHover: true
}

type AnnotatableHoverDecorationSpec = {
  isAnnotatableHover: true
}
type AnnotatableHoverDecoration = Decoration & {
  spec: AnnotatableHoverDecorationSpec
}

type TableHoverDecorationSpec = {
  isTableHover: true
  rowHover: number
  colHover: number
}

type BlockHoverDecoration = Decoration & {
  spec: BlockHoverDecorationSpec
}

type TableHoverDecoration = Decoration & {
  spec: TableHoverDecorationSpec
}

type TableFocusDecorationSpec = {
  isTableFocus: true
  colFocus: number
}
type TableFocusDecoration = Decoration & {
  spec: TableFocusDecorationSpec
}

export const findTableHoverDeco = (
  decos: Decoration[]
): TableHoverDecorationSpec =>
  decos.find((d): d is TableHoverDecoration => d.spec.isTableHover)?.spec || {}

export const findTableFocusDeco = (
  decos: Decoration[]
): TableFocusDecorationSpec =>
  decos.find((d): d is TableFocusDecoration => d.spec.isTableFocus)?.spec || {}

export const hasAnnotatableHoverDeco = (decos: Decoration[]): boolean =>
  decos.some((d) => d.spec.isAnnotatableHover)

export const hasBlockHoverDeco = (decos: Decoration[]): boolean =>
  decos.some((d): d is BlockHoverDecoration => d.spec.isBlockHover)

const shouldDecorateNodeOnHover = (node: Node, parent: Node) => {
  return isAnnotatableBlock(node, parent) || hasContainerDragHandle(node)
}

export function createBlockHoverPlugin(_editor: Editor) {
  const plugin = new Plugin({
    key: BlockHoverPluginKey,
    state: {
      init() {
        return new BlockHoverState()
      },
      apply(tr, pluginState, _oldEditorState, newEditorState) {
        return pluginState.apply(tr, newEditorState)
      },
    },

    props: {
      handleDOMEvents: {
        // On mousemove, detect which pos is being hovered over
        // and store it in plugin state
        mousemove(view: EditorView, event: MouseEvent) {
          if (isMobileDevice) {
            return
          }

          if (
            !(event.target as HTMLElement).closest(`.${CARD_WRAPPER_CLASS}`)
          ) {
            return
          }

          // Check under the mouse, and 100px to the left (if we're on the margins)
          const { clientX, clientY } = event
          const directPos = view.posAtCoords({
            left: clientX,
            top: clientY,
          })
          const $directPos =
            directPos && view.state.doc.resolve(directPos.inside)
          // Match the first position that points to a block within a card
          let setHoverPos: number | null = null
          let setAnnotatablePos: number | null = null
          // check the directly under node
          if ($directPos?.nodeAfter) {
            setHoverPos = $directPos.pos

            // if the direct hover pos is a node that isn't a card or cardLayoutItem use it as annotatable
            // hover pos
            if (
              !isCardNode($directPos.nodeAfter) &&
              !isCardLayoutItemNode($directPos.nodeAfter)
            ) {
              setAnnotatablePos = $directPos.pos
            }
          }

          if (!setAnnotatablePos) {
            // try the offset pos
            const offsetPos = view.posAtCoords({
              left: clientX - 100,
              top: clientY,
            })
            const $offsetPos =
              offsetPos && view.state.doc.resolve(offsetPos.inside)

            if (
              $offsetPos?.nodeAfter &&
              !isCardNode($offsetPos.nodeAfter) &&
              !isCardLayoutItemNode($offsetPos.nodeAfter)
            ) {
              setAnnotatablePos = $offsetPos.pos
            }
          }

          // If the position has changed, dispatch a transaction to update the plugin state
          const pluginState = BlockHoverPluginKey.getState(view.state)!
          if (
            setHoverPos !== pluginState.getHoverAbsPos(view.state) ||
            setAnnotatablePos !== pluginState.getAnnotableAbsPos(view.state)
          ) {
            const tr = view.state.tr
            tr.setMeta(BlockHoverPluginKey, <SetBlockHoverPosEvent>{
              setHoverPos,
              setAnnotatablePos,
            })
            view.dispatch(tr)
          }
        },
      },
      // Decorate parents of the hovered pos that need to rerender based on hover state
      decorations(state) {
        const pluginState = BlockHoverPluginKey.getState(state)!

        const found = pluginState!.getHoverAbsPos(state)
        if (found === null) {
          return DecorationSet.empty
        }

        let $pos: ResolvedPos

        try {
          // It's possible that the hovered pos is no longer in the document
          // (e.g. if the user deletes the block and this event fires before the doc state updates)
          $pos = state.doc.resolve(found)
        } catch (err) {
          return DecorationSet.empty
        }

        const decos: Decoration[] = []
        const hoveredBlocks = findNodeAndParents(
          $pos,
          shouldDecorateNodeOnHover
        )
        hoveredBlocks.forEach(({ pos, node }) => {
          decos.push(
            Decoration.node(
              pos,
              pos + node.nodeSize,
              {}, // classes
              <BlockHoverDecorationSpec>{
                isBlockHover: true,
              }
            )
          )
        })

        // Decorate a table when its cells are hovered so we know which column is hovering
        const table: NodeWithPos | undefined = findParentNodeClosestToPos(
          $pos,
          (node) => node.type.name === 'table'
        )
        const cell: NodeWithPos | undefined =
          $pos.nodeAfter?.type.name === 'tableCell'
            ? { pos: $pos.pos, node: $pos.nodeAfter }
            : findParentNodeClosestToPos(
                $pos,
                (node) => node.type.name === 'tableCell'
              )

        if (table && cell) {
          const tableMap = TableMap.get(table.node)
          const { left, top } = tableMap.findCell(cell.pos - (table.pos + 1))

          decos.push(
            Decoration.node(table.pos, table.pos + table.node.nodeSize, {}, <
              TableHoverDecorationSpec
            >{
              isTableHover: true,
              rowHover: top,
              colHover: left,
            })
          )
        }

        // try to also get the focused cell and table
        const $focusPos: ResolvedPos = state.selection.$from
        const focusedTable: NodeWithPos | undefined =
          findParentNodeClosestToPos(
            $focusPos,
            (node) => node.type.name === 'table'
          )
        const focusCell: NodeWithPos | undefined =
          $focusPos.nodeAfter?.type.name === 'tableCell'
            ? { pos: $focusPos.pos, node: $focusPos.nodeAfter }
            : findParentNodeClosestToPos(
                $focusPos,
                (node) => node.type.name === 'tableCell'
              )
        if (focusedTable && focusCell) {
          const tableMap = TableMap.get(focusedTable.node)
          const { left } = tableMap.findCell(
            focusCell.pos - (focusedTable.pos + 1)
          )

          decos.push(
            Decoration.node(
              focusedTable.pos,
              focusedTable.pos + focusedTable.node.nodeSize,
              {},
              <TableFocusDecorationSpec>{
                isTableFocus: true,
                colFocus: left,
              }
            )
          )
        }

        // handle annotable
        const annotatablePos = pluginState.getAnnotableAbsPos(state)
        if (annotatablePos !== null) {
          let $annotatablePos: ResolvedPos

          try {
            // It's possible that the hovered pos is no longer in the document
            // (e.g. if the user deletes the block and this event fires before the doc state updates)
            $annotatablePos = state.doc.resolve(annotatablePos)
          } catch (err) {
            return DecorationSet.empty
          }
          const annotableBlocks = findNodeAndParents(
            $annotatablePos,
            isAnnotatableBlock
          )
          annotableBlocks.forEach(({ pos, node }) => {
            decos.push(
              Decoration.node(pos, pos + node.nodeSize, {}, <
                AnnotatableHoverDecorationSpec
              >{
                isAnnotatableHover: true,
              })
            )
          })
        }

        try {
          return DecorationSet.create(state.doc, decos)
        } catch (e) {
          return DecorationSet.empty
        }
      },
    },
  })
  return plugin
}
