import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Kbd,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { findParentNode, NodeViewProps } from '@tiptap/core'

import { useFeatureFlag } from 'modules/featureFlags'
import { NodeInsertMethods } from 'modules/segment'
import {
  checkCommandDisabled,
  CommandInfo,
  trackItemInserted,
} from 'modules/tiptap_editor/commands'
import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { COMMANDS_LIST } from '../../../commands/commandsList'

const PROMPT_SHORTCUTS = [
  'image.custom',
  'table2',
  'columns',
  'insertCardInside',
  'embed.custom',
  'autoComplete',
]

const promptCommands = PROMPT_SHORTCUTS.map((key) =>
  COMMANDS_LIST.find((cmd) => cmd.key === key)
).filter(Boolean) as CommandInfo[]

// The placeholder that appears on a new line prompting you to add blocks
export const SlashMenuPrompt = ({ editor, node }: NodeViewProps) => {
  const canAutocomplete = useFeatureFlag('autocomplete')
  const { selection } = editor.state
  if (!selection.empty) return null

  const parentCard = findParentNode(isCardNode)(selection)
  const canFillCard =
    canAutocomplete && parentCard && parentCard.node.maybeChild(1) === node

  return (
    <HStack
      spacing={4}
      color="gray.400"
      data-print-hidden
      position="absolute"
      inset="0"
      contentEditable={false}
      overflow="hidden"
      fontFamily="Inter, sans-serif"
      fontSize="0.8em"
      onMouseDown={preventDefaultToAvoidBlur}
      pointerEvents="none"
      userSelect="none"
      data-guider-highlight="slash-menu-prompt"
    >
      <Text whiteSpace="nowrap">
        Type <Kbd>/</Kbd> for blocks
      </Text>
      {canFillCard && (
        <Button
          pointerEvents="auto"
          size="xs"
          variant="solid"
          onClick={() => {
            editor.commands.autoFillCard()
          }}
          leftIcon={<FontAwesomeIcon icon={regular('sparkles')} />}
        >
          Fill card
        </Button>
      )}
      <ButtonGroup
        isAttached
        variant="ghost"
        size="xs"
        spacing={1}
        pointerEvents="auto"
        colorScheme="gray"
        opacity="0.8"
        transitionProperty="opacity"
        transitionDuration="normal"
        _hover={{
          opacity: 1,
        }}
      >
        {promptCommands.map((command) => {
          const { name, shortcut, icon, execute, key } = command
          if (checkCommandDisabled(editor, command)) return false
          return (
            <GammaTooltip
              placement="top"
              shortcut={shortcut}
              label={name}
              key={key}
            >
              <IconButton
                aria-label={name}
                onClick={() => {
                  trackItemInserted(command, NodeInsertMethods.BLOCK_PROMPT)
                  execute(editor)
                }}
                icon={<FontAwesomeIcon icon={icon} />}
              />
            </GammaTooltip>
          )
        })}
      </ButtonGroup>
    </HStack>
  )
}
