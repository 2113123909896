import { ButtonGroup, IconButton, Tooltip } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, useCallback } from 'react'

import {
  EditBackgroundDrawer,
  useEditBackgroundDrawerDisclosure,
} from 'modules/tiptap_editor/extensions/Card/Card2/EditBackgroundDrawer/EditBackgroundDrawer'
import {
  CardLayoutItemAttrs,
  VerticalAlign,
} from 'modules/tiptap_editor/extensions/Card/CardLayout/CardLayoutItem'
import { findCardLayoutDecorationSpec } from 'modules/tiptap_editor/extensions/Card/CardLayout/CardLayoutPlugin'

import { NodeFormattingMenuProps } from './types'

const VerticalAlignButton = ({
  verticalAlign,
  icon,
  isActive,
  onClick,
}: {
  isActive: boolean
  icon: ReactElement
  verticalAlign: VerticalAlign
  onClick: () => void
}) => {
  return (
    <IconButton
      boxSize={7}
      minW="auto"
      p={0}
      bg={isActive ? 'trueblue.50' : undefined}
      onClick={onClick}
      icon={icon}
      variant="toolbar"
      aria-label={`${verticalAlign} layout`}
    />
  )
}

export const CardLayoutItemFormattingMenu = ({
  editor,
  selection,
  decorations,
}: NodeFormattingMenuProps) => {
  const { node, from: pos } = selection
  const cardLayoutDeco = findCardLayoutDecorationSpec(decorations)
  const attrs = node.attrs as CardLayoutItemAttrs
  const verticalAlign = (node.attrs as CardLayoutItemAttrs).verticalAlign
  const setVerticalAlign = useCallback(
    (align: VerticalAlign) => {
      editor.commands.updateAttributesAtPos(pos, {
        verticalAlign: align,
      })
    },
    [editor, pos]
  )
  const updateCardLayoutItemAttributes = useCallback(
    (a: Record<string, any>) => {
      editor.commands.updateAttributesAtPos(pos, a)
    },
    [editor, pos]
  )

  const { openBackgroundTab, ...disclosureProps } =
    useEditBackgroundDrawerDisclosure()

  return (
    <>
      <EditBackgroundDrawer
        editor={editor}
        {...disclosureProps}
        updateAttributes={updateCardLayoutItemAttributes}
        background={attrs.background}
      />
      <ButtonGroup spacing={1} size="sm" alignItems="center">
        {/* <VerticalAlignButton
          verticalAlign="start"
          onClick={() => setVerticalAlign('start')}
          icon={<MdOutlineVerticalAlignTop />}
          isActive={verticalAlign === 'start'}
        />
        <VerticalAlignButton
          verticalAlign="center"
          onClick={() => setVerticalAlign('center')}
          icon={<MdOutlineVerticalAlignCenter />}
          isActive={verticalAlign === 'center'}
        />
        <VerticalAlignButton
          verticalAlign="end"
          onClick={() => setVerticalAlign('end')}
          icon={<MdOutlineVerticalAlignBottom />}
          isActive={verticalAlign === 'end'}
        /> */}
        {attrs.itemId === 'accent' && (
          <Tooltip label="Edit accent image">
            <IconButton
              onClick={openBackgroundTab}
              variant="toolbar"
              size="sm"
              icon={<FontAwesomeIcon icon={regular('image')} />}
              boxSize={7}
              p={0}
              aria-label={''}
            />
          </Tooltip>
        )}
      </ButtonGroup>
    </>
  )
}
