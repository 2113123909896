import { Flex, FlexProps, Image } from '@chakra-ui/react'

import { useAppSelector } from 'modules/redux'
import { isMobileDevice } from 'utils/deviceDetection'

import { selectMode, selectTheme } from '../../reducer'
import { EditorModeEnum } from '../../types'
import { DOC_Z_INDEXES } from './zIndexes'

const LOGO_HEIGHT = '10vh'
const LOGO_WIDTH = '10vw'

export const CustomerLogo = () => {
  const theme = useAppSelector(selectTheme)
  const mode = useAppSelector(selectMode)
  const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW
  const { logoUrl } = theme

  if (!logoUrl) return null

  if (isMobileDevice) {
    return (
      <Image
        src={logoUrl}
        maxW="12em"
        zIndex={DOC_Z_INDEXES.customerLogo}
        maxH={LOGO_HEIGHT}
        transitionProperty="width, height"
        transitionDuration="normal"
        pointerEvents="none"
        objectFit="contain"
        objectPosition="bottom"
      />
    )
  }

  const logoPosition: FlexProps = isPresentMode
    ? {
        position: 'fixed',
        right: 'clamp(2rem, 3vw, 5rem)',
        top: '3rem',
        justify: 'end',
        align: 'start',
        width: '12vw',
        height: '5vh',
      }
    : {
        // Uses position: sticky to always stay on screen, without scrolling away
        // position: fixed doesn't work because this needs to stay in the same stacking context as Doc.tsx
        // to go above the background but behind the cards
        // and also because this needs to work inside modals/drawers like Snapshots and Theme Editor
        position: 'sticky',
        left: '3rem',
        top: `calc(100% - ${LOGO_HEIGHT} - 4.5rem)`,
        justify: 'start',
        width: '8vw',
        height: LOGO_HEIGHT,
      }

  return (
    <Flex
      {...logoPosition}
      height={LOGO_HEIGHT}
      className="logo"
      zIndex={isPresentMode ? DOC_Z_INDEXES.customerLogo : undefined}
      w={LOGO_WIDTH}
      h={LOGO_HEIGHT}
      borderRadius="md"
    >
      <Image
        src={logoUrl}
        maxH="100%"
        maxW="100%"
        transitionProperty="width, height"
        transitionDuration="normal"
        pointerEvents="none"
        data-testid="customer-logo"
        objectFit="contain"
        objectPosition={isPresentMode ? 'top right' : 'bottom left'}
      />
    </Flex>
  )
}
