/**
 * See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs
 * See: https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
 */
export function dataURLtoFile(dataurl, filename = 'image') {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const ext = mime.match(/image\/(.*)/)[1]

  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], `${filename}.${ext}`, { type: mime })
}

export const downloadImage = (src?: string) =>
  new Promise((res, rej) => {
    if (!src) {
      res(null)
    } else {
      const img = new Image()
      img.src = src
      img.onload = res
      img.onerror = rej
    }
  })

export function isImageMimeType(type: string): boolean {
  return /^image/i.test(type)
}

/**
 * These two functions isHEICFileType and isHEICFileExtension use regex to determine
 * if something is HEIC / HEIF.
 *
 * NOTE: the template on transloadit will also need to be updated to filter for these
 * same values
 */
export function isHEICFileType(mimeType: string): boolean {
  return /heic|heif/i.test(mimeType)
}

export function isHEICFileExtension(filePath: string): boolean {
  return /\.heic|\.heif$/i.test(filePath)
}

export function svgToFile(svg: string): File {
  const type = 'image/svg+xml;charset=utf-8'
  const blob = new Blob(['<?xml version="1.0" standalone="no"?>\r\n', svg], {
    type,
  })
  const file = new File([blob], 'pasted.svg', {
    type,
  })
  return file
}
