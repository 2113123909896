import { Flex, IconButton } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NodeViewProps } from '@tiptap/core'
import { useCallback } from 'react'

import { useAppSelector } from 'modules/redux'

import { NodeViewContent } from '../../react'
import { selectContentEditable } from '../../reducer'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { getFlexAlign } from '../HorizontalAlign'
import { MAX_BUTTONS } from './constants'

export const ButtonGroupView = (nodeViewProps: NodeViewProps) => {
  const { node, editor, getPos } = nodeViewProps
  const { horizontalAlign } = node.attrs
  const isEditable = useAppSelector(selectContentEditable)

  const insertButton = useCallback(() => {
    editor.commands.addButtonToGroup(getPos())
  }, [editor, getPos])

  const numChildren = node.content.childCount
  const canAddButton = numChildren < MAX_BUTTONS && isEditable

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <Flex
        w="100%"
        justify={getFlexAlign(horizontalAlign)}
        _hover={{
          '.add-button': {
            opacity: 1,
          },
        }}
        // Stack vertically on mobile, horizontally otherwise
        direction={['column', 'row']}
        data-selection-ring="padded"
        padding="2px"
        margin="-2px"
        borderRadius="var(--box-border-radius)"
        align="center"
        sx={{
          '> [data-node-view-content]': {
            width: ['100%', 'auto'],
            // The added div inside the node-view-content, which is the direct
            // parent of the buttons
            '[data-node-view-content-inner=buttonGroup]': {
              display: 'flex',
              flexDirection: ['column', 'row'],
              alignItems: 'center',
              width: ['100%', 'auto'],
              // The wrappers for the buttons themselves
              '> div': {
                // Vertial spacing on mobile, horizontal otherwise
                marginLeft: [0, 4],
                marginTop: [4, 0],
                // Full width on mobile
                width: ['100%', 'auto'],
                _first: {
                  marginLeft: 0,
                  marginTop: 0,
                },
              },
            },
          },
        }}
      >
        <NodeViewContent />
        {canAddButton && (
          <Flex position="relative">
            <IconButton
              icon={<FontAwesomeIcon icon={regular('plus')} />}
              aria-label="Add button"
              size="xs"
              variant="plain"
              isRound
              onClick={insertButton}
              opacity="0"
              className="add-button"
              transitionProperty="opacity"
              transitionDuration="normal"
              position="absolute"
              left={4}
              top={-3}
            />
          </Flex>
        )}
      </Flex>
    </AnnotatableNodeViewWrapper>
  )
}
