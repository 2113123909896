import { Editor } from '@tiptap/core'

import { DocFlags, getDocFlags } from './docFlags'

export function useDocFlag<F extends keyof DocFlags = keyof DocFlags>(
  editor: Editor | undefined,
  flag: F
): DocFlags[F] {
  const flags = getDocFlags(editor?.state.doc)
  return flags[flag]
}
