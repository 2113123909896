import '../polyfills'

import { ApolloProvider } from '@apollo/client'
import { ChakraProvider, extendTheme, Theme } from '@chakra-ui/react'
import { config as fontawesomeConfig } from '@fortawesome/fontawesome-svg-core'
import { gammaTheme } from '@gamma-app/ui'
import { TourProvider } from '@reactour/tour'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import { config } from 'config'
import { ErrorBoundary } from 'gamma_components/ErrorBoundary'

// SCSS must be included in the _app.tsx file.
// See _document.tsx for all the font related tags
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'scss/globals.scss'
import 'scss/animations.scss'
import 'scss/star.scss'
import 'scss/print.scss'

import 'modules/tiptap_editor/extensions/Math/prosemirror-math/style/math.css'

// Local modules
import {
  initApolloClient,
  getReactQueryClient,
  HealthCheckContextProvider,
  ReactQueryProvider,
} from 'modules/api'
import { initEmojiMartDataLazy } from 'modules/emoji'
import {
  FeatureFlagDevtoolBall,
  FeatureFlagWrapper,
  featureFlags,
} from 'modules/featureFlags'
import { TourProviderWrapper } from 'modules/guiders/components/TourProviderWrapper'
import { HighlightProvider } from 'modules/highlight'
import { setupPopstateListener } from 'modules/history'
import { init as initIntercom, IntercomScript } from 'modules/intercom'
import { keyboardHandler } from 'modules/keyboard'
import { initWorkbox } from 'modules/offline/workbox'
import { HoneycombUserWrapper } from 'modules/performance/HoneycombUserWrapper'
import { initPublicAPI } from 'modules/public_api'
import { getStore } from 'modules/redux'
import { useInitRoutingSessionStorage } from 'modules/routing/hooks/useInitRoutingSessionStorage'
import { analytics } from 'modules/segment'
import { SegmentContextProvider } from 'modules/segment/SegmentContextProvider'
import {
  TooltipPortalRefProvider,
  useCreateTooltipPortal,
} from 'modules/tooltips/hooks'
import { UserContextProvider } from 'modules/user'
import { handleAppHeight, isRobot } from 'utils/deviceDetection'

// https://github.com/FortAwesome/react-fontawesome/blob/976c1adc59934b34e52b11c03dda4bd69831a6df/examples/nextjs-app/pages/_app.js#L4-L6
fontawesomeConfig.autoAddCss = false

const reduxStore = getStore()
const apolloClient = initApolloClient()
const reactQueryClient = getReactQueryClient()
const finalTheme = extendTheme(gammaTheme, {
  styles: {
    global: {
      '.ProseMirror': {
        outline: '0 none',
      },
    },
  },
}) as Theme

if (typeof window !== 'undefined') {
  initWorkbox()
  initPublicAPI(window, finalTheme)
  handleAppHeight()
  keyboardHandler.initialize()
  setupPopstateListener()
  analytics.init()
  initIntercom()
  initEmojiMartDataLazy()
}

if (!featureFlags.get('debugLogging') && process.browser) {
  // Nuke all the calls to console.debug
  window.console.debug = () => {}
}

function GammaApp({ Component, pageProps }) {
  const router = useRouter()
  useInitRoutingSessionStorage()

  useEffect(() => {
    const handleRouteChange = (url: string, { shallow }) => {
      console.debug(
        `[GammaApp - handleRouteChange] App is changing to ${url} ${
          shallow ? 'with' : 'without'
        } shallow routing`
      )
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  const portalRef = useCreateTooltipPortal()

  return (
    <ChakraProvider theme={finalTheme} resetCSS={true}>
      <ErrorBoundary>
        <ReduxProvider store={reduxStore}>
          <ApolloProvider client={apolloClient}>
            <ReactQueryProvider client={reactQueryClient}>
              <GammaHead />
              <IntercomScript />
              <UserContextProvider>
                <SegmentContextProvider>
                  <FeatureFlagWrapper>
                    <HealthCheckContextProvider>
                      <HoneycombUserWrapper>
                        <HighlightProvider>
                          <TooltipPortalRefProvider value={portalRef}>
                            <TourProviderWrapper>
                              <Component {...pageProps} />
                            </TourProviderWrapper>
                          </TooltipPortalRefProvider>
                        </HighlightProvider>
                      </HoneycombUserWrapper>
                    </HealthCheckContextProvider>
                  </FeatureFlagWrapper>
                </SegmentContextProvider>
                <FeatureFlagDevtoolBall />
              </UserContextProvider>
            </ReactQueryProvider>
          </ApolloProvider>
        </ReduxProvider>
      </ErrorBoundary>
    </ChakraProvider>
  )
}

export default GammaApp

const GammaHead = () => {
  const APP_ENV = config.APPLICATION_ENVIRONMENT
  const faviconPath =
    APP_ENV === 'production'
      ? '/favicons/favicon-transparent-96x96.png'
      : APP_ENV === 'staging'
      ? '/favicons/favicon_staging.jpg'
      : '/favicons/favicon_local.jpg'
  return (
    <Head>
      <title>Gamma</title>
      {/* This needs to be here instead of _document.tsx to prevent Next overriding with its own value https://nextjs.org/docs/messages/no-document-viewport-meta */}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, viewport-fit=cover"
      />
      <link rel="manifest" href="/manifest.json" />
      <link rel="shortcut icon" href={faviconPath} />
      <meta
        content="Meet the modern memo. Write like a doc, present like a deck, discuss live or async."
        name="description"
        key="description"
      />
      <meta content="Gamma" property="og:title" key="og:title" />
      <meta
        content="Meet the modern memo. Write like a doc, present like a deck, discuss live or async."
        property="og:description"
        key="og:description"
      />
      <meta content={config.OG_IMAGE_URL} property="og:image" key="og:image" />
      <meta content="Gamma" property="twitter:title" key="twitter:title" />
      <meta
        content="Meet the modern memo. Write like a doc, present like a deck, discuss live or async."
        property="twitter:description"
        key="twitter:description"
      />
      <meta
        content={config.OG_IMAGE_URL}
        property="twitter:image"
        key="twitter:image"
      />
      <meta property="og:type" content="website" key="og:type" />
      <meta
        content="summary_large_image"
        name="twitter:card"
        key="twitter:card"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900"
        rel="stylesheet"
      />
      <link rel="stylesheet" href="https://use.typekit.net/tiw2cwq.css"></link>
      {/** Hardcode this for ease until we replace with LD */}
      {!isRobot && (
        <script
          async
          src="https://cdn.optimizely.com/js/20579410255.js"
        ></script>
      )}
      {/* https://iframely.com/docs/omit-script#iframely-omits-embed-js-itself */}
      {/* This is required to make some iframely embeds, like tweets, fit the right height */}
      <script src="//cdn.iframe.ly/embed.js" async charSet="utf-8"></script>
    </Head>
  )
}
