import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip, SectionTitle, useGammaTooltipHider } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import {
  DragEvent,
  DragEventHandler,
  MouseEvent,
  MouseEventHandler,
} from 'react'

import { useAllFeatureFlags } from 'modules/featureFlags'
import { NodeInsertMethods } from 'modules/segment'
import {
  checkCommandDisabled,
  CommandInfo,
  setDraggingContent,
  trackItemInserted,
} from 'modules/tiptap_editor/commands'
import { assignCardIds } from 'modules/tiptap_editor/extensions/Card/uniqueId'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { EMPTY_NODES } from '../../../commands/emptyNodes'
import { editorHasFocus } from '../../../utils'
import { useDragAndMouseDownMonitor } from './hooks'
import { InsertableCategoryInfo } from './items'

const iconShadow = '0px 1px 0px rgba(0,0,0,0)'
const iconColor = 'trueblue.600'
const POPOVER_CLASS = 'insert-widget-popover'

export const CardItemRow = ({ item }: { item: CommandInfo }) => {
  const { previewImage, shortcut, name } = item
  return (
    <HStack spacing={4}>
      {previewImage?.src && (
        <Flex w="150px" bg="white" borderRadius="md">
          <Image src={previewImage.src} alt={name} />
        </Flex>
      )}
      <Text w="100%" fontWeight="600" fontSize="sm">
        {name}
        {shortcut && (
          <Text fontSize="xs" color="gray.500" fontWeight="500">
            {shortcut}
          </Text>
        )}
      </Text>
    </HStack>
  )
}

const InsertWidgetItemRow = ({
  item,
  onDragStart,
  onDragEnd,
  onClick,
}: {
  item: CommandInfo
  onDragStart: DragEventHandler<HTMLDivElement>
  onDragEnd: DragEventHandler<HTMLDivElement>
  onClick: MouseEventHandler<HTMLDivElement>
}) => {
  const {
    isDragging,
    isMouseDown,
    handleDragStart,
    handleDragEnd,
    handleClick,
    onMouseDown,
    onMouseUp,
  } = useDragAndMouseDownMonitor(onDragStart, onDragEnd, onClick)
  const {
    key,
    name,
    description,
    type,
    image,
    shortcut,
    icon,
    previewImage,
    nodeName,
  } = item

  const showIcon = type !== 'cardTemplate'

  return (
    <GammaTooltip
      // Since clicking does nothing, leave the tooltip open on mousedown/click
      closeOnMouseDown={false}
      closeOnClick={false}
      // Once dragging starts, override tooltip to be closed
      isOpen={isDragging ? false : undefined}
      label={
        <Box as="span" display="inline">
          <FontAwesomeIcon icon={regular('hand')} />{' '}
          <Text display="inline">Drag to insert</Text>
        </Box>
      }
      aria-label="Drag to insert"
      openDelay={200}
      placement="top"
    >
      <HStack
        data-testid={`${key}-insert-button`}
        draggable={true}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={handleClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
        bg="gray.50"
        p={2}
        px={3}
        shadow="sm"
        borderRadius="xl"
        _hover={{
          transform: 'scale(1.05) rotate(-0.25deg)',
          shadow: 'lg',
        }}
        cursor="grab"
        transitionProperty="common"
        transitionDuration="normal"
        spacing={3}
        border="1px"
        borderColor="gray.200"
        transform={`rotate(${
          isMouseDown && !isDragging ? -1 : 0
        }deg) translate(0, 0);`}
      >
        <Flex w="100%">
          {nodeName === 'card' ? (
            <CardItemRow item={item} />
          ) : (
            <Stack
              spacing={0}
              textAlign="left"
              w="100%"
              whiteSpace="break-spaces"
            >
              <Text fontWeight="600" fontSize="sm">
                {name}
              </Text>
              {description && (
                <Text fontSize="xs" color="gray.500" fontWeight="500">
                  {description}
                </Text>
              )}
              {shortcut && (
                <Text fontSize="xs" color="gray.500" fontWeight="500">
                  {shortcut}
                </Text>
              )}
              {previewImage && (
                <Image
                  src={previewImage.src}
                  w="100%"
                  maxH="6em"
                  // Prevents the image itself from being draggable on Windows
                  pointerEvents="none"
                />
              )}
            </Stack>
          )}
          {showIcon && (
            <Flex
              w={7}
              justifyContent="center"
              alignItems="flex-start"
              pt={1}
              color="trueblue.600"
            >
              {image ? (
                <Image
                  src={image.src}
                  borderRadius="sm"
                  h="1em"
                  w="1em"
                  objectFit="contain"
                />
              ) : (
                <FontAwesomeIcon icon={icon} />
              )}
            </Flex>
          )}
        </Flex>
      </HStack>
    </GammaTooltip>
  )
}
export const InsertWidgetButtons = ({
  icon,
  name,
  itemGroups,
  editor,
  isHighlighted,
}: InsertableCategoryInfo & {
  editor: Editor
}) => {
  const allFlags = useAllFeatureFlags()
  const { GammaTooltipHiderContext, hideTooltips } = useGammaTooltipHider()

  return (
    <Popover
      placement="start"
      trigger="hover"
      isLazy
      lazyBehavior="keepMounted"
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: { padding: 20 },
        },
      ]}
      closeOnBlur={true} // Required to close it when you start dragging
    >
      <PopoverTrigger>
        <IconButton
          aria-label={name}
          data-testid={`${name
            .toLowerCase()
            .split(' ')
            .join('-')}-widget-inner-button`}
          size="sm"
          mb={2}
          variant={isHighlighted ? 'solid' : ''}
          icon={<FontAwesomeIcon icon={icon} />}
          color={isHighlighted ? 'white' : iconColor}
          fontWeight="500"
          borderRadius="md"
          borderWidth="1px"
          backdropFilter="blur(20px)"
          borderColor={isHighlighted ? iconColor : 'transparent'}
          _last={{
            mb: 0,
          }}
          _hover={
            !isHighlighted
              ? {
                  bg: 'gray.100',
                  borderColor: 'gray.200',
                }
              : {}
          }
          textShadow={
            isHighlighted ? '-1px 0px 2px rgba(0,0,0,.5)' : iconShadow
          }
          _focus={{
            bg: 'gray.100',
            borderColor: 'gray.200',
          }}
          onMouseDown={preventDefaultToAvoidBlur}
        />
      </PopoverTrigger>

      <Portal>
        <PopoverContent
          bg="#F9FAFBFA"
          p={1}
          borderWidth="1px"
          borderColor="whiteAlpha.600"
          borderRadius="xl"
          shadow="xl"
          // Show the Popover buttons with a higher zIndex than the widget toolbar
          zIndex="overlay"
          overflow="auto"
          maxH="90vh"
          className={POPOVER_CLASS}
        >
          <GammaTooltipHiderContext>
            {itemGroups.map(({ subcategory, items }, itemGroupIndex) => {
              if (itemGroups.length == 1) {
                return (
                  <Stack key={itemGroupIndex} p={2}>
                    {items.map((item) => {
                      if (item.featureFlag && !allFlags[item.featureFlag])
                        return null
                      return (
                        <InsertWidgetItemRow
                          item={item}
                          key={item.key}
                          onDragStart={(ev) =>
                            onItemDragStart(item, editor, ev, hideTooltips)
                          }
                          onDragEnd={() => onItemDragEnd(item, editor)}
                          onClick={(ev) => onItemClick(item, editor, ev)}
                        />
                      )
                    })}
                  </Stack>
                )
              } else {
                return (
                  <Stack
                    p={2}
                    key={subcategory}
                    mt={itemGroupIndex > 0 ? 1 : 0}
                  >
                    <SectionTitle flex="1" textAlign="left">
                      {subcategory}
                    </SectionTitle>
                    {items.map((item) => {
                      if (item.featureFlag && !allFlags[item.featureFlag])
                        return null
                      return (
                        <InsertWidgetItemRow
                          item={item}
                          key={item.key}
                          onClick={(ev) => onItemClick(item, editor, ev)}
                          onDragStart={(ev) =>
                            onItemDragStart(item, editor, ev, hideTooltips)
                          }
                          onDragEnd={() => onItemDragEnd(item, editor)}
                        />
                      )
                    })}
                  </Stack>
                )
              }
            })}
          </GammaTooltipHiderContext>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export const onItemClick = (
  item: CommandInfo,
  editor: Editor,
  ev: MouseEvent
) => {
  if (
    !editorHasFocus(editor) ||
    checkCommandDisabled(editor, item) ||
    !editor.state.selection.empty
  )
    return
  closePopover(ev)
  trackItemInserted(item, NodeInsertMethods.INSERT_WIDGET)
  item.execute(editor)
}

export const onItemDragEnd = (item: CommandInfo, editor: Editor) => {
  const { dragEndFn } = item

  try {
    if (dragEndFn) {
      dragEndFn(editor)
    }
    trackItemInserted(item, NodeInsertMethods.INSERT_WIDGET)
  } catch (e) {
    console.error('[InsertWidget] dragEndFn error:', e)
  }
}

export const onItemDragStart = (
  item: CommandInfo,
  editor: Editor,
  ev: DragEvent,
  afterDragStart?: () => void
) => {
  setTimeout(() => {
    closePopover(ev)
  })

  const nodeToAdd =
    item.nodeName === 'card'
      ? assignCardIds(EMPTY_NODES[item.key])
      : EMPTY_NODES[item.key]
  const dragStartFn = item.dragStartFn
    ? item.dragStartFn
    : nodeToAdd
    ? () => setDraggingContent(editor, nodeToAdd)
    : undefined

  if (!dragStartFn) {
    console.error('[InsertWidget] dragStartFn undefined', item)
    return
  }
  dragStartFn(editor)
  afterDragStart?.()
}

const closePopover = (ev: MouseEvent | DragEvent) => {
  // When we start dragging, close the popover by blurring it. This triggers via closeOnBlur on the popover.
  const popover = (ev.target as HTMLElement).closest(`.${POPOVER_CLASS}`)
  if (popover instanceof HTMLElement) {
    popover.blur()
  }
}
