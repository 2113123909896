import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DarkModeProvider } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import { useCallback, useRef, useState } from 'react'

import { Doc, useHealthCheck } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import {
  getVisibility,
  isOtherOrgDoc,
  VisibilityMap,
} from 'modules/sharing/utils'
import { useCan, useUserContext } from 'modules/user'

import { Export } from './Export'
import { InviteOthers } from './InviteOthers'
import { ShareIcon } from './ShareIcon'
import { SharePublicOrEmbed } from './SharePublicOrEmbed'

type SharePanelModalProps = {
  doc?: Doc
  editor?: Editor
  isSharePanelOpen: boolean
  onSharePanelClose: () => void
  onAnalyticsPanelOpen?: () => void
}

export const SharePanelModal = ({
  doc,
  editor,
  isSharePanelOpen,
  onSharePanelClose,
  onAnalyticsPanelOpen,
}: SharePanelModalProps) => {
  const orgIdErrorLogged = useRef(false)
  const { user, currentWorkspace } = useUserContext()
  const { isConnected } = useHealthCheck()
  const canManage = useCan('manage', doc)

  const isAnalyticsEnabled = useFeatureFlag('analytics')
  const canViewAnalytics =
    isAnalyticsEnabled && canManage && onAnalyticsPanelOpen

  const [view, setView] = useState<'invite' | 'public' | 'export' | 'embed'>(
    'invite'
  )

  const [verifySearchDone, setVerifySearchDone] = useState<() => Promise<any>>(
    // Allows the CollaboratorSearchBar to register a done fn that we'll call before
    // closing the dialog, allowing it to interrupt closing if needed
    () => () => Promise.resolve()
  )

  const closeIfReady = useCallback(() => {
    verifySearchDone().then(
      () => {
        setView('invite')
        onSharePanelClose()
      },
      () => {
        console.debug(
          '[handleSharePanelClose] CollaboratorSearchBar rejected 👎🏼'
        )
      }
    )
  }, [onSharePanelClose, verifySearchDone])

  const handleViewAnalyticsClick = useCallback(() => {
    if (onAnalyticsPanelOpen) {
      onSharePanelClose()
      onAnalyticsPanelOpen()
    }
  }, [onAnalyticsPanelOpen, onSharePanelClose])

  if (!user) return null

  const visibilityType = getVisibility(doc, user)
  const visibility = visibilityType ? VisibilityMap[visibilityType] : null
  const isFromOtherOrg = isOtherOrgDoc(doc, currentWorkspace)

  // We should never get this far, but err on the side of
  // caution by rendering nothing and logging an error
  if (doc && visibility === null) {
    console.error('[SharePanel] Unknown VisibilityValue for doc', doc)
    return null
  }

  const workspaceId = doc?.organization?.id
  if (doc && !workspaceId && !orgIdErrorLogged.current) {
    console.error(
      '[SharePanelModal] Doc unexpectedly doesnt have a workspaceId',
      {
        docId: doc.id,
        organization: doc.organization,
      }
    )
    orgIdErrorLogged.current = true
  }

  return (
    <DarkModeProvider isDark={false}>
      <Modal
        onEsc={closeIfReady}
        isOpen={isSharePanelOpen}
        onClose={onSharePanelClose}
        trapFocus={true}
        size="xl"
        returnFocusOnClose={false}
      >
        <ModalOverlay />
        <ModalContent data-share-panel-modal-content>
          <ModalHeader>
            {visibility && (
              <Box as="span" mr={3}>
                <ShareIcon
                  visibility={visibility}
                  isFromOtherOrg={isFromOtherOrg}
                />
              </Box>
            )}
            {!doc ? 'Share' : `Share ${doc.title}`}
          </ModalHeader>
          <ModalCloseButton
            data-share-panel-close-button
            onClick={(e) => {
              e.preventDefault()
              closeIfReady()
            }}
          />
          <Divider />
          {!doc ? (
            <ModalBody pb={6} data-testid="share-panel-modal-body">
              <Stack>
                <Skeleton height="55px" />
                <Skeleton height="55px" />
                <Skeleton height="55px" />
                <Skeleton height="55px" />
              </Stack>
            </ModalBody>
          ) : (
            <ModalBody pb={6} data-testid="share-panel-modal-body">
              {doc && workspaceId && (
                <>
                  {canManage && (
                    <Wrap my={4}>
                      <WrapItem>
                        <Button
                          leftIcon={
                            <FontAwesomeIcon
                              icon={regular('user-plus')}
                              aria-label="Invite others"
                            />
                          }
                          variant="ghost"
                          size="sm"
                          fontWeight="600"
                          bg={view === 'invite' ? 'trueblue.50' : 'transparent'}
                          color={
                            view === 'invite' ? 'trueblue.500' : 'gray.600'
                          }
                          onClick={() => setView('invite')}
                        >
                          Invite others
                        </Button>
                      </WrapItem>
                      <WrapItem>
                        <Button
                          leftIcon={
                            <FontAwesomeIcon
                              icon={regular('globe-americas')}
                              aria-label="Share publicly"
                            />
                          }
                          variant="ghost"
                          size="sm"
                          fontWeight="600"
                          bg={view === 'public' ? 'trueblue.50' : 'transparent'}
                          color={
                            view === 'public' ? 'trueblue.500' : 'gray.600'
                          }
                          onClick={() => setView('public')}
                        >
                          Share publicly
                        </Button>
                      </WrapItem>
                      {editor && (
                        <WrapItem>
                          <Button
                            leftIcon={
                              <FontAwesomeIcon
                                icon={regular('arrow-down-to-bracket')}
                                aria-label="Export"
                              />
                            }
                            variant="ghost"
                            size="sm"
                            fontWeight="600"
                            bg={
                              view === 'export' ? 'trueblue.50' : 'transparent'
                            }
                            color={
                              view === 'export' ? 'trueblue.500' : 'gray.600'
                            }
                            onClick={() => setView('export')}
                          >
                            Export
                          </Button>
                        </WrapItem>
                      )}
                      <WrapItem>
                        <Button
                          leftIcon={
                            <FontAwesomeIcon
                              icon={regular('code')}
                              aria-label="Embed"
                            />
                          }
                          variant="ghost"
                          size="sm"
                          fontWeight="600"
                          bg={view === 'embed' ? 'trueblue.50' : 'transparent'}
                          color={view === 'embed' ? 'trueblue.500' : 'gray.600'}
                          onClick={() => setView('embed')}
                        >
                          Embed
                        </Button>
                      </WrapItem>
                    </Wrap>
                  )}
                  {(!canManage || view == 'invite') && (
                    <InviteOthers
                      workspaceId={workspaceId}
                      doc={doc}
                      user={user}
                      setVerifySearchDone={setVerifySearchDone}
                      canManage={canManage}
                      isFromOtherOrg={isFromOtherOrg}
                    />
                  )}
                  {canManage && (view === 'public' || view === 'embed') && (
                    <SharePublicOrEmbed
                      doc={doc}
                      isSharePublic={view == 'public'}
                      isConnected={isConnected}
                    />
                  )}
                  {canManage && editor && view === 'export' && (
                    <Export doc={doc} editor={editor} />
                  )}
                </>
              )}
            </ModalBody>
          )}
          <Divider />
          <ModalFooter>
            <Flex
              justifyContent={canViewAnalytics ? 'space-between' : 'flex-end'}
              flex={1}
            >
              {canViewAnalytics && (
                <Button
                  disabled={!isConnected}
                  leftIcon={<FontAwesomeIcon icon={regular('chart-column')} />}
                  variant="ghost"
                  onClick={handleViewAnalyticsClick}
                >
                  View analytics
                </Button>
              )}
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  closeIfReady()
                }}
                variant="solid"
              >
                Done
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DarkModeProvider>
  )
}
