import { Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DarkModeProvider } from '@gamma-app/ui'
import { motion } from 'framer-motion'
import isHotkey from 'is-hotkey'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { keyboardHandler } from 'modules/keyboard'
import { Panel } from 'modules/panels/Panel'
import { PanelComponent } from 'modules/panels/types'
import { useAppSelector } from 'modules/redux'
import { useScrollManager } from 'modules/scroll'
import { isThemeDark } from 'modules/theming'
import { EditorModeEnum } from 'modules/tiptap_editor'
import { selectIsManageCardMenuOpen } from 'modules/tiptap_editor/extensions/Card/ManageCardMenu/reducer'
import { useForwardUndo } from 'modules/tiptap_editor/hooks/useForwardUndo'
import {
  selectBackground,
  selectMode,
  selectTheme,
} from 'modules/tiptap_editor/reducer'
import {
  getBackgroundProps,
  getDocOrThemeBackground,
} from 'modules/tiptap_editor/styles/backgroundStyles'
import {
  getContainerOptions,
  getContainerStyles,
} from 'modules/tiptap_editor/styles/containerStyles'
import { useEditorContext } from 'sections/docs/context/EditorContext'
import { useIsHovering } from 'utils/hooks'

import { TableOfContents } from './TableOfContents'

const MotionFlex = motion(Flex)
const ExpandTransition = {
  type: 'spring',
  duration: 0.3,
  bounce: 0,
}

const SCROLLER_ELEMENT_CLASS = 'toc-root'
const SCROLLER_ELEMENT_SELECTOR = `.${SCROLLER_ELEMENT_CLASS}`
const HOVERED_WIDTH = 300
const HOVERED_MARGIN = 16

const variants = {
  open: { x: 0 },
  hidden: { x: -HOVERED_WIDTH - HOVERED_MARGIN },
}

export const TableOfContentsPanel: PanelComponent = ({
  closePanel,
  openPanel,
  isDragging,
  isOpen,
  hasRoomToOpen,
}) => {
  const { editor } = useEditorContext()
  const scrollManager = useScrollManager('toc')
  const forwardUndo = useForwardUndo(editor)
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null)
  const mode = useAppSelector(selectMode)
  const isManageCardMenuOpen = useAppSelector(selectIsManageCardMenuOpen('toc'))
  const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW

  const theme = useSelector(selectTheme)
  const isDark = isThemeDark(theme)
  const docBackground = useSelector(selectBackground)
  const background = getDocOrThemeBackground(theme, docBackground)
  const container = getContainerOptions(theme)

  const { isHovering, ...hoverHandlers } = useIsHovering({
    disabled: isOpen,
    enterDelay: 30,
    leaveDelay: 30,
  })

  useEffect(() => {
    scrollManager.setScrollSelector(SCROLLER_ELEMENT_SELECTOR)
  }, [scrollManager])

  const [isHoverExpanded, setIsHoverExpanded] = useState(false)

  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      if (!isHotkey('Mod+\\')(e)) return false

      if (isOpen) {
        closePanel()
      } else {
        openPanel()
      }

      return true
    }
    return keyboardHandler.on('keydown', 'TABLE_OF_CONTENTS', keydownListener)
  }, [isOpen, openPanel, closePanel])

  return (
    <Panel
      ref={useCallback((node) => setRootElement(node), [])}
      width="100%"
      direction="column"
      sx={{
        '.toc-item .ss-preview': isDragging
          ? {
              display: 'none',
            }
          : {},
      }}
      tabIndex={-1} // Allows capturing keys by making this focusable, like Chakra drawers
      onKeyDown={forwardUndo}
      justify="center"
      {...hoverHandlers}
    >
      {/* Doc background - only when not hovering over the content */}
      {isOpen && (
        <Flex
          inset="0"
          {...getBackgroundProps(background, isDark)}
          position="absolute"
        />
      )}
      {/* Hover target */}
      <Flex
        h={{ base: 24, xl: '70vh' }}
        w={10}
        color={isDark ? 'gray.200' : 'gray.800'}
        justify="center"
        align="flex-start"
        direction="column"
        position="absolute"
        p={2}
        // Hide in present mode until you move your mouse. The opacity is set back to 1 in globals.scss
        className="toc-hover-target"
        data-testid="toc-hover-target"
        opacity={isPresentMode ? 0 : 1}
        transitionProperty="opacity"
        transitionDuration="normal"
      >
        <MotionFlex
          borderRadius="xl"
          sx={getContainerStyles(container, theme)}
          direction="column"
          align="center"
          justify="center"
          variants={variants}
          data-guider-highlight="table-of-contents-opener"
          variant={
            !isOpen && !isHovering && !isManageCardMenuOpen ? 'open' : 'hidden'
          }
          transition={ExpandTransition}
          w={10}
          h={20}
        >
          <FontAwesomeIcon icon={regular('bars-staggered')} />
        </MotionFlex>
      </Flex>

      <MotionFlex
        position={!isOpen ? 'absolute' : 'relative'}
        w={!isOpen ? `${HOVERED_WIDTH}px` : undefined}
        h={!isOpen ? 'fit-content' : '100%'}
        color={isDark ? 'gray.200' : 'gray.800'}
        variants={variants}
        animate={
          !isOpen && !isHovering && !isManageCardMenuOpen ? 'hidden' : 'open'
        }
        onAnimationComplete={(variant) => {
          setIsHoverExpanded(variant === 'open')
        }}
        transition={ExpandTransition}
        pointerEvents={isOpen || isHoverExpanded ? 'auto' : 'none'}
        maxH={!isOpen ? '80vh' : undefined}
        layout="position"
        layoutDependency={!isOpen}
      >
        {/* Extra hover zone on edges so you don't fall out too easily */}
        {!isOpen && <Flex position="absolute" inset={-4} right={-8} />}
        {/* Card container */}
        <Flex
          inset={!isOpen ? 0 : -1}
          right={0}
          left={2}
          borderRadius={!isOpen ? 'lg' : 'none'}
          sx={getContainerStyles(container, theme)}
          shadow="xl" // Match insert widget popovers
          position="absolute"
        />
        {/* TOC content */}
        <DarkModeProvider isDark={isDark}>
          <TableOfContents
            handleClose={closePanel}
            handleOpen={openPanel}
            rootElement={rootElement}
            isOpen={isOpen}
            hasRoomToOpen={hasRoomToOpen}
          />
        </DarkModeProvider>
      </MotionFlex>
    </Panel>
  )
}
TableOfContentsPanel.panelPosition = 'left'
TableOfContentsPanel.canCollapse = true
