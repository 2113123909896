import { range } from 'lodash'

import { CommandInfo, setDraggingContent } from 'modules/tiptap_editor/commands'
import { TextConvertCommand } from 'modules/tiptap_editor/components/menus/FormattingMenus/types'

import { areListNodesSelected } from '../lists/utils'
import { DefaultCellContent } from './constants'
import { SmartLayoutVariants } from './variants'

const NUM_CELLS = 3

const EnabledVariants = SmartLayoutVariants.filter((v) => !v.disabled)

export const SmartLayoutCommands: CommandInfo[] = EnabledVariants.map(
  (variant) => {
    const {
      key,
      name,
      description,
      icon,
      keywords = [],
      defaultContent,
    } = variant
    const commandKey = `smartLayout-${key}`
    const emptyNode = {
      type: 'smartLayout',
      attrs: {
        variantKey: key,
      },
      content: range(NUM_CELLS).map(() => {
        return {
          type: 'smartLayoutCell',
          content: defaultContent || DefaultCellContent,
        }
      }),
    }
    return {
      key: commandKey,
      name: `${name} layout`,
      keywords: [...keywords, 'layout', 'smart'],
      description,
      nodeName: 'smartLayout',
      icon,
      execute: (editor) => {
        editor.chain().focus().insertContentAndSelect(emptyNode).run()
      },
      dragStartFn: (editor) => setDraggingContent(editor, emptyNode),
      featureFlag: 'smartLayout',
    }
  }
)

export const ListToSmartLayoutCommands: TextConvertCommand[] =
  EnabledVariants.map((variant) => {
    const { key, name, description, icon } = variant
    const commandKey = `listToSmartLayout-${key}`
    return {
      key: commandKey,
      name: `${name} layout`,
      icon,
      description,
      group: 'list',
      apply: (editor) => editor.commands.listToSmartLayout(key),
      checkDisabled: (editor) => !areListNodesSelected(editor),
      featureFlag: 'smartLayout',
    }
  })
