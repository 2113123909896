import { Heading, SimpleGrid, VStack } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'

import {
  BackgroundOptions,
  MaskOptions,
} from 'modules/tiptap_editor/styles/backgroundStyles'

import { DEFAULT_MASK } from '../BackgroundMask'
import { BackgroundMaskPreview } from './BackgroundMaskPreview'

export const MASK_COLOR_OPTIONS: {
  label: string
  isDark: boolean
  value: MaskOptions['color']
}[] = [
  { isDark: false, label: 'White', value: 'white' },
  { isDark: true, label: 'Black', value: 'black' },
]

export const MASK_EFFECT_OPTIONS: {
  label: string
  value: MaskOptions['effect']
}[] = [
  {
    label: 'Frosted',
    value: 'frosted',
  },
  {
    label: 'Faded',
    value: 'faded',
  },
  {
    label: 'Clear',
    value: 'none',
  },
]

export type BackgroundMaskPanelProps = {
  background: BackgroundOptions
  updateAttributes: NodeViewProps['updateAttributes']
}
export const BackgroundMaskPanel = ({
  background,
  updateAttributes,
}: BackgroundMaskPanelProps) => {
  const mask = { ...DEFAULT_MASK, ...background.mask }

  return (
    <VStack spacing={4} w="100%" align="stretch">
      <VStack spacing={2} align="stretch">
        <Heading size="md">Transparency</Heading>
        <SimpleGrid columns={2} spacing={2}>
          {MASK_EFFECT_OPTIONS.map(({ label, value }) => {
            const newBackground = {
              ...background,
              mask: { ...mask, effect: value },
            }
            return (
              <BackgroundMaskPreview
                onClick={() => {
                  updateAttributes({
                    background: newBackground,
                  })
                }}
                key={label}
                label={label}
                isChecked={background.mask?.effect === value}
                cardBackground={newBackground}
              />
            )
          })}
        </SimpleGrid>
      </VStack>

      {/* <VStack spacing={2} align="stretch">
        <Heading size="md">Color</Heading>
        <SimpleGrid columns={2} spacing={2}>
          {MASK_COLOR_OPTIONS.map(({ label, value }) => {
            const newBackground = {
              ...background,
              mask: { ...mask, color: value },
            }
            return (
              <BackgroundMaskPreview
                onClick={() => {
                  updateAttributes({
                    background: newBackground,
                  })
                }}
                key={label}
                label={label}
                isChecked={background.mask?.color === value}
                cardBackground={newBackground}
              />
            )
          })}
        </SimpleGrid>
      </VStack> */}
    </VStack>
  )
}
