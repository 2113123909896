import { Heading as TiptapHeading, Level } from '@tiptap/extension-heading'

import { ReactNodeViewRenderer } from '../../react'
import { disallowParentsFromInputRule } from '../../utils/inputRules'
import { attrsOrDecorationsChanged } from '../updateFns'
import { HeadingView } from './HeadingView'

export const HeadingLevels = [1, 2, 3, 4] as Level[]
export const MaxHeadingLevel = Math.max(...HeadingLevels)

export const Heading = TiptapHeading.extend({
  selectable: false,

  addAttributes() {
    return {
      level: {
        default: 1,
        rendered: true, // Override Tiptap default behavior of hiding this
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(HeadingView, {
      update: attrsOrDecorationsChanged,
    })
  },

  addInputRules() {
    return this.options.levels.map((level) => {
      return disallowParentsFromInputRule(
        {
          find: new RegExp(`^(#{1,${level}})\\s$`),
          type: this.type,
          getAttributes: {
            level,
          },
        },
        [
          this.editor.state.schema.nodes.bullet,
          this.editor.state.schema.nodes.numbered,
          this.editor.state.schema.nodes.todo,
        ]
      )
    })
  },
}).configure({
  levels: HeadingLevels,
})
