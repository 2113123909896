import { icon } from '@fortawesome/fontawesome-svg-core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import shuffle from 'lodash/shuffle'

import { config } from 'config'
import { TRANSFORM_TEXT_GQL } from 'modules/ai/graphql'
import { getApolloClient } from 'modules/api'
import { downloadImage } from 'utils/image'
import { getExistingQueryParams } from 'utils/url'

const defaultIconSet = [
  light('user'),
  light('magnifying-glass'),
  light('star'),
  light('comment'),
  light('pen-nib'),
  light('file'),
].map((iconDef) => {
  const thisIcon = icon(iconDef)
  const [width, height] = thisIcon.icon
  return {
    id: iconDef.iconName,
    provider: 'fa',
    styles: ['light'],
    src: `/api/icons/${iconDef.iconName}.svg?provider=fa&style=light`,
    html: thisIcon.html[0],
    width,
    height,
  }
})

export const USE_SAMPLE_DATA_TO_DEBUG =
  getExistingQueryParams()['debug'] === 'true'

export type OpenAIParams = Partial<{
  temperature: number
  maxTokens: number
  model: string
  stop: string | string[]
}>

export const fetchTextCompletion = (
  prompt: string,
  operation: string = 'raw',
  params?: OpenAIParams
): Promise<string> => {
  console.debug('[AI] Fetching text completion', {
    prompt,
  })
  return getApolloClient()
    .mutate({
      mutation: TRANSFORM_TEXT_GQL,
      variables: {
        text: prompt,
        operation,
        params,
      },
    })
    .then(({ data }) => {
      const { text } = data.transformText
      if (!text) {
        throw Error(`No text returned`)
      }
      console.debug('[AI] Got text completion', { text })
      return text
    })
}

export const trimSpaces = (text: string): string => {
  return text.replace(/^[\s]+|[\s]+$/g, '')
}

export const fetchImages = async (searchQuery: string, count: number = 5) => {
  const imageSearchEndpoint = `${
    config.API_HOST || ''
  }/media/images/search?count=${count}&query=${searchQuery}&provider=unsplash&page=1`
  try {
    const req = await fetch(imageSearchEndpoint, { credentials: 'include' })
    const res = await req.json()

    if (!(res?.length >= 0)) {
      throw new Error(res)
    }
    Promise.all(
      res.map((image: any) => {
        return downloadImage(image.imageUrl)
      })
    ).then(() => {
      console.log('[AI fetchImages] images downloaded in background')
    })
    return res
  } catch (error) {
    console.error('(caught) [fetchImages] fetchResults:', error)
  }
}

export const fetchIcons = async (searchQuery: string) => {
  const imageSearchEndpoint = `/api/icons/search?count=5&query=${searchQuery}`
  try {
    const req = await fetch(imageSearchEndpoint, { credentials: 'include' })
    const res = (await req.json())?.data
    if (!res || res.length === 0) {
      console.warn('[fetchIcons] No icons found. Using default set')
      return shuffle(defaultIconSet)
    }
    console.log('[AI fetchIcons] fetched icons', res)
    return res
  } catch (error) {
    console.error('(caught) [fetchIcons] fetchResults:', error)
  }
}
