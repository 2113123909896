import { Node } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { FontSize } from './FontSize'
import { fontSizeFromNode } from './utils'

type FirstChildFontSizeDecorationSpec = {
  isFirstChildFontSizeDecoration: true
  firstChildSize: FontSize | undefined
}

type FirstChildFontSizeDecoration = Decoration & {
  spec: FirstChildFontSizeDecorationSpec
}

const shouldDecorateFirstChildFontSize = (node: Node) => {
  return ['calloutBox', 'smartLayoutCell'].includes(node.type.name)
}

export const FontSizePlugin = () =>
  new Plugin({
    props: {
      // Adds a decoration to parents with their first child's font size
      decorations(state) {
        const decos: Decoration[] = []
        state.doc.descendants((node, pos) => {
          if (!shouldDecorateFirstChildFontSize(node) || !node.firstChild) {
            return
          }
          const firstChildSize = fontSizeFromNode(node.firstChild)

          const spec: FirstChildFontSizeDecorationSpec = {
            isFirstChildFontSizeDecoration: true,
            firstChildSize: firstChildSize,
          }
          decos.push(Decoration.node(pos, pos + node.nodeSize, {}, spec))
        })
        if (decos.length == 0) {
          return
        }
        return DecorationSet.create(state.doc, decos)
      },
    },
  })

export const findFirstChildFontSizeDeco = (
  decos: Decoration[]
): FirstChildFontSizeDecorationSpec =>
  decos.find(
    (d): d is FirstChildFontSizeDecoration =>
      d.spec.isFirstChildFontSizeDecoration
  )?.spec || {}
