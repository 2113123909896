import { brightenColor, colorWithOpacity, isColorDark } from 'utils/color'

import { ThemeBase } from '../types'

export const MidnightThemeBase: ThemeBase = {
  key: 'midnight',
  getCSSVars(cardColor) {
    const isDark = isColorDark(cardColor)
    const borderColor = brightenColor(cardColor, isDark ? 15 : -8)
    return {
      '--line-color': borderColor,
      '--card-border-shadow': `0 0 0 0.25em ${borderColor}`,
      '--line-thickness': '0.125em',
      '--card-color-transparent': colorWithOpacity(cardColor, 0.75),
      '--box-border-radius': '1.5em',
    }
  },
  cardSx: {
    boxShadow: 'var(--card-border-shadow)',
    borderRadius: `2em`,
    backgroundColor: 'var(--card-color-transparent)',
  },
  boxSx: {
    backgroundColor: 'var(--card-color)',
    borderRadius: `var(--box-border-radius)`,
  },
  imageSx: {
    borderRadius: `2.5em`,
  },
  smartLayoutCellSx: {
    '--heading-color': 'var(--item-color)',
    '--line-color': 'var(--item-color)',
  },
  smartLayoutBoxSx: {
    boxShadow: `0 0 0 var(--line-thickness) var(--item-color)`,
    backgroundColor: 'var(--card-color)',
    borderRadius: 'var(--box-border-radius)',
  },
  smartLayoutContentSx: {},
  smartLayoutImageSx: {
    boxShadow: `0 0 0 var(--line-thickness) var(--item-color)`,
    borderRadius: 'full',
  },
  smartLayoutLineSx: {
    backgroundColor: 'var(--line-color)',
  },
  clickableSx: {
    border: 'var(--line-thickness) solid var(--link-color)',
    borderRadius: `1.5em`,
  },
  tableSx: {
    backgroundColor: 'var(--card-color)',
    borderRadius: `1.5em`,
    boxShadow: 'var(--card-border-shadow)',
    'tbody tr': {
      ':last-child': { borderBottom: 'none !important' },
      ':nth-child(5n + 1)': {
        borderBottom: '2px solid var(--accent-color)',
      },
      ':nth-child(5n + 2)': {
        borderBottom: '2px solid var(--accent-color-2)',
      },
      ':nth-child(5n + 3)': {
        borderBottom: '2px solid var(--accent-color-3)',
      },
      ':nth-child(5n + 4)': {
        borderBottom: '2px solid var(--accent-color-4)',
      },
      ':nth-child(5n + 5)': {
        borderBottom: '2px solid var(--accent-color-5)',
      },
    },
  },
}
