import { Extension } from '@tiptap/core'
import { NodeSelection } from 'prosemirror-state'

import { getStore } from 'modules/redux'
import { setMediaNodeExpanded } from 'modules/tiptap_editor/reducer'

import { UniqueMediaId } from './UniqueMediaId'
import { isMediaNode } from './utils'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    media: {
      handleSpaceToZoom: () => ReturnType
    }
  }
}

export const Media = Extension.create({
  name: 'media',

  addExtensions() {
    return [UniqueMediaId]
  },

  addCommands() {
    return {
      handleSpaceToZoom:
        () =>
        ({ state }) => {
          if (
            state.selection instanceof NodeSelection &&
            isMediaNode(state.selection.node)
          ) {
            const store = getStore()
            store.dispatch(
              setMediaNodeExpanded({ nodeId: state.selection.node.attrs.id })
            )
            return true
          }
          return false
        },
    }
  },
})
