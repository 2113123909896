import { SystemStyleObject } from '@chakra-ui/react'

const DIM_OPACITY = 0.2
const SPOTLIGHT_TRANSFORM = 'scale(1.05)'
const SPOTLIGHT_TRANSITION = {
  transitionDuration: 'slow',
  transitionTimingFunction: 'ease-out',
}

const COMMENTS_SELECTOR = '.comments-wrapper, .block-comments-circle'
const ANNOTATABLE_COMMENTS_SELECTOR = `> :is(${COMMENTS_SELECTOR})`
// Content doesn't have its own div (for perf reasons) so this is how we target it:
// any immediate child div of NodeViewWrapper that's not the comments wrapper
// This will also work for non-annotatable blocks, since it's a not
const ANNOTATABLE_CONTENT_SELECTOR = `> :not(${COMMENTS_SELECTOR}), [data-spotlight-parent] > *`

export const spotlightStyles: SystemStyleObject = {
  '[data-spotlight-dim]': {
    filter: `opacity(${DIM_OPACITY})`,
  },

  '.spotlightable': {
    // If we're using an annotatable NodeView, dim the content inside
    '&.react-renderer > [data-node-view-wrapper]': {
      [ANNOTATABLE_CONTENT_SELECTOR]: {
        transitionProperty: 'filter, transform',
        ...SPOTLIGHT_TRANSITION,
        // We use filter instead of opacity for two reasons
        // 1. It's hardware accelerated https://css-tricks.com/almanac/properties/f/filter/#aa-opacity
        // 2. Opacity was getting applied twice on nested cards for some reason. This fixes that.
        filter: `opacity(${DIM_OPACITY})`,
      },
      // Comments should be dimmed when not active
      [ANNOTATABLE_COMMENTS_SELECTOR]: {
        transitionProperty: 'opacity',
        ...SPOTLIGHT_TRANSITION,
        filter: `opacity(${DIM_OPACITY})`,
        // Unless the popup is open, or they're directly hovered
        '&[data-is-popup-open=true]': {
          filter: 'none',
        },
        _hover: {
          filter: 'none',
        },
      },
    },
    // If we're not using an annotatable NodeView, dim the block directly
    '&:not(.react-renderer)': {
      transitionProperty: 'filter, transform',
      ...SPOTLIGHT_TRANSITION,
      filter: `opacity(${DIM_OPACITY})`,
    },
  },
  '.spotlightable.spotlight-block, .spotlight-block .spotlightable': {
    '&.react-renderer > [data-node-view-wrapper]': {
      // Content should un-dim and bulge when active
      [ANNOTATABLE_CONTENT_SELECTOR]: {
        filter: 'none',
        // Framer Motion will leave style = transform: none on cards that aren't animating
        // so we need the !important for those
        transform: `${SPOTLIGHT_TRANSFORM} !important`,
      },
      // Comments should un-dim but not bulge bigger, or they'll end up in the wrong spot
      [ANNOTATABLE_COMMENTS_SELECTOR]: {
        filter: 'none',
      },
    },
    '&:not(.react-renderer)': {
      filter: 'none',
      transform: `${SPOTLIGHT_TRANSFORM} !important`,
    },
  },
}
