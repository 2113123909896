import { mergeAttributes, Node } from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'
import {
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/backgroundStyles'
import { configureJSONAttribute } from 'modules/tiptap_editor/utils'

import { attrsOrDecorationsChanged } from '../../../updateFns'
import { CardLayoutItemIds } from '../CardLayoutItem'
import { CardLayoutItemView } from '../CardLayoutItemView'

export type CardAccentLayoutItemAttrs = {
  background: BackgroundOptions
  itemId: CardLayoutItemIds
}

export const CardAccentLayoutItem = Node.create({
  name: 'cardAccentLayoutItem',
  group: 'cardLayoutItemGroup',
  selectable: false,
  atom: true,
  draggable: false,

  addAttributes() {
    return {
      itemId: {
        default: 'accent',
      },
      background: {
        default: {
          type: BackgroundType.NONE,
        },
        ...configureJSONAttribute('background'),
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(CardLayoutItemView, {
      update: attrsOrDecorationsChanged,
    })
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=card-accent-layout-item]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'card-accent-layout-item' }),
    ]
  },
})
