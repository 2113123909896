import { LDUser } from 'launchdarkly-js-client-sdk'
import { useEffect } from 'react'

import { config } from 'config'
import { isMobileDevice, isRobot } from 'utils/deviceDetection'
import { useHealthCheck } from 'modules/api'

import { useUserContext } from '../user'
import { featureFlags } from './FeatureFlagProvider'
import { initLDClient } from './utils'

const LD_ANONYMOUS_USER_KEY = 'ld_anonymous_user'

export const FeatureFlagWrapper = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const { user, anonymousUser, currentWorkspace, isUserLoading } =
    useUserContext()
  const { isConnected } = useHealthCheck()

  const getCustomAttributes = (email?: string, workspaceId?: string) => {
    return {
      isGammaE2E: config.GAMMA_E2E_USER,
      isGammaUser: email ? email.endsWith('@gamma.app') : false,
      orgId: workspaceId || 'no_org_id',
      isMobileDevice: isMobileDevice,
    }
  }

  useEffect(() => {
    if (
      isUserLoading ||
      isRobot ||
      typeof window === 'undefined' ||
      !isConnected
    ) {
      return
    }

    let ldUser: LDUser
    // https://docs.launchdarkly.com/sdk/features/user-config
    if (user) {
      const { id, firstName, lastName, email, profileImageUrl } = user
      ldUser = {
        key: id,
        firstName,
        lastName,
        email,
        anonymous: false,
        avatar: profileImageUrl,
        custom: getCustomAttributes(email, currentWorkspace?.id),
      }
    } else {
      /**
       * From https://docs.launchdarkly.com/sdk/features/user-config#designating-anonymous-users
       * "We recommend using the same user key for every initialization and
       * then replacing that with the actual user key when you know who the user is.
       * This way LaunchDarkly counts the initialization user key only once
       * against your MAU, instead of every time you initialize."
       */
      ldUser = {
        key: LD_ANONYMOUS_USER_KEY,
        anonymous: true,
        custom: getCustomAttributes(),
      }
    }

    if (!featureFlags.hasInitialized) {
      // Now that we have our Gamma User, initialize the LD client
      initLDClient(config.LAUNCH_DARKLY_CLIENT_SIDE_ID, ldUser)
        // Once the LDClient is initialize, proceed with our own provider
        .then(({ flags, ldClient }) => {
          featureFlags.initialize(ldClient, flags)
        })
    } else {
      // We've already initialized, so just re-identify the user
      featureFlags.initializePromise.then(() => {
        featureFlags.ldClient.identify(ldUser)
      })
    }
  }, [isUserLoading, isConnected, user, anonymousUser, currentWorkspace])

  return <>{children}</>
}
