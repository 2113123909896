import {
  findParentNode,
  findParentNodeClosestToPos,
  mergeAttributes,
  Node,
} from '@tiptap/core'

import { ignoreDataMutation } from 'modules/tiptap_editor/utils/ignoreMutation'

import { ReactNodeViewRenderer } from '../../react'
import { configureJSONAttribute, isTreeEmpty } from '../../utils'
import { deleteLayoutCell } from '../Layout/commands'
import { attrsOrDecorationsChanged } from '../updateFns'
import { DefaultCellContent } from './constants'
import { SmartLayoutPlugin } from './SmartLayoutPlugin'
import { SmartLayoutView } from './SmartLayoutView'
import { getSmartLayoutVariant } from './variants'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    smartLayout: {
      insertSmartLayoutCell: (pos: number) => ReturnType
      handleSmartLayoutDelete: (forward?: boolean) => ReturnType
    }
  }
}

export const SmartLayout = Node.create({
  name: 'smartLayout',
  group: 'cardBlock layoutBlock calloutBlock',
  content: 'smartLayoutCell+',
  isolating: true,
  containerHandle: true,
  defining: true,

  addAttributes() {
    return {
      variantKey: {
        // Mark this attr as required for FixRequiredAttrs extension
        // This will prevent ProseMirror from trying to intelligently create a smart layout
        // e.g. when dragging a smart layout cell out into a card
        default: undefined,
      },
      options: {
        default: {},
        ...configureJSONAttribute('options'),
      },
      fullWidthBlock: {
        default: false,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=smart-layout]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'smart-layout' }),
      0,
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(SmartLayoutView, {
      update: attrsOrDecorationsChanged,
      ignoreMutation: ignoreDataMutation,
    })
  },

  addProseMirrorPlugins() {
    return [SmartLayoutPlugin(this.editor)]
  },

  addCommands() {
    return {
      insertSmartLayoutCell:
        (pos) =>
        ({ chain, state }) => {
          const $pos = state.doc.resolve(pos)
          const parentLayout = findParentNodeClosestToPos(
            $pos,
            (n) => n.type.name === 'smartLayout'
          )
          if (!parentLayout) {
            return false
          }
          const variant = getSmartLayoutVariant(
            parentLayout.node.attrs.variantKey
          )
          const defaultContent = variant.defaultContent || DefaultCellContent
          chain()
            .insertContentAt(pos, {
              type: 'smartLayoutCell',
              content: defaultContent,
            })
            .selectInsertedNode()
            .run()
          return true
        },
      handleSmartLayoutDelete:
        (forward = true) =>
        ({ dispatch, state, tr, chain }) => {
          if (!dispatch) return true

          const parentCell = findParentNode(
            (n) => n.type.name === 'smartLayoutCell'
          )(state.selection)

          const parentLayout = findParentNode(
            (n) => n.type.name === 'smartLayout'
          )(state.selection)

          if (!parentCell || !parentLayout || !isTreeEmpty(parentCell.node))
            return false

          if (parentLayout.node.childCount == 1) {
            // If this is the last cell, unwrap the whole layout
            chain()
              .selectNodeAtPos(parentLayout.pos)
              .deleteSelectionAndSelectNear(forward ? 1 : -1)
              .run()
          } else {
            const $parentCell = tr.doc.resolve(parentCell.pos)
            deleteLayoutCell(tr, $parentCell, forward)
          }

          return true
        },
    }
  },
})
