import { Editor } from '@tiptap/core'
import { marked } from 'marked'
import { Node, Schema, Slice } from 'prosemirror-model'
import Turndown from 'turndown'

import { textLooksLikeLink } from './handleLinkPaste'
import { parseHtmlToSlice } from './utils'

import { serializeFragment } from '.'

export const handleMarkdownPaste = (editor: Editor, event: ClipboardEvent) => {
  // Based on https://github.com/outline/rich-markdown-editor/blob/be1b9426a292fd41beb4db8d70d6695a9416dbea/src/plugins/PasteHandler.ts#L36
  if (!editor.isEditable || !event.clipboardData) {
    return false
  }
  const text = event.clipboardData.getData('text/plain')
  // Don't run if there's no text, or on text that's just a link
  if (!text || textLooksLikeLink(text)) {
    return false
  }
  // If the HTML on the clipboard is from Prosemirror then the best
  // compatibility is to just use the HTML parser, regardless of
  // whether it "looks" like Markdown
  const html = event.clipboardData.getData('text/html')
  if (html?.includes('data-pm-slice')) {
    return false
  }

  const { state } = editor

  // If we're pasting into a codeblock, just paste the text as is without parsing
  if (state.selection.$from.parent.type.spec.code) {
    const tr = state.tr.insertText(text)
    editor.view.dispatch(tr)
    return true
  }

  // Because VSCode is an especially popular editor that places metadata
  // on the clipboard, we can parse it to find out what kind of content
  // was pasted.
  const vscode = event.clipboardData.getData('vscode-editor-data')
  const vscodeMeta = vscode ? JSON.parse(vscode) : undefined
  const pasteCodeLanguage = vscodeMeta?.mode

  const preferMarkdown =
    !html || pasteCodeLanguage === 'markdown' || looksLikeMarkdown(text)
  if (!preferMarkdown) return false

  const { from, to } = state.selection
  event.preventDefault()
  return editor.commands.insertMarkdownAt({ from, to }, text, true)
}

export const parseMarkdownToSlice = (
  text: string,
  schema: Schema,
  shouldSplitCards?: boolean
): Slice => {
  const parsedHtml = marked.parse(text)
  return parseHtmlToSlice(parsedHtml, schema, shouldSplitCards, false)
}

// https://github.com/outline/rich-markdown-editor/blob/bf9aabcc14bcd049fbeccc05a4f8195a3e7243b4/src/lib/isMarkdown.ts#L1
const looksLikeMarkdown = (text: string): boolean => {
  // code-ish
  const fences = text.match(/^```/gm)
  if (fences && fences.length > 1) return true

  // link-ish
  if (text.match(/\[[^]+\]\(https?:\/\/\S+\)/gm)) return true
  if (text.match(/\[[^]+\]\(\/\S+\)/gm)) return true

  // heading-ish
  if (text.match(/^#{1,6}\s+\S+/gm)) return true

  // list-ish
  const listItems = text.match(/^\s*[\d-*].?\s\S+/gm)
  if (listItems && listItems.length > 1) return true

  return false
}

const TurndownSerializer = Turndown({
  headingStyle: 'atx',
  bulletListMarker: '-',
  codeBlockStyle: 'fenced',
})

export const htmlToMarkdown = (html: string) =>
  TurndownSerializer.turndown(html)

export const markdownToHtml = (markdown: string) => marked.parse(markdown, {})

export const rangeToMarkdown = (
  doc: Node,
  from: number,
  to: number
): string => {
  const fragment = doc.slice(from, to).content
  const dom = serializeFragment(fragment, doc.type.schema)
  // Turn it into HTML
  const div = document.createElement('div')
  div.appendChild(dom)
  const html = div.innerHTML
  const markdown = htmlToMarkdown(html)
  return markdown
}

export const docToMarkdown = (doc: Node): string => {
  return rangeToMarkdown(doc, 1, doc.nodeSize - 2)
}
