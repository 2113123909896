import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { CommandInfo } from 'modules/tiptap_editor/commands'

import { EMPTY_NODES } from 'modules/tiptap_editor/commands/emptyNodes'
import BlankCard from 'publicImages/card-templates/Blank-card.svg'
import TitleCard from 'publicImages/card-templates/Title-card.svg'
import TitleWith2ColumnContent from 'publicImages/card-templates/Title-with-2-Column-Content.svg'
import TitleWith2ColumnImageLeft from 'publicImages/card-templates/Title-with-2-Column-Image-Left.svg'
import TitleWith2ColumnImageRight from 'publicImages/card-templates/Title-with-2-Column-Image-Right.svg'
import TitleWith2Images from 'publicImages/card-templates/Title-with-2-images.svg'
import TitleWith3ColumnContent from 'publicImages/card-templates/Title-with-3-Column-Content.svg'
import TitleWith3Images from 'publicImages/card-templates/Title-with-3-images.svg'
import TitleWith3NestedCards from 'publicImages/card-templates/Title-with-3-nested-cards.svg'
import TitleWith3Toggles from 'publicImages/card-templates/Title-with-3-toggles.svg'
import TitleWith4Images from 'publicImages/card-templates/Title-with-4-images.svg'
import TitleWithBullets from 'publicImages/card-templates/Title-with-Bullets.svg'
import TitleWithContentWithButton from 'publicImages/card-templates/Title-with-Content-with-Button.svg'
import TitleWithText from 'publicImages/card-templates/Title-with-text.svg'
import { assignCardIds } from '../uniqueId'

export const CardTemplateCommands: CommandInfo[] = [
  {
    type: 'cardTemplate',
    key: 'blankCard',
    name: 'Blank card',
    nodeName: 'card',
    shortcut: '/new',
    icon: duotone('grid-2'),
    previewImage: BlankCard,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.blankCard),
  },
  {
    type: 'cardTemplate',
    key: 'titleCard',
    name: 'Title card',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleCard,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.titleCard),
  },
  {
    type: 'cardTemplate',
    key: 'titleWithText',
    name: 'Title with text',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWithText,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.titleWithText),
  },
  {
    type: 'cardTemplate',
    key: 'titleWithBullets',
    name: 'Title with bullets',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWithBullets,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.titleWithBullets),
  },
  {
    type: 'cardTemplate',
    key: 'titleWith2ColumnContent',
    name: '2 Column content',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith2ColumnContent,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith2ColumnContent
      ),
  },
  {
    type: 'cardTemplate',
    key: 'titleWith2ColumnImageLeft',
    name: '2 Column (image left)',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith2ColumnImageLeft,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith2ColumnImageLeft
      ),
  },
  {
    type: 'cardTemplate',
    key: 'titleWith2ColumnImageRight',
    name: '2 Column (image right)',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith2ColumnImageRight,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith2ColumnImageRight
      ),
  },
  {
    type: 'cardTemplate',
    key: 'titleWith3ColumnContent',
    name: '3 Column content',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith3ColumnContent,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith3ColumnContent
      ),
  },
  {
    type: 'cardTemplate',
    key: 'titleWith3NestedCards',
    name: 'Title with 3 nested cards',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith3NestedCards,
    execute: (editor) => {
      editor
        .chain()
        .insertContentAndSelect(
          assignCardIds(EMPTY_NODES.titleWith3NestedCards)
        )
        .run()
    },
  },
  {
    type: 'cardTemplate',
    key: 'titleWith3Toggles',
    name: 'Title with 3 toggles',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith3Toggles,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.titleWith3Toggles),
  },
  {
    type: 'cardTemplate',
    key: 'titleWith2ImageColumns',
    name: '2 Image columns',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith2Images,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith2ImageColumns
      ),
  },
  {
    type: 'cardTemplate',
    key: 'titleWith3ImageColumns',
    name: '3 Image columns',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith3Images,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith3ImageColumns
      ),
  },
  {
    type: 'cardTemplate',
    key: 'titleWith4ImageColumns',
    name: '4 Image columns',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith4Images,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith4ImageColumns
      ),
  },
  {
    type: 'cardTemplate',
    key: 'titleWithContentAndButton',
    name: 'Content with button',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWithContentWithButton,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWithContentAndButton
      ),
  },
]
