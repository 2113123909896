import { ButtonGroup } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { NodeSelection } from 'prosemirror-state'
import { useCallback } from 'react'

import { eventEmitter as cardSuggestionEventEmitter } from 'modules/ai/components/CardSuggestionEditor/AIEventEmitter'
import { useFeatureFlag } from 'modules/featureFlags'
import {
  EditBackgroundDrawer,
  useEditBackgroundDrawerDisclosure,
} from 'modules/tiptap_editor/extensions/Card/Card2/EditBackgroundDrawer/EditBackgroundDrawer'
import {
  findLayoutPreset,
  getCardLayoutItemsFromResolvedPos,
} from 'modules/tiptap_editor/extensions/Card/CardLayout/cardLayoutUtils'
import { useCardState } from 'modules/tiptap_editor/extensions/Card/hooks/useCardState'
import { CardAttributes } from 'modules/tiptap_editor/extensions/Card/types'

import { CardAccentLayoutItemAttrs } from '../../../extensions/Card/CardLayout/CardAccent/CardAccentLayoutItem'
import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { ToolbarButton } from '../buttons/ToolbarButton'
import { CardColorMenu } from './CardFormattingMenu/CardColorMenu'
import { CopyLinkButton } from './CardFormattingMenu/CopyLinkButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const CardFormattingMenu = ({
  editor,
  selection,
  decorations,
}: NodeFormattingMenuProps) => {
  const { node, from: pos } = selection
  const { attrs, isNested, isFirstCard } = useCardState({
    node,
    decorations,
  })
  const hasBackground = attrs.background.type !== 'none'
  const cardDesignerEnabled = useFeatureFlag('cardDesigner')

  const { openBackgroundTab: editBackdrop, ...backdropDisclosureProps } =
    useEditBackgroundDrawerDisclosure()
  const { openBackgroundTab: editAccent, ...accentDisclosureProps } =
    useEditBackgroundDrawerDisclosure()

  const layoutItems = getCardLayoutItemsFromResolvedPos(selection.$from)
  const layoutPreset = findLayoutPreset(attrs.layout)
  const hasAccentItem = !!layoutPreset.items.accent
  const accentPos = layoutItems.accent?.pos || null
  const accentBackground = layoutItems.accent?.node.attrs.background || {}

  const updateAttributes = useCallback(
    (a: Partial<CardAttributes>) => {
      editor.commands.updateAttributesAtPos(pos, a)
    },
    [editor, pos]
  )

  const updateAccentAttributes = useCallback(
    (a: Partial<CardAccentLayoutItemAttrs>) => {
      // console.log('updateAccentAttributes', accentPos, accentItems)
      if (accentPos === null) return
      editor.commands.updateAttributesAtPos(accentPos, a)
    },
    [editor, accentPos]
  )

  const duplicateCard = useCallback(() => {
    editor?.commands.duplicateCard(pos)
  }, [editor, pos])

  const mergeIntoParent = useCallback(() => {
    editor?.commands.unnestCard(pos)
  }, [editor, pos])

  const mergeIntoPrevious = useCallback(() => {
    editor?.chain().mergeCardsAtPos(pos).focus().run()
  }, [editor, pos])

  const openCardSuggestionEditor = useCallback(() => {
    if (!selection || !pos || !(selection instanceof NodeSelection)) return

    const cardId = selection.node?.attrs.id
    if (!cardId) return

    cardSuggestionEventEmitter.emit('openSuggestionEditor', { cardId })
  }, [selection, pos])

  const addAccentImage = () => {
    editor.commands.setCardLayout(pos, 'left')
    setTimeout(() => {
      editAccent()
    }, 50)
  }

  return (
    <>
      <ButtonGroup spacing={1} size="sm" alignItems="center">
        <CardColorMenu
          editor={editor}
          selection={selection}
          decorations={decorations}
        />
        {!isNested && (
          <>
            <ToolbarButton
              label={hasAccentItem ? 'Change accent image' : 'Add accent image'}
              onClick={hasAccentItem ? editAccent : addAccentImage}
              icon={regular('image')}
              shortcut="(inside the card)"
            />
            <EditBackgroundDrawer
              editor={editor}
              updateAttributes={updateAccentAttributes}
              background={accentBackground}
              enableMask={attrs.layout === 'behind'}
              {...accentDisclosureProps}
            />
            <ToolbarButton
              onClick={editBackdrop}
              icon={regular('panorama')}
              label={
                hasBackground ? 'Change backdrop image' : 'Add backdrop image'
              }
              shortcut="(outside the card)"
            />
            <EditBackgroundDrawer
              editor={editor}
              updateAttributes={updateAttributes}
              background={attrs.background}
              {...backdropDisclosureProps}
            />
            <FormattingMenuDivider />
          </>
        )}
        <ToolbarButton
          label="Duplicate card"
          testId="duplicate-card"
          onClick={duplicateCard}
          icon={regular('clone')}
        />
        {cardDesignerEnabled && (
          <>
            <ToolbarButton
              onClick={openCardSuggestionEditor}
              icon={regular('magic-wand-sparkles')}
              label="Card designer"
            />
            <FormattingMenuDivider />
          </>
        )}
        {isNested && (
          <ToolbarButton
            label="Merge into parent card"
            testId="merge-into-parent-card"
            onClick={mergeIntoParent}
            icon={regular('arrow-up-left')}
          />
        )}
        {!isNested && !isFirstCard && (
          <ToolbarButton
            label="Merge into card above"
            testId="merge-into-card-above"
            onClick={mergeIntoPrevious}
            icon={regular('arrow-up')}
          />
        )}

        <CopyLinkButton attrs={attrs} />
        <DeleteNodeButton editor={editor} />
      </ButtonGroup>
    </>
  )
}
