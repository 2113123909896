import { mergeAttributes, Node } from '@tiptap/core'

import { configureJSONAttribute } from 'modules/tiptap_editor/utils'

import { ReactNodeViewRenderer } from '../../react'
import { attrsOrDecorationsChanged } from '../updateFns'
import { SmartLayoutCellView } from './SmartLayoutCellView'

export const SmartLayoutCell = Node.create({
  name: 'smartLayoutCell',
  content: 'block+',
  isolating: true,
  selectable: false,
  containerHandle: true,

  imageKeyPath: 'image',

  addAttributes() {
    return {
      options: {
        default: {},
        ...configureJSONAttribute('options'),
      },
      width: {
        default: null,
      },
      image: {
        default: null,
        ...configureJSONAttribute('image'),
      },
      label: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=smart-layout-cell]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'smart-layout-cell' }),
      0,
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(SmartLayoutCellView, {
      update: attrsOrDecorationsChanged,
    })
  },
})
